import Axios from "../../../components/Universal/Axios";
import { Toaster } from "../../../components/Toaster";
import ADMIN_ACTIONS from "../actions";
import { TOAST_TYPE } from "../../../constants";

const list = (query, loader) => async (dispatch) => {
    try {
        dispatch({ type: ADMIN_ACTIONS.REQUEST_USER_LIST, status: true });
        const response = await Axios.get("/admin/get/users", query, loader);

        dispatch({ type: ADMIN_ACTIONS.USER_LIST_SUCCESS, users: response.data.users, count: response.data.count });
        dispatch({ type: ADMIN_ACTIONS.REQUEST_USER_LIST, status: false });
    }
    catch (err) { }
};

const update = (query, payload, loader) => async (dispatch) => {
    try {
        await Axios.patch("/admin/update/user", payload, loader);

        Toaster(TOAST_TYPE.SUCCESS, `User ${payload.isBlocked ? 'blocked' : 'unblocked'} successfully`);
        dispatch(list(query, loader));
    }
    catch (err) { }
};

const specific = (loader, query) => async (dispatch) => {
    try {
        const response = await Axios.get("/admin/get/user", query, loader);

        dispatch({ type: ADMIN_ACTIONS.SPECIFIC_USER_SUCCESS, user: response.data });
    }
    catch (err) { }
};


export {
    list,
    update,
    specific,
};