import React from "react";
import ReactPaginate from "react-paginate";

const Pagination = ({ count, setPagination, pageSize = 10 }) => {

    const handlePageClick = ({ selected }) => {
        setPagination((prev) => {
            return { ...prev, offset: selected * prev.limit };
        });
    };

    return (
        count && count >= pageSize ? (
            <div className="d-flex mt-2">
                <div className="paginationContainer">
                    <ReactPaginate
                        previousLabel="<"
                        previousLinkClassName="pagination-button text-decoration-none"
                        nextLinkClassName="text-decoration-none pagination-button"
                        nextLabel=">"
                        breakLabel="..."
                        breakClassName="break-me"
                        pageCount={Math.ceil(count / pageSize)}
                        marginPagesDisplayed={2}
                        pageRangeDisplayed={3}
                        onPageChange={handlePageClick}
                        containerClassName="pagination"
                        subContainerClassName="pages pagination"
                        activeClassName="active"
                        // forcePage={pageIndex}
                    />
                </div>
            </div>
        ) : null
    );
};

export default Pagination;
