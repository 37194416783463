import Axios from "../../../components/Universal/Axios";
import { Toaster } from "../../../components/Toaster";
import { CommonAction } from "../../common/thunk";
import COMMON_ACTIONS from "../../common/actions";
import { TOAST_TYPE } from "../../../constants";

const approve = (payload, loader, pagination) => async (dispatch) => {
    try {
        const response = await Axios.patch("property/approve", payload, loader);

        if (response.status) {
            Toaster(TOAST_TYPE.SUCCESS, `Property ${payload.isApproved ? 'approved' : 'unapproved'} successfully`);

            dispatch(CommonAction.propertyList(pagination, loader));
        }
        else {
            Toaster(TOAST_TYPE.ERROR, response.error);
        }
    }
    catch (err) { }
};

const listBeds = (loader) => async (dispatch) => {
    try {
        const { data } = await Axios.get("property/beds/category", {}, loader);
        dispatch({ type: COMMON_ACTIONS.SET_BED_CATEGORIES, bedCategories: data });

    }
    catch (err) { }
};

const listAmenities = (loader) => async (dispatch) => {
    try {
        const { data } = await Axios.get("amenity", {}, loader);
        dispatch({
            type: COMMON_ACTIONS.SET_PROPERTY_AMENITIES,
            amenities: data.map(item => ({ label: item.title, value: item._id, checked: false }))
        });
    }
    catch (err) { }
};

export {
    approve,
    listBeds,
    listAmenities,
}; 