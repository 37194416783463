import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { asyncLocalStorage } from "../Auth/LocalStorage";
import Axios from "../Universal/Axios";
import { APP_ROUTES } from "../../constants";

function About({ loader, props }) {
  const [cms, setCms] = useState({});
  const [isVendor, setIsVendor] = useState(false);

  const cmstype = useSelector((state) => state.cms);

  useEffect(() => {
    CMS();
  }, [cmstype.payload]);

  const CMS = async () => {
    try {
      const { userType } = await asyncLocalStorage.getParsedItem(
        "loginDetails"
      );

      let API = "admin";

      if (userType === "VENDOR") {
        setIsVendor(true);
      }

      const { status, data } = await Axios.get(
        `/cms`,
        {
          type: cmstype.payload,
        },
        loader
      );

      if (status) setCms(data);
    } catch (error) {
      console.error("ERROR-->", error);
    }
  };

  return (
    <div className="about">
      <h2>
        {cms.title}
        {isVendor ? null : (
          <span>
            <Link state={{ cms }} to={APP_ROUTES.EDIT_CMS}>
              <i className="far fa-edit"></i>Edit
            </Link>
          </span>
        )}
      </h2>
      <p>{cms.description}</p>
    </div>
  );
}

export default About;
