import React, { useEffect, useState } from "react";
import { asyncLocalStorage } from "../Auth/LocalStorage";
import Pagination from "../Universal/Pagination";
import Axios from "../Universal/Axios";
import { Link } from "react-router-dom";

const Notifications = ({ loader }) => {
  const [notifications, setNotifications] = useState([]);
  const [pagination, setPagination] = useState({
    offset: 0,
    limit: 10,
  });
  const getNotifications = async () => {
    try {
      const { userType } = await asyncLocalStorage.getParsedItem(
        "loginDetails"
      );

      const { status, data } = await Axios.get(
        "/notification/listing",
        {
          userType,
          limit: pagination.limit,
          offset: pagination.offset,
        },
        loader
      );

      if (status)
        setNotifications({
          count: data.count,
          notifications: data.notifications,
        });
    } catch (error) {
      console.error("ERROR-->", error);
    }
  };

  useEffect(() => {
    getNotifications();
  }, [pagination]);

  return (
    <div className="vendor-listing">
      <div className="d-flex justify-content-between align-items-center">
        <ul className="paginaion">
          <li><Link to="/">Dashboard</Link></li>
          <li className="active"><Link to="/">Notifications</Link></li>
        </ul>
      </div>
      {/* <h3>Notifications</h3> */}
      <div className="listing-table">
        <div className="listing-table-scrl">
          <table cellPadding={0} cellSpacing={0}>
            <tr>
              <th></th>
              {/* <th>Image</th> */}
              <th>Name</th>
              <th>Title</th>
              <th>Body</th>
            </tr>

            {notifications.notifications &&
              notifications.notifications.map((notify, index) => (
                <tr key={notify._id}>
                  <td>{++index}</td>
                  {/* <td>
                          <img
                            src={notify.sender_id?.image}
                            alt={notify.sender_id?.firstName}
                          />
                        </td> */}
                  <td>{notify.sender_id?.firstName}</td>
                  <td>{notify.title}</td>
                  <td>{notify.body}</td>
                </tr>
              ))}
          </table>
        </div>
      </div>
      <Pagination
        count={notifications.count + 1}
        setPagination={setPagination}
      />
    </div>
  );
};

export default Notifications;
