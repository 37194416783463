import React, { Fragment, useEffect, useRef, useState } from "react";
import { Formik, Form, Field, FieldArray, ErrorMessage } from "formik";
import { useNavigate, useParams } from "react-router-dom";
import Switch from "react-switch";
import MDEditor from '@uiw/react-md-editor';

import MultiSelect from "../../../../components/Universal/MultiSelect";
import Axios from "../../../../components/Universal/Axios";
import { API_URL } from "../../../../constants";
import { FILE_UPLOAD_TYPE, IMAGE_FILE_TYPES, MAXIMUM_FILE_SIZE_LIMIT, MAXIMUM_FILE_UPLOAD_LIMIT, MESSAGES, MINIMUM_FILE_UPLOAD_LIMIT, TOAST_TYPE } from "../../../../constants";
import { Toaster } from "../../../../components/Toaster";
import FocusError from "../../../../utils/focusError";
import { imageHelper } from "../../../../utils";
import { AddPropertySchema } from "../../../../schemas";
import { APP_ROUTES } from "../../../../constants";
import { Button } from "../../../../components";
import { uploadImage } from "../../../../utils/imageHelper";

const AddProperty = ({ loader }) => {
  const { id } = useParams();
  const [selectedImage, setSelectedImage] = useState([]);
  const [images, setImages] = useState([]);
  const [city, setCity] = useState([]);
  const [areas, setAreas] = useState([]);
  const [buildings, setBuildings] = useState([]);
  const [category, setCategory] = useState([]);
  const [subCategory, setSubCategory] = useState([]);
  const [aminit, setAminities] = useState([]);
  const [subCategories, setSubCat] = useState("");
  const [amenitiesArr, setAmenities] = useState([]);
  const [openAmenities, setOpenAmenities] = useState(false);
  const [property, setProperty] = useState("");
  const [amenitiesName, setAmenitiesName] = useState([]);
  const [bedCategories, setBedCategories] = useState([]);
  const [selectedBathroom, setSelectedBathroom] = useState({});
  const [disabledLocations, setDisabledLocations] = useState({ city: false, area: false, building: false, category: false, subCategory: false });

  const openAmenitiesRef = useRef(null);
  const dragItem = useRef();
  const dragOverItem = useRef();

  const [initialValues, setInitialValues] = useState({
    name: "",
    city_id: "",
    area: '',
    building: '',
    category_id: "",
    subCategory_ids: [],
    price: "",
    description: "",
    hero: "",
    location: "",
    latitude: "",
    longitude: "",
    personCapacity: "",
    totalBeds: 0,
    totalBathrooms: 0,
    beds: [],
    bathrooms: [],
    amenities: [],
    images: [],
    discount: "",
    weekly: "",
    monthly: "",
    maidRoom: false,
    maidRoomBathroomAttached: false,
    driverRoom: false,
    driverRoomBathroomAttached: false,
    areaInSqft: "",
    unitNumber: '',
  });

  const navigate = useNavigate()

  const setPropertyDetails = async () => {
    const propertyDetail = await Axios.get(
      `${API_URL.BASE_URL}${API_URL.VENDOR_PROPERTY_DETAIL}?slug=${id}`,
      {},
      loader
    );
    if (propertyDetail?.status === true) {
      setProperty(propertyDetail?.data);
    }
  };

  useEffect(() => {
    if (!!property) {
      const cities = property.cityData.map(item => ({ isSelected: item.isSelected, name: item.name, status: item.status, _id: item._id }));
      const selectedCity = property.cityData.find(item => item.isSelected);

      const areas = selectedCity.areaData.map(item => ({ isSelected: item.isSelected, name: item.name, status: item.status, _id: item._id, buildings: item.buildingData }));
      const selectedArea = selectedCity.areaData.find(item => item.isSelected);

      const buildings = selectedArea.buildingData.map(item => ({ isSelected: item.isSelected, name: item.name, status: item.status, _id: item._id }));
      const selectedBuilding = buildings.find(item => item.isSelected);

      const categories = property.categoryData.map(item => ({ isSelected: item.isSelected, name: item.name, status: item.status, _id: item._id }));
      const selectedCategory = property.categoryData.find(item => item.isSelected);

      const subCategories = selectedCategory.subCategoryData.map(item => ({ isSelected: item.isSelected, name: item.name, status: item.status, _id: item._id }));
      const selectedSubCategory = selectedCategory.subCategoryData.filter(item => item.isSelected).map(item => ({ value: item._id, label: item.name, _id: item._id }));

      setCity(cities);
      setAreas(areas);
      setBuildings(buildings);
      setCategory(categories);
      setSubCategory(subCategories);

      setDisabledLocations({
        ...disabledLocations,
        city: selectedCity.status === 'INACTIVE',
        area: selectedArea.status === 'INACTIVE',
        building: selectedBuilding.status === 'INACTIVE',
      });

      const amenities = property.amenities.map(item => ({ value: item._id, label: item.title, checked: true }));

      const amenitiesName = amenities.map(item => item.label);
      setAmenitiesName(amenitiesName);

      setSubCat(selectedSubCategory);
      setAminities(amenities);

      const beds = property.bedRoomData.map(item => ({
        bedRoomName: item.bedRoomNumber,
        items: item.bedData.map(innerItem => ({ bedType: innerItem.bedType, totalBeds: innerItem.numberOfBeds }))
      }));

      let selectedBathrooms = {};

      const bathrooms = property.bathRooms.map(item => {
        let obj = {
          private: item.bathRoomPrivate ? item.bathRoomPrivate.toString() : 'false',
          ensuite: item.bathRoomEnsuite ? item.bathRoomEnsuite.toString() : 'false',
          insideBathroom: item.insideAppartment ? item.insideAppartment.toString() : 'false',
          bedroom: item.bedRoomNumber
        };

        if (item.bedRoomNumber) selectedBathrooms[item.bedRoomNumber] = true;
        return obj;
      });

      setSelectedBathroom(selectedBathrooms);

      setInitialValues({
        name: property.name,
        city_id: selectedCity._id,
        category_id: selectedCategory._id,
        subCategory_ids: selectedSubCategory.map(item => item._id),
        amenities: property.amenities.map(item => item._id),
        description: property.description,
        price: property.price,
        personCapacity: property.personCapacity,
        areaInSqft: property.areaInSqft,
        maidRoom: property.otherRoom.maidRoom || false,
        driverRoom: property.otherRoom.driverRoom || false,
        maidRoomBathroomAttached: property.otherRoom.maidRoomBathRoom || false,
        driverRoomBathroomAttached: property.otherRoom.driverRoomBathRoom || false,
        latitude: property.latitude,
        longitude: property.longitude,
        building: selectedBuilding._id,
        area: selectedArea._id,
        beds: beds,
        totalBeds: property.bedRoomData.length,
        totalBathrooms: property.bathRooms.length,
        unitNumber: property.unitNumber,
        bathrooms: bathrooms,
      });

      setSelectedImage(property.images);
      setImages(property.images);
    }
  }, [property]);

  const styles = {
    preview: {
      display: "flex",
      flexDirection: "column",
    },
    image: { maxWidth: "100%" },
    delete: {
      cursor: "pointer",
      color: "white",
      border: "none",
    },
  };

  const processImage = (file) => {
    return new Promise((resolve) => {
      const reader = new FileReader();

      reader.onload = (e) => {

        const img = new Image();
        img.src = e.target.result;

        img.onload = () => {
          const aspectRatio = img.width / img.height;
          const isApprox4by3 = imageHelper.isAspectRatio4by3(img.width, img.height);

          resolve({
            file,
            aspectRatio,
            is4by3: isApprox4by3,
          });
        };
      };

      reader.readAsDataURL(file);
    });
  };

  const handleImageChange = async (event, setFieldError, setFieldValue) => {
    const { files } = event.target;

    if (files && files.length) {
      const fileImages = [];

      for (let i = 0; i < files.length; i++) {
        const result = processImage(files[i]);
        fileImages.push(result);
      }

      const processedImages = await Promise.all(fileImages);

      const invalidFileTypes = processedImages.filter(item => !IMAGE_FILE_TYPES.includes(item.file.type));
      const invalidNewFileSize = processedImages.filter(item => item.file.size > MAXIMUM_FILE_SIZE_LIMIT);
      const invalidOldFileSize = images.filter(item => item.size > MAXIMUM_FILE_SIZE_LIMIT);
      const invalidAspectRatio = processedImages.filter(item => item.is4by3 === false);

      if (images.length + processedImages.length < MINIMUM_FILE_UPLOAD_LIMIT) {
        setFieldError('images', MESSAGES.MINIMUM_UPLOAD_FILE_LIMIT);
      }
      else if (images.length + processedImages.length > MAXIMUM_FILE_UPLOAD_LIMIT) {
        setFieldError('images', MESSAGES.MAXIMUM_UPLOAD_FILE_LIMIT);
      }
      else if (invalidFileTypes.length) {
        setFieldError('images', MESSAGES.VALID_IMAGE_FILE_TYPE);
      }
      else if (invalidNewFileSize.length || invalidOldFileSize.length) {
        setFieldError('images', MESSAGES.UPLOAD_FILE_SIZE_LIMIT);
      }
      // else if (invalidAspectRatio.length) {
      //   setFieldError('images', MESSAGES.FILE_UPLOAD_ASPECT_RATIO);
      // }
      else {
        setFieldError('images', '');
      };

      const selectedFiles = processedImages.map(item => item.file);

      setSelectedImage((prevState) => [...prevState, ...selectedFiles]);
      setImages((prevState) => [...prevState, ...selectedFiles]);
    }
  };

  const handleValidate = async (values) => {
    const errors = {};
    let fileImages = [];
    for (let i = 0; i < images.length; i++) {
      if (images[i] instanceof File) {
        const result = processImage(images[i]);
        fileImages.push(result);
      }
    }

    const processedImages = await Promise.all(fileImages);
    const invalidAspectRatio = processedImages.filter(item => item.is4by3 === false);
    const invalidFileTypes = processedImages.filter(item => !IMAGE_FILE_TYPES.includes(item.file.type));
    const invalidOldFileSize = images.filter(item => item.size > MAXIMUM_FILE_SIZE_LIMIT);
    const invalidNewFileSize = processedImages.filter(item => item.file.size > MAXIMUM_FILE_SIZE_LIMIT);

    if (images.length < MINIMUM_FILE_UPLOAD_LIMIT) {
      errors.images = MESSAGES.MINIMUM_UPLOAD_FILE_LIMIT;
    }
    // else if (invalidAspectRatio.length) {
    //   errors.images = MESSAGES.FILE_UPLOAD_ASPECT_RATIO;
    // }
    else if (invalidFileTypes.length) {
      errors.images = MESSAGES.VALID_IMAGE_FILE_TYPE;
    }
    else if (invalidNewFileSize.length || invalidOldFileSize.length) {
      errors.images = MESSAGES.UPLOAD_FILE_SIZE_LIMIT;
    }

    return errors;
  };

  // Do not remove this empty function
  const handleImageBlur = (event, setFieldError) => {
    if (selectedImage.length < MINIMUM_FILE_UPLOAD_LIMIT) {
      setFieldError('images', MESSAGES.MINIMUM_UPLOAD_FILE_LIMIT);
    }

  };

  const removeAllImages = (setFieldError) => {
    setSelectedImage([]);
    setImages([]);

    setFieldError('images', MESSAGES.MINIMUM_UPLOAD_FILE_LIMIT);
  };

  const removeSpecificImage = (index, setFieldError) => {
    const tempSelectedImage = [...selectedImage];
    tempSelectedImage.splice(index, 1);

    const tempImages = [...images];
    tempImages.splice(index, 1);

    setSelectedImage(tempSelectedImage);
    setImages(tempImages);

    const invalidFileSize = tempImages.filter(item => item.size && item.size > MAXIMUM_FILE_SIZE_LIMIT);
    const invalidFileTypes = tempImages.filter(item => item.type && !IMAGE_FILE_TYPES.includes(item.type));

    if (tempImages.length < 3) {
      setFieldError('images', MESSAGES.MINIMUM_UPLOAD_FILE_LIMIT);
    }
    else if (invalidFileSize.length) {
      setFieldError('images', 'Please upload maximum file size of 3MB.');
    }
    else if (invalidFileTypes.length) {
      setFieldError('images', MESSAGES.VALID_IMAGE_FILE_TYPE);
    }
    else {
      setFieldError('images', '');
    };
  };

  // add-property-api
  const addProperty = async (values, setFieldError) => {
    try {
      if (images.length < 3) {
        setFieldError('images', MESSAGES.MINIMUM_UPLOAD_FILE_LIMIT);
        return;
      }

      const uploadedImages = await Promise.all(
        images?.map((val) => {
          const formData = new FormData();
          formData.append("file", val);
          formData.append("type", FILE_UPLOAD_TYPE.PROPERTY);
          return uploadImage(formData);;
        })
      );

      let fileError = uploadedImages.find(item => item && item.status === false && item.error === 'File too large');

      if (fileError) {
        Toaster(TOAST_TYPE.ERROR, MESSAGES.UPLOAD_FILE_SIZE_LIMIT);
        return;
      }

      const buildingDetails = buildings.find(item => item._id === values.building);

      const params = {
        city_id: values.city_id,
        category_id: values.category_id,
        subCategory_ids: values.subCategory_ids.map(item => item.value),
        name: values.name,
        description: values.description,
        price: values.price,
        latitude: buildingDetails.latitude,
        longitude: buildingDetails.longitude,
        // location: buildingDetails.name,
        personCapacity: values.personCapacity,
        bedrooms: values.bedrooms,
        bathRooms: values.bathRooms,
        images: uploadedImages?.map((val) => val.data.fileUrl),
        amenities: values.amenities,
        areaInSqft: values.areaInSqft,
        currency: 'USD',
        otherRoom: {
          driverRoom: values.driverRoom,
          maidRoom: values.maidRoom,
          driverRoomBathRoom: values.driverRoomBathroomAttached,
          maidRoomBathRoom: values.maidRoomBathroomAttached,
        },
        areaId: values.area,
        buildingId: values.building,
        unitNumber: values.unitNumber
      };

      const { status } = await Axios.post("/property", params, loader);
      if (status) {
        navigate(APP_ROUTES.PROPERTIES_LIST);
        Toaster(TOAST_TYPE.SUCCESS, MESSAGES.PROPERTY.CREATE_SUCCESS);
      };
    } catch (error) {
      console.error("ERROR-->", error);
    }
  };

  const getCity = async () => {
    try {
      const { status, data } = await Axios.get("/location/city", {}, loader);
      if (status) {
        setCity(data);
      };
    } catch (error) {
      console.error("ERROR-->", error);
    }
  };

  const getCategory = async () => {
    try {
      const { status, data } = await Axios.get(
        "/category",
        { type: "PROPERTY" },
        loader
      );

      if (status) setCategory(data.categories);
    } catch (error) {
      console.error("ERROR-->", error);
    }
  };

  const listSubCategories = async (value) => {
    let apiUrl = "/category/" + value;
    const { status, data } = await Axios.get(apiUrl);

    if (status) {
      setSubCat([]);
      setSubCategory(data.sub_categories);
    }
  };

  const subCat = async (e, setFieldValue) => {
    if (e?.target?.name) {
      setFieldValue("subCategory_ids", []);
      setDisabledLocations({
        ...disabledLocations,
        subCategory: false,
      });
    }
    try {
      if (e?.target?.name) setFieldValue(e.target.name, e.target.value);
      listSubCategories(e?.target?.value || e);
    } catch (error) {
      console.error("ERROR-->", error);
    }
  };

  const getAmnities = async () => {
    try {
      const { status, data } = await Axios.get("/amenity", {}, loader);

      if (status) {
        let groupedAminities = data.map(item => ({ label: item.title, value: item._id, checked: false }));

        setAmenities(groupedAminities);
      }
    } catch (error) {
      console.error("ERROR-->", error);
    }
  };

  const listBedCategories = async () => {
    try {
      const { status, data } = await Axios.get("/property/beds/category", {}, loader);

      if (status) {
        setBedCategories(data);
      }
    } catch (error) {
      console.error("ERROR-->", error);
    }
  };

  const handleAmnitiesChange = (event, setFieldValue) => {
    let tempAmnities = [...amenitiesArr];
    let index = tempAmnities.findIndex(item => item.value === event.target.value);

    if (index >= 0) {
      tempAmnities[index].checked = !tempAmnities[index].checked;
      setAmenities(tempAmnities);

      const tempAmnitiesName = tempAmnities.filter(item => item.checked === true).map(item => item.label);
      tempAmnities = tempAmnities.filter(item => item.checked === true).map(item => item.value);

      setFieldValue('amenities', tempAmnities);
      setAmenitiesName(tempAmnitiesName);
    }
  };

  const openAmenitiesBox = (event) => setOpenAmenities(!openAmenities);

  const functions = async () => {
    const promiseArr = [getAmnities(), listBedCategories()];
    if (!property) {
      promiseArr.push(getCity(), getCategory());
    }

    await Promise.all(promiseArr);
  };

  const handleClickOutside = (event) => {
    if (openAmenitiesRef.current && !openAmenitiesRef.current.contains(event.target)) {
      openAmenitiesBox();
    }
  };

  useEffect(() => {
    if (openAmenities) {
      window.addEventListener('click', handleClickOutside);
    }

    return () => {
      window.removeEventListener('click', handleClickOutside);
    };
  }, [openAmenities]);


  useEffect(() => {
    if (id) {
      setPropertyDetails();
    }
  }, [id]);

  useEffect(() => {
    functions();
  }, []);

  useEffect(() => {
    if (amenitiesArr.length && aminit.length) {
      let tempAmenities = [...amenitiesArr];
      for (const item of aminit) {
        const index = tempAmenities.findIndex(it => it.value === item.value);
        if (index > -1) {
          tempAmenities[index].checked = true;
        }
      }
      setAmenities(tempAmenities)
    }
  }, [aminit])

  const dragStart = (event, position) => {
    dragItem.current = position;
  };

  const dragEnter = (event, position) => {
    dragOverItem.current = position;
  };

  const dropImage = () => {
    const copiedSelectedImages = [...selectedImage];
    const copyImages = [...images];

    const dragItemContent = copiedSelectedImages[dragItem.current];

    copiedSelectedImages.splice(dragItem.current, 1);
    copyImages.splice(dragItem.current, 1);
    copiedSelectedImages.splice(dragOverItem.current, 0, dragItemContent);
    copyImages.splice(dragOverItem.current, 0, dragItemContent);

    dragItem.current = null;
    dragOverItem.current = null;

    setSelectedImage(copiedSelectedImages);
    setImages(copyImages);
  };

  const handleCityChange = (event, setFieldValue, setFieldError) => {
    setFieldValue(event.target.name, event.target.value);

    if (event.target.value && event.target.value.length) {
      setFieldError(event.target.name, undefined)
    }

    const specificCity = city.find(item => item._id === event.target.value);

    setDisabledLocations({
      ...disabledLocations,
      city: specificCity ? specificCity.status === 'INACTIVE' : false,
      area: false,
      building: false,
    });

    if (specificCity) {
      const areas = specificCity.areaData.map(item => ({ _id: item._id, name: item.name, buildings: item.buildingData }));

      setAreas(areas);
      setBuildings([]);
      setFieldValue('building', '');
    }
    else {
      setAreas([]);
      setBuildings([]);
    }
  };

  const handleAreaChange = (event, setFieldValue) => {
    setFieldValue(event.target.name, event.target.value);

    const specificArea = areas.find(item => item._id === event.target.value);

    setDisabledLocations({
      ...disabledLocations,
      area: specificArea ? specificArea.status === 'INACTIVE' : false,
      building: false,
    });

    if (specificArea) {

      const buildings = specificArea.buildings.map(item => ({ _id: item._id, name: item.name, latitude: item.latitude, longitude: item.longitude }));

      setBuildings(buildings);
    }
    else setBuildings([]);
  };

  return (
    <div className="add-property-sc">
      <Formik
        initialValues={initialValues}
        validationSchema={AddPropertySchema}
        enableReinitialize={true}
        validate={handleValidate}
        onSubmit={async (values, { setFieldError }) => {
          let payload = values;
          payload.bedrooms = [];
          payload.bathRooms = [];

          for (let bed of payload.beds) {
            const items = bed.items.map(item => ({ numberOfBeds: parseInt(item.totalBeds, 10), bedType: item.bedType, bedRoomNumber: bed.bedRoomName }));
            payload.bedrooms.push(...items);
          }

          for (let bathroom of payload.bathrooms) {
            let obj = {};

            if (bathroom.private === 'true') {
              obj.bathRoomPrivate = true;
              obj.bathRoomEnsuite = false;
              obj.insideAppartment = false;
            } else obj.bathRoomPrivate = false;

            if (bathroom.ensuite === 'true') {
              obj.bathRoomEnsuite = true;
            }
            else if(obj.bathRoomPrivate) {
              obj.insideAppartment = false;
              obj.bathRoomEnsuite = false;
              // delete obj.insideAppartment;
            };
            if (bathroom.insideBathroom === 'true') obj.insideAppartment = true;

            if (bathroom.bedroom) {
              obj.bedRoomNumber = bathroom.bedroom;
              delete obj.insideAppartment;
            };

            payload.bathRooms.push(obj);
          }

          if (!id) addProperty(payload, setFieldError);
          else {
            let uploadedImages = await Promise.all(
              images?.map((val) => {
                if (typeof val !== 'string') {
                  const formData = new FormData();
                  formData.append("file", val);
                  formData.append("type", FILE_UPLOAD_TYPE.PROPERTY);
                  return uploadImage(formData);
                }
              })
            );

            let fileError = uploadedImages.find(item => item && item.status === false && item.error === 'File too large');

            if (fileError) {
              Toaster(TOAST_TYPE.ERROR, MESSAGES.UPLOAD_FILE_SIZE_LIMIT);
              return;
            }

            uploadedImages = uploadedImages.filter(item => item !== undefined).map((val) => val.data.fileUrl);
            uploadedImages = [...uploadedImages, ...images.filter(item => typeof (item) === 'string')];

            const updatePayload = {
              property_id: property?._id,
              otherRoom: {
                driverRoom: values.driverRoom,
                maidRoom: values.maidRoom,
                driverRoomBathRoom: values.driverRoomBathroomAttached,
                maidRoomBathRoom: values.maidRoomBathroomAttached,
              },
              images: uploadedImages,
              buildingId: payload.building,
              areaId: payload.area,
              bedrooms: payload.bedrooms,
              title: values.title,
              city_id: values.city_id,
              category_id: values.category_id,
              subCategory_ids: values.subCategory_ids,
              name: values.name,
              description: values.description,
              personCapacity: values.personCapacity,
              bathRooms: payload.bathRooms,
              areaInSqft: values.areaInSqft,
              amenities: values.amenities,
              unitNumber: values.unitNumber,
            };

            try {
              const { status } = await Axios.patch(
                `${API_URL.BASE_URL}${API_URL.EDIT_PROPERTY}`,
                updatePayload,
                loader
              );

              if (status) {
                navigate(APP_ROUTES.PROPERTIES_LIST);
                Toaster(TOAST_TYPE.SUCCESS, MESSAGES.PROPERTY.UPDATE_SUCCESS);
              };
            } catch (error) {
              console.error("ERROR-->", error);
            }
          }
        }}
      >
        {({ errors, touched, setFieldValue, setFieldError, values, handleSubmit }) => (
          <Form>
            <div className="row">
              <div className="col-md-12">
                <div className="form-group add-image new-add-gallery">
                  <label className="mb-2">Add Images</label>
                  <div style={styles.container}>
                    <Field
                      name="images"
                      placeholder=""
                      type="file"
                      accept="image/*"
                      onChange={(event) => handleImageChange(event, setFieldError, setFieldValue)}
                      className="show-for-sr"
                      multiple
                    />

                    <span>
                      <i className="fas fa-images"></i>
                    </span>
                    {
                      selectedImage.length !== 0 &&
                      <p
                        onClick={() => removeAllImages(setFieldError, setFieldValue)}
                        className="mb-0 text-right mt-2 text-danger cursor-pointer"
                      >
                        Remove All
                      </p>
                    }
                    <div className={`image-pre-outer ${selectedImage.length ? 'selected-images' : null}`}>
                      {selectedImage.map((item, index) =>
                        <div
                          key={index}
                          onDragStart={(e) => dragStart(e, index)}
                          onDragEnd={dropImage}
                          onDragEnter={(e) => dragEnter(e, index)}
                          className="main-img-priew"
                          draggable
                          style={styles.preview}
                        >
                          <img
                            name="hero"
                            src={typeof item === "string" ? item : URL.createObjectURL(item)}
                            style={styles.image}
                            alt="Thumb"
                          />
                          <p onClick={() => removeSpecificImage(index, setFieldError)}>X</p>
                        </div>
                      )}
                    </div>

                    {
                      errors.images ? <div className="error mt-2">{errors.images}</div>
                        :
                        null
                    }
                  </div>
                </div>
              </div>

              <div className="col-md-12">
                <div className="row">
                  <div className="col-md-6 form-group">
                    <label>Property Title</label>
                    <Field name="name" placeholder="Enter name" />
                    {errors.name && touched.name ? (
                      <div className="error">{errors.name}</div>
                    ) : null}
                  </div>
                  <div className="col-md-6 form-group">
                    <label>Unit Number</label>
                    <Field name="unitNumber" placeholder="Enter unit number" />
                    {errors.unitNumber && touched.unitNumber ? (
                      <div className="error">{errors.unitNumber}</div>
                    ) : null}
                  </div>
                </div>
              </div>

              <div className="col-md-12">
                <div className="row">
                  <div className="col-md-6 form-group">
                    <div className="d-flex justify-content-between">
                      <label>Select city</label>
                      {disabledLocations.city ? <label className="disabled-locations">Selected city is not active</label> : null}
                    </div>

                    <Field as="select" name="city_id" onChange={(event) => handleCityChange(event, setFieldValue, setFieldError)}>
                      <option value="">click to select</option>
                      {city.map((city) => (
                        <option key={city._id} value={city._id}>
                          {city.name}
                        </option>
                      ))}
                    </Field>

                    {errors.city_id && touched.city_id ? (
                      <div className="error">{errors.city_id}</div>
                    ) : null}
                  </div>
                  <div className="col-md-6 form-group">
                    <div className="d-flex justify-content-between">
                      <label>Select Area</label>
                      {disabledLocations.area ? <label className="disabled-locations">Selected area is not active</label> : null}
                    </div>
                    <Field
                      onChange={(event) => handleAreaChange(event, setFieldValue)}
                      as="select"
                      name="area"
                    >
                      <option value="">click to select</option>
                      {areas.map((area) => (
                        <option key={area._id} value={area._id}>
                          {area.name}
                        </option>
                      ))}
                    </Field>
                    {errors.area && touched.area ? (
                      <div className="error">{errors.area}</div>
                    ) : null}
                  </div>
                </div>
              </div>

              <div className="col-md-12">
                <div className="row">
                  <div className="col-md-6 form-group mt-4">
                    <div className="d-flex justify-content-between">
                      <label>Select Building</label>
                      {disabledLocations.building ? <label className="disabled-locations">Selected building is not active</label> : null}
                    </div>
                    <Field
                      onChange={(e) => {
                        const building = buildings.find(item => item._id === e.target.value);

                        setDisabledLocations({
                          ...disabledLocations,
                          building: building ? building.status === 'INACTIVE' : false,
                        });
                        setFieldValue(e.target.name, e.target.value);
                      }}
                      as="select"
                      name="building"
                    >
                      <option value="">click to select</option>
                      {buildings.map((building) => (
                        <option key={building._id} value={building._id}>
                          {building.name}
                        </option>
                      ))}
                    </Field>
                    {errors.building && touched.building ? (
                      <div className="error">{errors.building}</div>
                    ) : null}
                  </div>
                  <div className="col-md-6 form-group mt-4">
                    <div className="d-flex justify-content-between">
                      <label>Select category</label>
                      {disabledLocations.category ? <label className="disabled-locations">Selected category is not active</label> : null}
                    </div>
                    <Field
                      onChange={(e) => {
                        subCat(e, setFieldValue);
                      }}
                      as="select"
                      name="category_id"
                    >
                      <option value="">click to select</option>
                      {
                        category.map((category) => (
                          <option key={category._id} value={category._id}>
                            {category.name}
                          </option>
                        ))}
                    </Field>
                    {errors.category_id && touched.category_id ? (
                      <div className="error">{errors.category_id}</div>
                    ) : null}
                  </div>
                </div>
              </div>

              <div className="col-md-12">
                <div className="row">
                  <div className="col-md-6 form-group">
                    <div className="d-flex justify-content-between">
                      <label>Select sub category</label>
                      {disabledLocations.subCategory ? <label className="disabled-locations">Selected sub category is not active</label> : null}
                    </div>

                    <Field
                      name="subCategory_ids"
                      id="multiSelectCustom"
                      placeholder="Select sub-categories"
                      isMulti={true}
                      component={MultiSelect}
                      defaultOption={subCategories}
                      options={
                        subCategory.map((val) => {
                          return {
                            label: val.name,
                            value: val._id,
                          };
                        })
                      }
                    />

                    {errors.subCategory_ids && touched.subCategory_ids ? (
                      <div className="error">{errors.subCategory_ids}</div>
                    ) : null}
                  </div>

                  <div className="col-md-6 form-group">
                    <label>Enter price</label>
                    <Field
                      min={1}
                      name="price"
                      type="number"
                      placeholder="Enter price/day"
                      disabled={!!property}
                      pattern="[0-9]*"
                      validate={(value) => {
                        if (!/^\d*$/.test(value)) {
                          return 'Please enter a whole number for the price.';
                        }
                      }}
                    />
                    {errors.price && touched.price ? (
                      <div className="error">{errors.price}</div>
                    ) : null}
                  </div>
                </div>
              </div>

              <div className="col-md-12 form-group bathroom-select">
                <h5>Bedrooms Options</h5>
                <hr />
                <label>Total Bedrooms</label>
                <Field
                  min={1}
                  name="totalBeds"
                  as="select"
                  value={values.totalBeds}
                  onChange={(event) => {
                    const { name, value } = event.target;
                    let newArr = [...values.beds];

                    if (parseInt(value, 10) > newArr.length) {
                      const newLength = parseInt(value, 10) - newArr.length;

                      for (let i = 0; i < newLength; i++) {
                        newArr.push({
                          bedRoomName: i + 1,
                          items: [{ bedType: '', totalBeds: 0 }]
                        });
                      }
                    }
                    else {
                      let toDelete = newArr.length - parseInt(value, 10);

                      while (toDelete--) {
                        newArr.pop();
                      }
                    }

                    setFieldValue(name, value);
                    setFieldValue('beds', newArr);
                  }}
                >
                  <option value={0}>Click to select</option>
                  <option value={1}>1</option>
                  <option value={2}>2</option>
                  <option value={3}>3</option>
                </Field>
                {errors.totalBeds && touched.totalBeds ? (
                  <div className="error">{errors.totalBeds}</div>
                ) : null}


                <FieldArray
                  name="beds"
                  render={() => (
                    <div>
                      {values.beds.map((bed, index) => (
                        <div className="bathrom-options" key={index}>
                          <div className="inner-option">
                            <h6>Bedroom {index + 1}</h6>
                            <FieldArray
                              name={`beds.${index}.items`}
                              render={(innerArrayHelper) => (
                                <div>
                                  {bed.items.map((innerItem, innerIndex) => (
                                    <div key={innerIndex}>
                                      <div className="row">
                                        <div className="col-md-6">
                                          <div className="form-group">
                                            <label>What kind of beds are available?</label>
                                            <div className="inner-radio-option">
                                              <Field
                                                as="select"
                                                name={`beds.${index}.items.${innerIndex}.bedType`}
                                                onChange={(event) => {
                                                  const { name, value } = event.target;
                                                  setFieldValue(name, value);
                                                }}
                                                value={innerItem.bedType}
                                                onBlur={() => { }}
                                              >
                                                <option value="">Select a bed type</option>
                                                {bedCategories.map((item, key) => (
                                                  <option key={key} value={item._id}>{item.name}</option>
                                                ))}
                                              </Field>
                                              <ErrorMessage component="div" className="error" name={`beds.${index}.items.${innerIndex}.bedType`} />
                                            </div>
                                          </div>
                                        </div>
                                        <div className="col-md-6">
                                          <div className="form-group">
                                            <label>Number of beds</label>
                                            <div className="inner-radio-option">
                                              <Field
                                                as="select"
                                                name={`beds.${index}.items.${innerIndex}.totalBeds`}
                                                onChange={(event) => {
                                                  const { name, value } = event.target;
                                                  setFieldValue(name, value);
                                                }}
                                                value={innerItem.totalBeds}
                                                onBlur={() => { }}
                                              >
                                                <option value={0}>Select number of beds</option>
                                                <option value={1}>1</option>
                                                <option value={2}>2</option>
                                                <option value={3}>3</option>
                                              </Field>
                                              <ErrorMessage component="div" className="error" name={`beds.${index}.items.${innerIndex}.totalBeds`} />
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                      <div className="d-flex justify-content-between align-items-center">
                                        {
                                          bed.items.length === innerIndex + 1 ? <div className="form-group">
                                            <span
                                              className="add-onother-nbed"
                                              onClick={() => innerArrayHelper.push({ bedType: '', totalBeds: 0 })}
                                            >
                                              <i className="fas fa-plus-circle"></i>
                                              Add another bed
                                            </span>
                                          </div> : null
                                        }
                                        {
                                          bed.items.length !== 1 ? <span
                                            className="add-onother-nbed"
                                            onClick={() => innerArrayHelper.remove(innerIndex)}
                                          >
                                            <i className="fas fa-minus-circle"></i>
                                            Remove bed
                                          </span> : null

                                        }
                                      </div>

                                    </div>
                                  ))}
                                </div>
                              )}
                            />
                          </div>
                        </div>
                      ))}
                    </div>
                  )}
                />
              </div>

              <div className="col-md-12 form-group bathroom-select">
                <h5>Bathroom Options</h5>
                <hr />
                <label>Total Bathrooms</label>
                <Field
                  min={1}
                  name="totalBathrooms"
                  as="select"
                  value={values.totalBathrooms}
                  onChange={(event) => {
                    const { name, value } = event.target;
                    let newArr = [...values.bathrooms];

                    if (parseInt(value, 10) > newArr.length) {
                      const newLength = parseInt(value, 10) - newArr.length;

                      for (let i = 0; i < newLength; i++) {
                        newArr.push({
                          private: null,
                          ensuite: null,
                          bedroom: '',
                          insideBathroom: null,
                        });
                      }
                    }
                    else {
                      let toDelete = newArr.length - parseInt(value, 10);

                      while (toDelete--) {
                        newArr.pop();
                      }
                    }

                    setFieldValue(name, value);
                    setFieldValue('bathrooms', newArr);
                  }}
                >
                  <option value={0}>Click to select</option>
                  <option value={1}>1</option>
                  <option value={2}>2</option>
                  <option value={3}>3</option>
                </Field>
                {errors.totalBathrooms && touched.totalBathrooms ? (
                  <div className="error">{errors.totalBathrooms}</div>
                ) : null}

                <FieldArray
                  name="bathrooms"
                  render={(arrayHelper) => (
                    <div>
                      {values.bathrooms.map((item, index) => (
                        <div className="bathrom-options" key={index}>
                          <div className="inner-option">
                            <h6>Bathroom {index + 1}</h6>
                            <div className="form-group">
                              <label>Is the bathroom private ? (not shared with host  or other  guests)</label>
                              <div className="inner-radio-option">
                                <span><Field type="radio" name={`bathrooms.${index}.private`} value="true" /> Yes</span>
                                <span>
                                  <Field
                                    type="radio"
                                    name={`bathrooms.${index}.private`}
                                    value="false"
                                    onChange={(event) => {
                                      const { name, value } = event.target;

                                      if (value === "false") {
                                        setFieldValue(`bathrooms.${index}.ensuite`, false);
                                        setFieldValue(`bathrooms.${index}.bedroom`, false);
                                      }

                                      setFieldValue(name, value);
                                    }}
                                  /> No</span>
                              </div>
                              <ErrorMessage component="div" className="error" name={`bathrooms.${index}.private`} />
                            </div>
                            {
                              item.private === 'true' ?
                                <div className="form-group">
                                  <label>Is the bathroom ensuite ? (attached to or inside the bedroom)</label>
                                  <div className="inner-radio-option">
                                    <span><Field type="radio" name={`bathrooms.${index}.ensuite`} value="true" /> Yes</span>
                                    <span><Field
                                      type="radio"
                                      name={`bathrooms.${index}.ensuite`}
                                      value="false"
                                      onChange={(event) => {
                                        const { name, value } = event.target;

                                        setFieldValue(`bathrooms.${index}.insideBathroom`, '');

                                        if (value === 'false') {
                                          setFieldValue(`bathrooms.${index}.bedroom`, '');
                                        }
                                        setFieldValue(name, value);
                                      }}
                                    /> No</span>
                                  </div>
                                  <ErrorMessage component="div" className="error" name={`bathrooms.${index}.ensuite`} />
                                </div>
                                :
                                null
                            }
                            {
                              item.ensuite === 'true' ?
                                <div className="form-group">
                                  <label>Which bedroom is it attached to?</label>
                                  <div className="inner-radio-option">
                                    <Field
                                      as="select"
                                      name={`bathrooms.${index}.bedroom`}
                                      onChange={(event) => {
                                        const { name, value } = event.target;

                                        setFieldValue(name, value);
                                        let selectedBathroom = {};
                                        for (const item of values.bathrooms) {
                                          if (item.bedroom) selectedBathroom[item.bedroom] = true;
                                        }

                                        setSelectedBathroom({ ...selectedBathroom, [value]: true });
                                      }}
                                    >
                                      <option value={0}>click to select</option>
                                      {values.beds.map((it, index) => <option key={index + 1} disabled={selectedBathroom[(index + 1).toString()] || false} value={index + 1}>Bedroom {index + 1}</option>)}
                                    </Field>
                                  </div>
                                  <ErrorMessage component="div" className="error" name={`bathrooms.${index}.bedroom`} />
                                </div>
                                : null
                            }
                            {
                              item.ensuite === 'false' ?
                                <div className="form-group">
                                  <label>Is the bathroom inside the apartment ?</label>
                                  <div className="inner-radio-option">
                                    <span><Field type="radio" name={`bathrooms.${index}.insideBathroom`} value="true" /> Yes</span>
                                    <span><Field type="radio" name={`bathrooms.${index}.insideBathroom`} value="false" /> No</span>
                                  </div>
                                  <ErrorMessage component="div" className="error" name={`bathrooms.${index}.insideBathroom`} />
                                </div> : null
                            }
                          </div>
                        </div>
                      ))}
                    </div>
                  )}
                />
              </div>

              <div className="col-md-12">
                <div className="row">
                  <div className="col-md-6 form-group">
                    <label>Person Capacity</label>
                    <Field
                      min={1}
                      name="personCapacity"
                      type="number"
                      placeholder="Enter Person Capacity"
                    />
                    {errors.personCapacity && touched.personCapacity ? (
                      <div className="error">{errors.personCapacity}</div>
                    ) : null}
                  </div>

                  <div className="col-md-6 form-group">
                    <label>Area (In SQFT)</label>
                    <Field
                      min={1}
                      name="areaInSqft"
                      type="number"
                      placeholder="Enter Area in SQFT"
                    />
                    {errors.areaInSqft && touched.areaInSqft ? (
                      <div className="error">{errors.areaInSqft}</div>
                    ) : null}
                  </div>
                </div>
                <div className="col-md-12 form-group">
                  <div className="row">
                    {/* <div className="col-md-6 form-group">
                      <label>Bathrooms</label>
                      <Field
                        min={1}
                        name="bathrooms"
                        type="number"
                        placeholder="Enter Bathrooms"
                      />
                      {errors.bathrooms && touched.bathrooms ? (
                        <div className="error">{errors.bathrooms}</div>
                      ) : null}
                    </div> */}
                  </div>
                </div>

                <div className="col-md-12">
                  <div className="row">
                    <div className="col-md-12 form-group">
                      <label>Amenities</label>

                      <div ref={openAmenitiesRef}>
                        <div className="aminityi d-flex bg-white" onClick={openAmenitiesBox}>
                          {amenitiesName.length === 0 ? <span className="align-self-center">Select Amenities</span> : null}
                          {amenitiesName.map((item, index) => <div key={index} className="mr-2">{item}</div>)}
                        </div>
                        {openAmenities && <div className="aminity-box">
                          {openAmenities && amenitiesArr.map((item, index) => (
                            <div className="d-flex" key={index}>
                              <input type="checkbox" checked={item.checked} value={item.value} onChange={(event) => handleAmnitiesChange(event, setFieldValue)} />
                              {item.label}
                            </div>
                          ))}
                        </div>}
                      </div>

                      {errors.amenities && touched.amenities ? (
                        <div className="error">{errors.amenities}</div>
                      ) : null}
                    </div>
                  </div>
                </div>

                <div className="col-md-12">
                  <div className="form-group description" data-color-mode="light">
                    <label>Enter Description</label>
                    <MDEditor
                      value={values.description}
                      onChange={(value) => setFieldValue('description', value)}
                      preview="edit"
                      commandsFilter={(cmd) => cmd && /(fullscreen|edit|live|preview|link|code|image)/.test(cmd.name) ? false : cmd}
                    />
                    {errors.description && touched.description ? (
                      <div className="error">{errors.description}</div>
                    ) : null}
                  </div>
                </div>
                <div className="col-md-12">
                  <div className="row">
                    <div className="col-md-6 form-group d-flex justify-content-between align-items-center">
                      <label>Maid Room</label>
                      <Switch
                        onColor="#e89532"
                        onChange={(value) => setFieldValue('maidRoom', value)}
                        checked={values.maidRoom}
                      />
                    </div>

                    <div className="col-md-6 form-group d-flex justify-content-between align-items-center">
                      <label>Driver Room</label>
                      <Switch
                        onColor="#e89532"
                        onChange={(value) => setFieldValue('driverRoom', value)}
                        checked={values.driverRoom}
                      />
                    </div>
                  </div>
                </div>
                <div className={`col-md-12 ${!values.maidRoom && !values.driverRoom ? 'd-none' : ''}`}>
                  <div className="row">
                    <div className="col-md-6 form-group d-flex justify-content-between align-items-center">
                      {values.maidRoom ? <Fragment>
                        <label>Is Bathroom Attached</label>
                        <Switch
                          onColor="#e89532"
                          onChange={(value) => setFieldValue('maidRoomBathroomAttached', value)}
                          checked={values.maidRoomBathroomAttached}
                        />
                      </Fragment> : null}
                    </div>

                    <div className="col-md-6 form-group d-flex justify-content-between align-items-center">
                      {values.driverRoom ? <Fragment>
                        <label>Is Bathroom Attached</label>
                        <Switch
                          onColor="#e89532"
                          onChange={(value) => setFieldValue('driverRoomBathroomAttached', value)}
                          checked={values.driverRoomBathroomAttached}
                        />
                      </Fragment> : null}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <Button type="submit" onClick={handleSubmit} title={id ? 'Edit' : 'Add'} />
            <FocusError />
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default AddProperty;
