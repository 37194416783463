import { useFormik } from "formik";
import { useEffect } from "react";
import moment from 'moment';

import { TourCalendarPriceSchema, TourCalendarMaximumPersonSchema, TourCalendarPickupSchema } from "../../../../schemas/tourCalendarSchema";

const PricePopup = (props) => {
    const pricePopupFormik = useFormik({
        initialValues: { price: 0, maximumPerson: 0, pickUpDrop: 0 },
        validationSchema: props.type === 'price' ? TourCalendarPriceSchema : props.type === 'maximumPerson' ? TourCalendarMaximumPersonSchema : TourCalendarPickupSchema,
        onSubmit: (values) => {
            let payload;

            if (props.values && props.values.length) {
                values.pickupAndDrop = values.pickUpDrop == 1;
                delete values.pickUpDrop;

                const groupedValues = props.values.reduce((result, item) => {
                    if (!result[item.subCategoryId]) {
                        result[item.subCategoryId] = [];
                    }

                    result[item.subCategoryId].push(item);

                    return result;
                }, []);

                payload = {
                    selectedDates: props.values.map(item => moment(item.date).format('YYYY-MM-DD')),
                    subCategoryData: Object.keys(groupedValues).map(item => ({
                        _id: item,
                        ...values,
                    })),
                };
            }
            else {
                let updatePayload = {};

                if (props.type === 'price') updatePayload.price = values.price;
                else if (props.type === 'maximumPerson') updatePayload.maximumPerson = values.maximumPerson;
                else updatePayload.pickupAndDrop = values.pickUpDrop == 1;

                payload = {
                    selectedDates: [props.values.date],
                    subCategoryData: [{
                        ...updatePayload,
                        _id: props.values.subCategoryId,
                    }]
                };
            }
            props.updateTourCalendar(payload);
            props.closeModal(false);
        },
        enableReinitialize: true
    });

    useEffect(() => {
        if (props.multipleDates.length === 0 && props.values) {
            if (props.type === 'price') pricePopupFormik.setFieldValue('price', props.values.price);
            else if (props.type === 'maximumPerson') pricePopupFormik.setFieldValue('maximumPerson', props.values.maximumPerson);
            else pricePopupFormik.setFieldValue('pickUpDrop', props.values.pickupAndDrop ? 1 : 0);
        }
        else {
            pricePopupFormik.setFieldValue('price', 0);
            pricePopupFormik.setFieldValue('maximumPerson', 0);
            pricePopupFormik.setFieldValue('pickUpDrop', '');
        };
    }, [props.values]);

    return (
        <div
            className={`modal fade ${props.show ? 'show d-block' : ''}`}
            id="calendar-price"
            tabIndex="-1"
            aria-labelledby="exampleModalLabel"
            aria-hidden="true"
        >
            <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title" id="exampleModalLongTitle">Change Tour</h5>
                        <button
                            type="button"
                            className="btn-close"
                            data-bs-dismiss="modal"
                            aria-label="Close"
                            onClick={() => props.closeModal(false)}
                        ></button>
                    </div>
                    {props.type === 'price' ?
                        <div className="modal-body">
                            <label>Price</label>
                            <input
                                type="text"
                                name="price"
                                id="price"
                                className="form-control px-3 py-2"
                                value={pricePopupFormik.values.price}
                                onChange={pricePopupFormik.handleChange}
                                onBlur={pricePopupFormik.handleBlur}
                            />
                            {pricePopupFormik.touched.price && pricePopupFormik.errors.price ? <p className='text-danger mb-0'>{pricePopupFormik.errors.price}</p> : null}
                        </div> : props.type === 'maximumPerson' ?
                            <div className="modal-body">
                                <label>Maximum Person allowed</label>
                                <input
                                    type="text"
                                    name="maximumPerson"
                                    id="maximumPerson"
                                    className="form-control px-3 py-2"
                                    value={pricePopupFormik.values.maximumPerson}
                                    onChange={pricePopupFormik.handleChange}
                                    onBlur={pricePopupFormik.handleBlur}
                                />
                                {pricePopupFormik.touched.maximumPerson && pricePopupFormik.errors.maximumPerson ? <p className='text-danger mb-0'>{pricePopupFormik.errors.maximumPerson}</p> : null}
                            </div> :
                            <div className="modal-body">
                                <label>Pick up/drop facility</label>
                                <select
                                    onChange={(e) => pricePopupFormik.setFieldValue(`pickUpDrop`, e.target.value)}
                                    as="select"
                                    name="pickUpDrop"
                                    value={pricePopupFormik.values.pickUpDrop}
                                >
                                    <option value="">Click to select</option>
                                    <option value={1}>Yes</option>
                                    <option value={0}>No</option>
                                </select>
                            </div>
                    }

                    {/* <div className="px-3 pt-2">
                        <p className={styles.categoryName}>{props?.selectedProperty?.category_id?.name} (Room ID: {props?.selectedProperty?.unitNumber})</p>
                        <p className={styles.rate}>Standard Rate</p>
                        <p className={styles.priceDate}>{moment(props.priceDates.startDate).format('DD MMM YYYY')} {!moment(props.priceDates.startDate).isSame(moment(props.priceDates.endDate)) ? `to ${moment(props.priceDates.endDate).format('DD MMM YYYY')}` : ''}</p>
                    </div>
                    {props?.priceDetails?.dragType === CALENDAR_DRAGGING_TYPE.PRICE_DRAGGING ?
                        <Fragment>
                            <div className="px-3 d-flex pt-2">
                                <label className={`rad ${styles.openCloseStatus}`}>
                                    <input
                                        type="radio"
                                        checked={pricePopupFormik.values.openCloseStatus == OPEN_CLOSE_BOOKING_TYPES.OPEN}
                                        name="radio"
                                        onChange={() => pricePopupFormik.setFieldValue('openCloseStatus', OPEN_CLOSE_BOOKING_TYPES.OPEN)}
                                    />
                                    <span className="checkmark"></span>
                                    Vacant
                                </label>
                                <label className={`rad ${styles.openCloseStatus}`}>
                                    <input
                                        type="radio"
                                        checked={pricePopupFormik.values.openCloseStatus == OPEN_CLOSE_BOOKING_TYPES.CLOSE}
                                        name="radio"
                                        onChange={() => pricePopupFormik.setFieldValue('openCloseStatus', OPEN_CLOSE_BOOKING_TYPES.CLOSE)}
                                    />
                                    <span className="checkmark"></span>
                                    Close
                                </label>
                            </div>
                            <div className="px-3">
                                {pricePopupFormik.errors.openCloseStatus ? <p className='text-danger mb-0'>{pricePopupFormik.errors.openCloseStatus}</p> : null}
                            </div>
                            <div className="modal-body">
                                <label>Price</label>
                                <input
                                    type="text"
                                    name="price"
                                    id="price"
                                    className="form-control px-3 py-2"
                                    value={pricePopupFormik.values.price}
                                    onChange={pricePopupFormik.handleChange}
                                    onBlur={pricePopupFormik.handleBlur}
                                />
                                {pricePopupFormik.touched.price && pricePopupFormik.errors.price ? <p className='text-danger mb-0'>{pricePopupFormik.errors.price}</p> : null}
                            </div>
                        </Fragment>
                        :
                        <Fragment>
                            <div className="modal-body">
                                <label>Minimum length of stay</label>
                                <input
                                    type="text"
                                    name="night"
                                    className="form-control px-3 py-2"
                                    value={nightsPopupFormik.values.night}
                                    onChange={nightsPopupFormik.handleChange}
                                    onBlur={nightsPopupFormik.handleBlur}
                                />
                                {nightsPopupFormik.touched.night && nightsPopupFormik.errors.night ? <p className='text-danger mb-0'>{nightsPopupFormik.errors.night}</p> : null}
                            </div>
                        </Fragment>} */}
                    <div className="modal-footer">
                        <button
                            type="button"
                            className="btn btn-primary"
                            onClick={(event) => pricePopupFormik.submitForm()}>
                            Update
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default PricePopup;
