
import Axios from "../../../components/Universal/Axios";
import { Toaster } from "../../../components/Toaster";
import { APP_ROUTES, MESSAGES, TOAST_TYPE } from "../../../constants";
import COMMON_ACTIONS from "../../common/actions";
import { CommonAction } from "../../common/thunk";
import VENDOR_ACTIONS from "../actions";

const remove = (params, loader, pagination) => async (dispatch) => {
    try {
        const response = await Axios.delete("car", { params }, loader);
        if (response.status) {
            Toaster(TOAST_TYPE.SUCCESS, MESSAGES.CAR.REMOVE_SUCCESS);

            dispatch(CommonAction.carsList(pagination, loader));
        }
        else {
            Toaster(TOAST_TYPE.ERROR, response.error);
        }
    }
    catch (err) { }
};

const specific = (params, loader) => async (dispatch) => {
    try {
        const response = await Axios.get("car/details", params, loader);

        dispatch({ type: VENDOR_ACTIONS.SET_CAR_DETAILS, car: response.data });
        dispatch({ type: COMMON_ACTIONS.SET_IMAGES, key: 'selectedImage', value: response.data.images });
        dispatch({ type: COMMON_ACTIONS.SET_IMAGES, key: 'images', value: response.data.images });

    }
    catch (err) { }
};

const update = (payload, loader, navigate) => async (dispatch) => {
    try {
        const response = await Axios.patch("car", payload, loader);

        if (response.status) {
            Toaster(TOAST_TYPE.SUCCESS, MESSAGES.CAR.UPDATE_SUCCESS);
            dispatch(CommonAction.specificCar({ id: payload.car_id }, loader));
        }
    }
    catch (err) { }
};

const create = (payload, loader, navigate) => async (dispatch) => {
    try {
        const response = await Axios.post("car", payload, loader);

        if (response.status) {
            Toaster(TOAST_TYPE.SUCCESS, MESSAGES.CAR.CREATE_SUCCESS);

            navigate(APP_ROUTES.CARS_LIST);
            dispatch({ type: COMMON_ACTIONS.SET_IMAGES, key: 'selectedImage', value: [] });
            dispatch({ type: COMMON_ACTIONS.SET_IMAGES, key: 'images', value: [] });
        }
    }
    catch (err) { }
};

export {
    remove,
    specific,
    update,
    create
};