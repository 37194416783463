import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Switch from "react-switch";
import Pagination from "../Universal/Pagination";
import Axios from "../Universal/Axios";

function Revenue({ loader }) {
    const [cities, setCities] = useState([]);
    const [revenuedata, setRevenue] = useState("PROPERTY");
    const [pagination, setPagination] = useState({
      offset: 0,
      limit: 10,
    });
    const getCities = async () => {
      try {
        const { status, data } = await Axios.get(
          `admin/revenue`,
          {
            limit: pagination.limit,
            offset: pagination.offset,
          },
          loader
        );
  
        if (status)
          setCities({
            count: data.count,
            cities: data,
          });
      } catch (error) {}
    };

  
    useEffect(() => {
      getCities();
    }, [pagination]);
  
    return (
      <div className="vendor-listing">
        <ul>
            <li>
                <span className={revenuedata === "PROPERTY" ? "active" : "inactive"} onClick={() => setRevenue("PROPERTY")}>Properties Revenue</span>
            </li>
            <li>
                <span className={revenuedata === "CAR" ? "active" : "inactive"} onClick={() => setRevenue("CAR")}>Car Revenue</span>
            </li>
            <li>
                <span className={revenuedata === "TOUR" ? "active" : "inactive"} onClick={() => setRevenue("TOUR")}>Tours Revenue</span>
            </li>
        </ul>
        {revenuedata === "PROPERTY" && 
        <>
         <h3>Property  Revenue</h3>
         <div className="listing-table">
           <div className="listing-table-scrl">
             <table cellPadding={0} cellSpacing={0}>
               <tr>
                 <th>S No.</th>
                 <th>Name</th>
                 <th>User Name</th>
                 <th>Vendor Name</th>
                 <th>Date</th>
                 <th>Total Revenue</th>
                
               </tr>
   
               {cities.cities
 ?.propertyRevenue &&  cities.cities.propertyRevenue.map((city, index) => (
                   <tr key={city._id}>
                     <td>{++index}</td>
                     <td>
                       {city.name} 
                     </td>
                     <td>
                       {city.userFirstName}  {city.userLastName}
                     </td>
                     <td>{city.vendorFirstName} {city.vendorLastName}</td>
                     <td>{city.createdAt.split("T")[0]}</td>
                     <td>{city.totalAmount ? city.totalAmount : "-"}</td>
                    
                   
                    
                   </tr>
                 ))}
             </table>
           </div>
   
           <Pagination count={cities.count + 1} setPagination={setPagination} />
         </div>
         
         </>
        }

{revenuedata === "CAR" && 
        <>
         <h3>Car  Revenue</h3>
         <div className="listing-table">
           <div className="listing-table-scrl">
             <table cellPadding={0} cellSpacing={0}>
               <tr>
                 <th>S No.</th>
                 <th>Name</th>
                 <th>User Name</th>
                 <th>Vendor Name</th>
                 <th>Date</th>
                 <th>Total Revenue</th>
                
               </tr>
   
               {cities.cities
 ?.carRevenue &&  cities.cities.carRevenue.map((city, index) => (
                   <tr key={city._id}>
                     <td>{++index}</td>
                     <td>
                       {city.name} 
                     </td>
                     <td>
                       {city.userFirstName}  {city.userLastName}
                     </td>
                     <td>{city.vendorFirstName} {city.vendorLastName}</td>
                     <td>{city.createdAt.split("T")[0]}</td>
                     <td>{city.totalAmount ? city.totalAmount : "-"}</td>
                    
                   
                    
                   </tr>
                 ))}
             </table>
           </div>
   
           <Pagination count={cities.count + 1} setPagination={setPagination} />
         </div>
         
         </>
        }
       

       {revenuedata === "TOUR" && 
        <>
         <h3>Tours  Revenue</h3>
         <div className="listing-table">
           <div className="listing-table-scrl">
             <table cellPadding={0} cellSpacing={0}>
               <tr>
                 <th>S No.</th>
                 <th>Name</th>
                 <th>User Name</th>
                 <th>Vendor Name</th>
                 <th>Date</th>
                 <th>Total Revenue</th>
                
               </tr>
   
               {cities.cities
 ?.tourRevenue &&  cities.cities.tourRevenue.map((city, index) => (
                   <tr key={city._id}>
                     <td>{++index}</td>
                     <td>
                       {city.name} 
                     </td>
                     <td>
                       {city.userFirstName}  {city.userLastName}
                     </td>
                     <td>{city.vendorFirstName} {city.vendorLastName}</td>
                     <td>{city.createdAt.split("T")[0]}</td>
                     <td>{city.totalAmount ? city.totalAmount : "-"}</td>
                    
                   
                    
                   </tr>
                 ))}
             </table>
           </div>
   
           <Pagination count={cities.count + 1} setPagination={setPagination} />
         </div>
         
         </>
        }
      </div>
    );
}

export default Revenue