import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";

import { asyncLocalStorage } from "../Auth/LocalStorage";
import { cmsData } from "../../store/slices/authSlice";
import { SideBar } from "./sideBarRoutes";
import { APP_ROUTES, CMS_TYPE } from "../../constants";
import { Logo } from "../../assets/images";

const Sidebar = ({ open, pathname }) => {
    const dispatch = useDispatch();
    const location = useLocation();

    const [isVendor, setIsVendor] = useState(false);
    const [bookingRequest, setBookingRequestMenu] = useState(false);
    const [calendarRequest, setCalendarRequestMenu] = useState(false);
    const [submenu, setSubmenu] = useState(false);

    useEffect(() => {
        (async () => {
            const loginDeatils = await asyncLocalStorage.getParsedItem(
                "loginDetails"
            );
            if (loginDeatils)
                setIsVendor(loginDeatils.userType === "VENDOR" ? true : false);
        })();
    }, []);

    const sunMenu = () => {
        setSubmenu(!submenu);
    };

    const handleMobileSidebarChange = () => {
        if (window.innerWidth < 767) {
            open();
        }
    };

    const cmsType = (type) => dispatch(cmsData(type));
    return (
        <div className="side-bar">
            <div className="logo">
                <Link to={APP_ROUTES.DASHBOARD}>
                    <img src={Logo} alt="logo" />
                </Link>
                <span className="side-br-mobile">
                    <i className="fas fa-bars" onClick={open}></i>
                </span>
            </div>

            <ul>
                {SideBar.map((val, index) => {
                    const list =
                        val.path === APP_ROUTES.CMS ? (
                            <li key={index} className="down-arow" onClick={sunMenu}>
                                <i className="far fa-address-card"></i>Content Management
                                {submenu && (
                                    <ul className="submenu">
                                        <li onClick={() => cmsType(CMS_TYPE.ABOUT_US)}>
                                            <Link to={APP_ROUTES.CMS}>About us</Link>
                                        </li>
                                        <li onClick={() => cmsType(CMS_TYPE.TERM_AND_CONDITION)}>
                                            <Link to={APP_ROUTES.CMS}>Terms & Condition</Link>
                                        </li>
                                        <li onClick={() => cmsType(CMS_TYPE.PRIVACY_POLICY)}>
                                            <Link to={APP_ROUTES.CMS}>Privacy Policy</Link>
                                        </li>
                                    </ul>
                                )}
                            </li>
                        ) : [APP_ROUTES.PROPERTY_REQUESTS].includes(val.path) ?
                            <li key={index} className="cursor-pointer down-arow" onClick={() => setBookingRequestMenu(!bookingRequest)}>
                                <i className="far fa-address-card"></i>Booking Requests
                                {bookingRequest && (
                                    <ul className="submenu mt-2">
                                        <li onClick={handleMobileSidebarChange}>
                                            <Link className={location.pathname === APP_ROUTES.PROPERTY_REQUESTS ? 'active' : null} to={APP_ROUTES.PROPERTY_REQUESTS}>
                                                <i className="far fa-building me-4" />
                                                Property
                                            </Link>
                                        </li>
                                        <li onClick={handleMobileSidebarChange}>
                                            <Link className={location.pathname === APP_ROUTES.CAR_REQUESTS ? 'active' : null} to={APP_ROUTES.CAR_BOOKINGS_LIST}>
                                                <i className="fas fa-car me-4" />
                                                Car
                                            </Link>
                                        </li>
                                        <li onClick={handleMobileSidebarChange}>
                                            <Link className={location.pathname === APP_ROUTES.TOUR_BOOKINGS ? 'active' : null} to={APP_ROUTES.TOUR_BOOKINGS}>
                                                <i className="fa fa-fighter-jet me-3" />
                                                Tour
                                            </Link>
                                        </li>
                                    </ul>
                                )}
                            </li> : [APP_ROUTES.PROPERTY_CALENDAR].includes(val.path) ?
                                <li key={index} className={`cursor-pointer down-arow`} onClick={() => setCalendarRequestMenu(!calendarRequest)}>
                                    <i className="far fa-address-card"></i>Calendar
                                    {calendarRequest && (
                                        <ul className="submenu mt-2">
                                            <li onClick={handleMobileSidebarChange}>
                                                <Link className={location.pathname === APP_ROUTES.PROPERTY_CALENDAR ? 'active' : null} to={APP_ROUTES.PROPERTY_CALENDAR}>
                                                    <i className="far fa-building me-4" />
                                                    Property
                                                </Link>
                                            </li>
                                            <li onClick={handleMobileSidebarChange}>
                                                <Link className={location.pathname === APP_ROUTES.TOUR_CALENDAR ? 'active' : null} to={APP_ROUTES.TOUR_CALENDAR}>
                                                    <i className="fa fa-fighter-jet me-3" />
                                                    Tour
                                                </Link>
                                            </li>
                                        </ul>
                                    )}
                                </li> : (
                                    <li key={index} className={pathname === val.path ? "active" : "inactive"}>
                                        <Link 
                                        target= {val.name == 'Reset Booking' ? "_blank" : ''}
                                        onClick={() => {
                                            if (val.function) {
                                                val.function();
                                            }
                                            handleMobileSidebarChange();
                                        }} to={val.to}>
                                            <i className={val.class}></i>
                                            {val.name}
                                        </Link>
                                    </li>
                                );

                    if (isVendor && val.vendor) return list;
                    if (!isVendor && val.admin) return list;
                })}
            </ul>
        </div>
    );
}

export default Sidebar;
