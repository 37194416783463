/* eslint-disable react-hooks/exhaustive-deps */
import { useRef } from "react";
import { useState } from "react";
import { useEffect } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import moment from 'moment';
import momentTz from 'moment-timezone';
import { useFormik } from "formik";
import DatePicker from "react-datepicker";

import { CommonAction } from "../../../../store/common/thunk";
import { CalendarFilterSchema } from "../../../../schemas";
import { VendorTourAction } from "../../../../store/vendor/thunk";
import PricePopup from "./PricePopup";
import VENDOR_ACTIONS from "../../../../store/vendor/actions";
import styles from '../style.module.css';
import { Button } from "../../../../components";
import CalendarPopup from "./CalendarPopup";
import { CalendarLoader, PhotoIcon } from '../../../../assets/images';

const TourCalendar = (props) => {
    const dispatch = useDispatch();

    const [selectedTour, setSelectedTour] = useState(false);
    const [showTour, setTourModal] = useState(false);
    const [tourPopup, setTourPopup] = useState({ show: false, type: null, values: null });
    const [calendarPopup, setCalendarPopup] = useState({ show: false, values: null });
    const [multipleDates, setMultipleDates] = useState([]);
    const [calendarDates, setCalendarDates] = useState([]);

    const tourPopupRef = useRef(null);
    const calendarDateScrollbarRef = useRef(null);
    const tourPriceScrollbarRef = useRef([]);

    const { toursList } = useSelector(state => state.common);
    const { tours: { tourPrices, priceLoader } } = useSelector(state => state.vendor);

    const openTours = () => setTourModal(!showTour);

    const fetchTours = () => {
        const query = { offset: 0, limit: 1000 };

        props.getTours(query, props.loader);
    };

    const handleOutsideClick = (event) => {

        if (tourPopupRef.current && !tourPopupRef.current.contains(event.target)) {
            if (event.target.className === 'outer-list-pro') setTourModal(false);
            else setTourModal(true);
        }
    };

    const fetchCalendar = async (resetDates) => {
        const params = {
            tourId: selectedTour._id,
            fromDate: moment(calendarFormik.values.startDate).format('YYYY-MM-DD'),
            toDate: moment(calendarFormik.values.endDate).format('YYYY-MM-DD'),
        };

        props.getTourPrices(params, props.loader, resetDates);
    };

    const calendarFormik = useFormik({
        initialValues: { startDate: moment().toDate(), endDate: moment().add(1, 'month').toDate() },
        onSubmit: (values) => {
            fetchCalendar(false);
        },
        validationSchema: CalendarFilterSchema,
    });

    const handleCalendarDateScroll = () => {
        if (calendarDateScrollbarRef.current && tourPriceScrollbarRef.current) {
            const { scrollLeft } = calendarDateScrollbarRef.current;

            for (const item of tourPriceScrollbarRef.current) {
                item.scrollLeft = scrollLeft;
            }
        }
    };


    const handleCalendarClick = (event, values) => {
        let tempTourPrices = tourPrices;
        let tempMultipleDates = calendarDates;
        const selectedTourDate = moment(values.date);

        for (const [index, item] of Object.entries(tempTourPrices)) {
            for (const mt of item.monthlyData) {
                for (const innerMt of mt.data) {
                    let date = moment(innerMt.date);

                    if (
                        date.get('month') === selectedTourDate.get('month') &&
                        date.get('date') === selectedTourDate.get('date') &&
                        date.get('year') === selectedTourDate.get('year')
                    ) {
                        if (event.ctrlKey && parseInt(index, 10) === 0) {
                            innerMt.isCalendarDateSelected = !innerMt.isCalendarDateSelected;
                        }

                        let existingDate = tempMultipleDates.findIndex(it => it.date === date.format('YYYY-MM-DD') && item._id.subCategoryId === it.subCategoryId);

                        if (existingDate > -1) {
                            tempMultipleDates.splice(existingDate, 1);
                        }
                        else {
                            tempMultipleDates.push({
                                date: selectedTourDate.format('YYYY-MM-DD'),
                                price: innerMt.price,
                                tourId: innerMt.tourId,
                                subCategoryId: item._id.subCategoryId,
                                maximumPerson: innerMt.maximumPerson,
                                pickupDrop: innerMt.pickupAndDrop,
                                name: item._id.name,
                            });
                        };
                    }
                }
            }
        }

        setCalendarDates(tempMultipleDates);
        dispatch({ type: VENDOR_ACTIONS.SET_TOUR_PRICE, pricing: tempTourPrices });

        if (!event.ctrlKey) {
            setCalendarPopup({ values: tempMultipleDates, show: true });
        }
    };

    const handleCalendarChange = (event, type, values) => {
        if (event.ctrlKey) {
            let tempTourPrices = tourPrices;
            let tempMultipleDates = multipleDates;

            const selectedTourDate = moment(values.date);

            for (const item of tempTourPrices) {
                for (const mt of item.monthlyData) {
                    for (const innerMt of mt.data) {
                        let date = moment(innerMt.date);
                        if (
                            date.get('month') === selectedTourDate.get('month') &&
                            date.get('date') === selectedTourDate.get('date') &&
                            date.get('year') === selectedTourDate.get('year') &&
                            item._id.subCategoryId === values.subCategoryId
                        ) {
                            const checkForSameSubCategory = tempMultipleDates.filter(item => item.subCategoryId !== values.subCategoryId);
                            const checkForSameType = tempMultipleDates.filter(item => item.type !== type);

                            let existingDate = tempMultipleDates.findIndex(item => item.date === date.format('YYYY-MM-DD') && item.subCategoryId === values.subCategoryId);

                            if (!checkForSameSubCategory.length && !checkForSameType.length) {
                                if (existingDate > -1) {
                                    tempMultipleDates.splice(existingDate, 1);
                                }
                                else {
                                    tempMultipleDates.push({
                                        date: selectedTourDate.format('YYYY-MM-DD'),
                                        price: values.price,
                                        tourId: values.tourId,
                                        subCategoryId: values.subCategoryId,
                                        type,
                                    });
                                };

                                if (type === 'price') innerMt.isPriceSelected = !innerMt.isPriceSelected;
                                else if (type === 'maximumPerson') innerMt.isMaximumPersonSelected = !innerMt.isMaximumPersonSelected;
                                else innerMt.isPickupDropSelected = !innerMt.isPickupDropSelected;

                            }
                        }
                    }
                }
            }

            setMultipleDates(tempMultipleDates);
            dispatch({ type: VENDOR_ACTIONS.SET_TOUR_PRICE, pricing: tempTourPrices });

            setTourPopup({ ...tourPopup, type: type });
        }
        else {
            setTourPopup({ show: true, type: type, values: values });
        };
    };

    const updateTourCalendar = (values) => {
        const payload = {
            ...values,
            tourId: selectedTour._id,
        };

        const params = {
            tourId: selectedTour._id,
            fromDate: moment(calendarFormik.values.startDate).format('YYYY-MM-DD'),
            toDate: moment(calendarFormik.values.endDate).format('YYYY-MM-DD'),
        };
        props.updateTourCalendar(payload, props.loader, params);
    };

    const bulkUpdateCalendar = () => {
        if (multipleDates.length) setTourPopup({ ...tourPopup, show: true, values: multipleDates });
        else setCalendarPopup({ show: true, values: calendarDates });
    };

    const closePopup = () => {
        setTourPopup({ type: null, show: false, values: null });
        setCalendarPopup({ values: null, show: false });
        let tempTourPrices = tourPrices;

        for (const item of tempTourPrices) {
            for (const mt of item.monthlyData) {
                for (const innerMt of mt.data) {
                    if (typeof innerMt.isCalendarDateSelected === 'boolean') {
                        innerMt.isCalendarDateSelected = false;
                    }

                    innerMt.isPriceSelected = false;
                    innerMt.isMaximumPersonSelected = false;
                    innerMt.isPickupDropSelected = false;
                }
            }
        }

        dispatch({ type: VENDOR_ACTIONS.SET_TOUR_PRICE, pricing: tempTourPrices });
        setMultipleDates([]);
        setCalendarDates([]);
    };

    useEffect(() => {
        fetchTours();
    }, []);

    useEffect(() => {
        if (toursList && toursList.length) {
            calendarFormik.setValues({
                startDate: momentTz().tz(toursList[0].timezone || 'Asia/Kolkata').toDate(),
                endDate: momentTz().tz(toursList[0].timezone || 'Asia/Kolkata').add(1, 'month').toDate(),
            });

            setSelectedTour(toursList[0]);
        }
    }, [toursList]);


    useEffect(() => {
        if (Object.keys(selectedTour).length) {
            const resetDates = false;
            fetchCalendar(resetDates);
        }
    }, [selectedTour]);

    useEffect(() => {
        if (showTour) {
            window.addEventListener('click', handleOutsideClick);
        }

        return () => {
            window.removeEventListener('click', handleOutsideClick);
        };
    }, [showTour]);

    if (priceLoader) {
        return (
            <div className={styles.loadingCalendar}>
                <div className='d-flex'>
                    <p className='mb-0'>Loading Calendar...</p>
                </div>
                <img alt="calendar-loader" src={CalendarLoader} />
            </div>
        );
    }

    return (
        <div className='calander-outer'>
            <div className='d-flex justify-content-between'>
                <h5>Tour Calendar</h5>
                {multipleDates.length || calendarDates.length ? <Button title="Bulk Update" handleClick={() => bulkUpdateCalendar()} /> : null}
            </div>
            <div className='ficed-top-nbar'>
                <div className='d-inline-flex align-items-center'>
                    <div className='d-flex flex-column form-group align-self-end'>
                        {calendarFormik.touched.startDate && calendarFormik.errors.startDate ? <p className='error mb-0'>{calendarFormik.errors.startDate}</p> : null}
                        <DatePicker
                            selected={calendarFormik.values.startDate}
                            onChange={(date) => calendarFormik.setFieldValue('startDate', date)}
                            dateFormat="dd MMM yyyy"
                            minDate={new Date()}
                        />
                    </div>
                    <div className='form-group mx-2 align-self-end'>
                        {calendarFormik.touched.endDate && calendarFormik.errors.endDate ? <p className='error mb-0'>{calendarFormik.errors.endDate}</p> : null}
                        <DatePicker
                            selected={calendarFormik.values.endDate}
                            onChange={(date) => calendarFormik.setFieldValue('endDate', date)}
                            dateFormat="dd MMM yyyy"
                            minDate={new Date()}
                            className='d-block'
                        />
                    </div>
                    <span className='sech-btn align-self-end' onClick={calendarFormik.submitForm}> <i className='fas fa-search align-self-center cursor-pointer' /></span>
                </div>
                <div className='cat-prop'>
                    <button onClick={openTours}><img src={PhotoIcon} alt="" />{selectedTour.name && selectedTour.name.length > 20 ? `${selectedTour.name.slice(0, 20)}...` : selectedTour.name}</button>
                    {showTour &&
                        <div className='outer-list-pro'>
                            <div className='list-pro'>
                                <ul ref={tourPopupRef}>
                                    {toursList.map((item, key) => (
                                        <li key={key}>
                                            <div>
                                                <img src={item.images ? item.images : PhotoIcon} alt="" />
                                                <div className='text-prop'>
                                                    <h6>{item.name}</h6>
                                                    <p>{item.location}</p>
                                                </div>
                                            </div>
                                            <label className="rad">
                                                <input
                                                    type="radio"
                                                    value={item._id}
                                                    checked={selectedTour._id === item._id}
                                                    name="radio"
                                                    onChange={() => {
                                                        setSelectedTour(item);
                                                        openTours();
                                                    }}
                                                />
                                                <span className="checkmark"></span>
                                            </label>
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        </div>
                    }
                </div>
            </div>
            <div className='outre-calnder-grid-outer' ref={calendarDateScrollbarRef} onScroll={handleCalendarDateScroll}>
                {new Array(3).fill({}).map((_, index) => (
                    <div className='outre-calnder-grid' key={index}>
                        <div className='day-cal empty-day-cal'>
                            <p></p>
                        </div>
                        <div className='dates-inner'>
                            <div className='av-cal-list-grid__cell av-cal-list-cell av-cal-list-grid__cell--borderless-top border-right-0'>
                                <span className='av-cal-list-date av-cal-list-date--weekend'>
                                    <span className="av-cal-list-date__day-of-week">
                                        <p></p>
                                    </span>
                                    <p className="av-cal-list-date__day-number"></p>
                                </span>
                            </div>
                        </div>
                    </div>
                ))}

                {tourPrices.length && tourPrices[0].monthlyData.map((item, index) => (
                    <div className='outre-calnder-grid' key={index}>
                        <div className='day-cal'>
                            <span>{moment().month(item.month).format('MMMM')} {item.year}</span>
                        </div>
                        <div className='dates-inner'>
                            {item.data.map((monthlyItem, index) => (
                                <div
                                    key={index}
                                    onClick={(event) => handleCalendarClick(event, monthlyItem)}
                                    className={`cursor-pointer ${monthlyItem.isCalendarDateSelected ? styles.selectedBox : ''} av-cal-list-grid__cell av-cal-list-cell ${monthlyItem.day === 6 ? 'av-cal-list-grid__cell--separator' : 'av-cal-list-grid__cell--borderless-top'}`}
                                >
                                    <span className={`${monthlyItem.day === 6 || monthlyItem.day === 0 ? 'av-cal-list-date av-cal-list-date--weekend' : 'av-cal-list-date'}`}>
                                        <span className="av-cal-list-date__day-of-week">
                                            <span>{moment(monthlyItem.date).format('dddd').slice(0, 3)}</span>
                                        </span>
                                        <span className="av-cal-list-date__day-number">
                                            {moment(monthlyItem.date).format('DD')}
                                        </span>
                                    </span>
                                </div>
                            ))}
                        </div>
                    </div>
                ))}
            </div>

            {tourPrices.map((item, key) => (
                <div className='lower-calander-data' key={key}>
                    <h4>{item?._id.name}</h4>
                    <div className='rooms-outer' ref={el => tourPriceScrollbarRef.current[key] = el} onScroll={handleCalendarDateScroll}>
                        {/* <div className='room-sell romstarus'>
                            <label className='mg-n'>Tour status</label>
                            <table>
                                <tbody>
                                    <tr className='available-bookings'>
                                        {propertyPrices.map(item => (
                                            item.monthlyData.map((mt, index) => (
                                                <th className='room-rount-header' key={index}>A</th>
                                            ))
                                        ))}
                                    </tr>

                                    <tr>
                                        {availableBookings.map((item, index) => (
                                            <td className='room-rount' colSpan={item.open || item.closed || item.soldOut} key={index}>

                                                <p className={item.open ? styles.available : styles.booked}>{getAvailableBookingChip(item).text}</p>
                                            </td>
                                        ))}
                                    </tr>
                                </tbody>
                            </table>
                        </div> */}
                        <div className='room-sell booked-rate'>
                            <label>Price</label>
                            <div className='rooms-count'>
                                {item.monthlyData.map((mt, index) => (
                                    mt.data.map((data, innerIndex) => (
                                        <div
                                            key={innerIndex}
                                            className={`text-right cursor-pointer room-rount ${data.isPriceSelected ? styles.selectedBox : null}`}
                                            onClick={(event) => handleCalendarChange(event, 'price', { ...data, subCategoryId: item._id.subCategoryId })}
                                        >
                                            {data.startDate}
                                            <span>{data.currencySymbol}</span>
                                            {data.price}
                                        </div>
                                    ))
                                ))}
                            </div>
                        </div>
                        <div className='room-sell booked-rate'>
                            <label>Maximum Person allowed</label>
                            <div className='rooms-count'>
                                {item.monthlyData.map((mt, index) => (
                                    mt.data.map((data, innerIndex) => (
                                        <div
                                            key={innerIndex}
                                            className={`text-right cursor-pointer room-rount ${data.isMaximumPersonSelected ? styles.selectedBox : null}`}
                                            onClick={(event) => handleCalendarChange(event, 'maximumPerson', { ...data, subCategoryId: item._id.subCategoryId })}
                                        >
                                            {data.maximumPerson}
                                        </div>
                                    ))
                                ))}
                            </div>
                        </div>
                        <div className='room-sell booked-rate'>
                            <label>Pick up and Drop</label>
                            <div className='rooms-count'>
                                {item.monthlyData.map((mt, index) => (
                                    mt.data.map((data, innerIndex) => (
                                        <div
                                            key={innerIndex}
                                            className={`text-right cursor-pointer room-rount ${data.isPickupDropSelected ? styles.selectedBox : null}`}
                                            onClick={(event) => handleCalendarChange(event, 'pickUpDrop', { ...data, subCategoryId: item._id.subCategoryId })}
                                        >
                                            {data.pickupAndDrop ? 'Yes' : 'No'}
                                        </div>
                                    ))
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
            ))}
            <PricePopup {...tourPopup} closeModal={closePopup} updateTourCalendar={updateTourCalendar} multipleDates={multipleDates} />
            <CalendarPopup {...calendarPopup} closeModal={closePopup} calendarDates={calendarDates} updateTourCalendar={updateTourCalendar} />
        </div>
    );
};

const mapDispatchToProps = (dispatch) => ({
    getTours: (query, loader) => dispatch(CommonAction.toursList(query, loader)),
    getTourPrices: (params, loader, resetDates) => dispatch(VendorTourAction.pricingList(params, loader, resetDates)),
    updateTourCalendar: (payload, loader, query) => dispatch(VendorTourAction.updateTourCalendar(payload, loader, query)),
});

export default connect(null, mapDispatchToProps)(TourCalendar);