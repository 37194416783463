import Axios from "../../../components/Universal/Axios";
import { Toaster } from "../../../components/Toaster";
import { APP_ROUTES, MESSAGES } from "../../../constants";
import { TOAST_TYPE } from "../../../constants";
import ADMIN_ACTIONS from "../actions";

const list = (params, loader) => async (dispatch) => {
    try {
        const { data } = await Axios.get("/voucher", params, loader);
        dispatch({ type: ADMIN_ACTIONS.VOUCHERS_LIST_SUCCESS, vouchers: data.data, count: data.count });
    }
    catch (err) { }
};

const claimedUsers = (params, loader) => async (dispatch) => {
    try {
        const { data } = await Axios.get("/voucher/claimedUsers", params, loader);
        dispatch({ type: ADMIN_ACTIONS.CLAIMED_VOUCHER_USERS_LIST, users: data.users, count: data.count });
    }
    catch (err) { }
};

const create = (payload, loader, navigate) => async (_dispatch) => {
    try {
        const response = await Axios.post("/voucher", payload, loader);

        if (response.status) {
            Toaster(TOAST_TYPE.SUCCESS, MESSAGES.VOUCHER.CREATE.SUCCESS);
            navigate(APP_ROUTES.VOUCHERS);
        }
    }
    catch ({ error }) {
        if (error) {
            Toaster(TOAST_TYPE.ERROR, error);
        }
    }
}

const update = (payload, loader, navigate) => async (_dispatch) => {
    try {
        const response = await Axios.put("/voucher", payload, loader);

        if (response.status) {
            Toaster(TOAST_TYPE.SUCCESS, MESSAGES.VOUCHER.EDIT.SUCCESS);
            navigate(APP_ROUTES.VOUCHERS);
        }
    }
    catch ({ error }) {
        if (error) {
            Toaster(TOAST_TYPE.ERROR, error);
        }
    }
}

const remove = (voucherId, loader) => async (dispatch) => {
    try {
        const params = { voucherId };
        const response = await Axios.delete('/voucher', { params: params }, loader);

        if (response.status) {
            Toaster(TOAST_TYPE.SUCCESS, MESSAGES.VOUCHER.DELETE.SUCCESS);
            dispatch(list(loader));
        }
    }
    catch (err) { }
};

const specific = (voucherId, loader) => async (dispatch) => {
    try {
        const params = { voucherId };

        const response = await Axios.get('/voucher/voucherId', params, loader);

        dispatch({ type: ADMIN_ACTIONS.SET_SPECIFIC_VOUCHER, voucher: response.data })
    }
    catch (err) { }
};

export {
    list,
    create,
    remove,
    specific,
    update,
    claimedUsers
};