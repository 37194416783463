import * as Yup from 'yup';

import { PORTFOLIO_DEALS_TYPE } from '../constants/promotions';
import { MESSAGES } from '../constants';

const vendorPromotionSchema = Yup.object().shape({
    promotionName: Yup.string().required("Required"),
    discount: Yup
        .number().required("Required")
        .test('test-discount', function (value) {

            if (this.parent.dealName === PORTFOLIO_DEALS_TYPE.LAST_MINUTE && (value < 1 || value > 10)) {
                return this.createError({ message: MESSAGES.VALIDATION_ERRORS.DISCOUNT_BETWEEN_1_10, path: this.path });
            }
            else if (this.parent.type === 1 && (value < 1 || value > 20)) {
                return this.createError({ message: MESSAGES.VALIDATION_ERRORS.DISCOUNT_BETWEEN_1_20, path: this.path });
            }
            return { path: this.path };
        }),
    stayDates: Yup.array().min(1, 'Required').required('Required'),
    dealName: Yup.string().optional(),
    type: Yup.number().optional(),
});

export default vendorPromotionSchema;