export const API_URL = {
    BASE_URL: process.env.REACT_APP_BASE_URL,
    SOCKET_URL: process.env.REACT_APP_SOCKET_URL,
    PROPERTY_DETAIL: "property/details",
    VENDOR_PROPERTY_DETAIL: "property/details/vendor",
    EDIT_PROPERTY: "property",
    CAR_DETAIL: "car/details",
    EDIT_CAR: "vendor/update/car",
    TOUR_API: "tour",
    CURRENCY_URL: "https://ipapi.co/currency",
    CALENDAR_URL: 'property/calendar/pricing',
    PROPERTY_LIST: 'property'
};
