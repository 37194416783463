/* eslint-disable react-hooks/exhaustive-deps */
import React, { Fragment, useEffect, useState } from 'react';
import { connect, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { Link, useNavigate } from 'react-router-dom';
import moment from 'moment';
import { debounce } from 'lodash';

import { AdminInvoiceAction } from '../../../store/admin/thunk';
import { APP_ROUTES, CATEGORY_TYPE, MESSAGES, PAYMENT_TYPE } from '../../../constants';
import { Pagination } from '../../../components';
import styles from './style.module.css';
import { SearchIcon } from '../../../assets/images';

const SpecificVendorPayout = ({ specificInvoiceDetails, loader }) => {
    const params = useParams();
    const navigate = useNavigate()

    const { user, bookingInvoices, bookingInvoicesCount, requestBookingInvoiceLoader } = useSelector((state) => state.common);
    const [amount, setAmount] = useState({ totalVendorAmount: 0, rentVIPAmount: 0, overallAmount: 0 });

    const [pagination, setPagination] = useState({ offset: 0, limit: 10 });
    const [payoutType, setPayoutType] = useState(CATEGORY_TYPE.PROPERTY);
    const [bookingNoSearch, setBookingNoSearch] = useState('');
    const [propertyNameSearch, setPropertyNameSearch] = useState('');

    const handlePayoutTypeChange = (type) => {
        setPayoutType(type);
    };

    const getPayouts = (offset = pagination.offset) => {
        const query = {
            invoiceId: params.id,
            type: payoutType,
            offset: offset,
            limit: pagination.limit,
        };

        if (bookingNoSearch && bookingNoSearch.length) query.bookingNumber = bookingNoSearch;
        else if (propertyNameSearch && propertyNameSearch.length) query.searchString = propertyNameSearch;

        specificInvoiceDetails(query, user.userType, loader);
    };


    const clearResult = () => {
        setBookingNoSearch('');
        setPropertyNameSearch('');
        getPayouts(0);
    };

    useEffect(() => {
        if (params.id) {
            getPayouts();
        }
    }, [pagination.offset, payoutType]);

    const debouncePayoutsList = debounce(() => getPayouts(0), 500);

    useEffect(() => {
        let totalVendorAmount = 0;
        let rentVIPAmount = 0;
        let overallAmount = 0;


        for (const item of bookingInvoices) {
            totalVendorAmount += item.amountPerDay;
            rentVIPAmount += item.serviceCharge;
            overallAmount += item.settlementAmount;
        }

        setAmount({ totalVendorAmount, rentVIPAmount, overallAmount });

    }, [bookingInvoices]);

    return (
        <div className="vendor-listing">
            <div className="d-flex justify-content-between align-items-center">
                <ul className="paginaion">
                    <li>
                        <Link to="/">Dashboard</Link>
                    </li>
                    <li className="active">
                        <Link to={APP_ROUTES.VIEW_INVOICES}>Invoices</Link>
                    </li>
                </ul>
            </div>

            <div className='filter-main'>
                <div className='filter-left'>

                    <div className="form-group">
                        <span className="search-c">
                            <img src={SearchIcon} alt="search" />
                        </span>
                        <input
                            type='bookingNoSearch'
                            placeholder='Search by Booking no'
                            onChange={(e) => setBookingNoSearch(e.target.value)}
                            onKeyDown={(e) => {
                                if (e.key === 'Enter') debouncePayoutsList();
                            }}
                            value={bookingNoSearch}
                            className='me-2'
                        />
                    </div>

                    <div className='form-group'>
                        <span className="search-c">
                            <img src={SearchIcon} alt="search" />
                        </span>

                        <input
                            type='text'
                            name="propertyNameSearch"
                            placeholder={`Search by ${payoutType === CATEGORY_TYPE.PROPERTY ? 'Property' : payoutType === CATEGORY_TYPE.TOUR ? 'Tour' : 'Car'} name`}
                            onChange={(e) => setPropertyNameSearch(e.target.value)}
                            onKeyDown={(e) => {
                                if (e.key === 'Enter') debouncePayoutsList();
                            }}
                            value={propertyNameSearch}
                            className='me-2'
                        />

                        {(propertyNameSearch.length || bookingNoSearch.length) ?
                            <button className="search-button" onClick={clearResult}>
                                <i className="fa fa-eraser" />
                            </button>
                            :
                            null
                        }
                    </div>
                </div>
            </div>

            <div className={styles.tabPanel}>
                <div
                    className={`${styles.tab} me-2 ${payoutType === CATEGORY_TYPE.PROPERTY ? styles.activeTab : ''}`}
                    onClick={() => handlePayoutTypeChange(CATEGORY_TYPE.PROPERTY)}
                >
                    Property
                </div>
                <div
                    className={`${styles.tab} me-2 ${payoutType === CATEGORY_TYPE.CAR ? styles.activeTab : ''}`}
                    onClick={() => handlePayoutTypeChange(CATEGORY_TYPE.CAR)}
                >
                    Car
                </div>
                <div
                    className={`${styles.tab} ${payoutType === CATEGORY_TYPE.TOUR ? styles.activeTab : ''}`}
                    onClick={() => handlePayoutTypeChange(CATEGORY_TYPE.TOUR)}
                >
                    Tour
                </div>
            </div>

            <div className="listing-table">
                <div className="listing-table-scrl">
                    <table cellPadding={0} cellSpacing={0}>
                        <thead>
                            <tr>
                                <th></th>
                                <th>Booking No.</th>
                                <th>{payoutType === CATEGORY_TYPE.PROPERTY ? 'Property' : payoutType === CATEGORY_TYPE.TOUR ? 'Tour' : 'Car'} Name</th>
                                <th>{payoutType === CATEGORY_TYPE.PROPERTY ? 'Check In' : payoutType === CATEGORY_TYPE.CAR ? 'Pick up Date' : 'Date'}</th>
                                <th className={payoutType === CATEGORY_TYPE.TOUR && 'd-none'}>{payoutType === CATEGORY_TYPE.PROPERTY ? 'Check Out' : payoutType === CATEGORY_TYPE.CAR ? 'Drop Date' : 'Car'}</th>
                                <th>Payment Mode</th>
                                <th>Vendor Amount</th>
                                <th>Rent VIP Charge</th>
                                <th>Overall Amount</th>
                            </tr>
                        </thead>
                        <tbody>
                            {requestBookingInvoiceLoader ? <tr className="noresult"><td colSpan={9}>{MESSAGES.LOADING}</td></tr> : null}
                            {!requestBookingInvoiceLoader && bookingInvoices.length === 0 ? <tr className="noresult"><td colSpan={9}>{MESSAGES.PAYOUTS.NO_PAYOUTS_FOUND}</td></tr> : null}
                            {!requestBookingInvoiceLoader && bookingInvoices.map((item, index) => (
                                <tr key={item._id}>
                                    <td>{++index}</td>
                                    <td onClick={() => navigate(`/propertyBookings/${item.bookingData._id}`)} className={styles.bookingNo}>{item.bookingData.bookingNumber}</td>
                                    <td>{item.bookingData.bookingCategoryName} </td>
                                    {payoutType === CATEGORY_TYPE.PROPERTY ?
                                        <Fragment>
                                            <td>{moment(item.bookingData.checkIn).format('YYYY-MM-DD')}</td>
                                            <td>{moment(item.bookingData.checkOut).format('YYYY-MM-DD')}</td>
                                        </Fragment> : payoutType === CATEGORY_TYPE.CAR ?
                                            <Fragment>
                                                <td>{moment(item.bookingData.checkIn).format('YYYY-MM-DD')}</td>
                                                <td>{moment(item.bookingData.checkOut).format('YYYY-MM-DD')}</td>
                                            </Fragment> : <td>{moment(item.bookingData.date).format('YYYY-MM-DD')}</td>}
                                    <td>{PAYMENT_TYPE[item.bookingData.modeOfPayment]}</td>
                                    <td>{item.settlementCurrency} {item.amountPerDay}</td>
                                    <td>{item.settlementCurrency} {item.serviceCharge}</td>
                                    <td>{item.settlementCurrency} {item.settlementAmount}</td>
                                </tr>
                            ))}
                            {
                                !requestBookingInvoiceLoader && bookingInvoices.length !== 0 &&
                                <tr className={styles.totalAmountTab}>
                                    <td></td>
                                    <td className={styles.totalAmount}>Total</td>
                                    <td colSpan={4}></td>
                                    <td className={styles.totalAmount}>AED {amount.totalVendorAmount}</td>
                                    <td className={styles.totalAmount}>AED {amount.rentVIPAmount}</td>
                                    <td className={styles.totalAmount}>AED {amount.overallAmount}</td>
                                </tr>
                            }
                        </tbody>
                    </table>
                </div>

                <Pagination count={bookingInvoicesCount} setPagination={setPagination} />
            </div>
        </div>
    );
};

const mapDispatchToProps = (dispatch) => ({
    specificInvoiceDetails: (query, userType, loader) => dispatch(AdminInvoiceAction.specificInvoiceDetails(query, userType, loader)),
});

export default connect(null, mapDispatchToProps)(SpecificVendorPayout);