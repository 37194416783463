const COMMON_ACTIONS = {
    USER_DETAILS_SUCCESS: 'USER_DETAILS_SUCCESS',
    LOADING_USER_DETAILS :'LOADING_USER_DETAILS',
    PROPERTIES_LIST_SUCCESS: 'PROPERTIES_LIST_SUCCESS',
    CAR_LIST_SUCCESS: 'CAR_LIST_SUCCESS',
    CATEGORY_LIST_SUCCESS: 'CATEGORY_LIST_SUCCESS',
    SUB_CATEGORY_LIST_SUCCESS: 'SUB_CATEGORY_LIST_SUCCESS',
    SET_IMAGES: 'SET_IMAGES',
    SET_SPECIFIC_CAR: 'SET_SPECIFIC_CAR',
    REQUEST_SPECIFIC_PROPERTY: 'REQUEST_SPECIFIC_PROPERTY',
    REQUEST_SPECIFIC_CAR: 'REQUEST_SPECIFIC_CAR',
    SET_SPECIFIC_PROPERTY: 'SET_SPECIFIC_PROPERTY',
    TOURS_LIST_SUCCESS: 'TOURS_LIST_SUCCESS',
    SET_CURRENCY: 'SET_CURRENCY',
    SET_SPECIFIC_TOUR: 'SET_SPECIFIC_TOUR',
    REQUEST_PROPERTY_LIST: 'REQUEST_PROPERTY_LIST',
    REQUEST_CAR_LIST: 'REQUEST_CAR_LIST',
    SET_USER_SOCKET: 'SET_USER_SOCKET',
    SET_USER_ROOMS: 'SET_USER_ROOMS',
    SET_SPECIFIC_ROOM_MESSAGES: 'SET_SPECIFIC_ROOM_MESSAGES',
    SET_NEXT_ROOM_MESSAGES: 'SET_NEXT_ROOM_MESSAGES',
    SET_MESSAGES_PAGINATION: 'SET_MESSAGES_PAGINATION',
    SET_USER_LOCATION: 'SET_USER_LOCATION',
    RESET_MESSAGES_PAGINATION: 'RESET_MESSAGES_PAGINATION',
    SET_SPECIFIC_ROOM: 'SET_SPECIFIC_ROOM',
    SET_RESERVATION_MESSAGES_PAGINATION: 'SET_RESERVATION_MESSAGES_PAGINATION',
    SET_LAST_MESSAGE: 'SET_LAST_MESSAGE',
    SET_NOTIFICATIONS: 'SET_NOTIFICATIONS',
    SET_NOTIFICATION_PAGINATION: 'SET_NOTIFICATION_PAGINATION',
    SET_BED_CATEGORIES: 'SET_BED_CATEGORIES',
    SET_PROPERTY_AMENITIES: 'SET_PROPERTY_AMENITIES',
    REQUEST_TOUR_LIST: 'REQUEST_TOUR_LIST',
    REQUEST_INVOICE_LIST: 'REQUEST_INVOICE_LIST',
    SET_INVOICE_LIST: 'SET_INVOICE_LIST',
    REQUEST_SPECIFIC_INVOICE: 'REQUEST_SPECIFIC_INVOICE',
    SET_SPECIFIC_INVOICE_DETAILS: 'SET_SPECIFIC_INVOICE_DETAILS'
};

export default COMMON_ACTIONS;