import { useEffect, useState } from "react";
import Select from "react-select";

const MultiSelect = ({
    defaultOption,
    field,
    form,
    options,
    isMulti = false,
    placeholder = "Select",
}) => {

    const [selectedOptions, setSelectedOptions] = useState([]);

    const onChange = (option) => {
        setSelectedOptions(option);

        form.setFieldValue(field.name, option ? option : []);
    };

    useEffect(() => {
        setSelectedOptions(defaultOption);
    }, [defaultOption]);

    useEffect(() => {
        if (options && options.length === 0) {
            setSelectedOptions([]);
        }
    }, [options]);

    if (!isMulti) {
        return (
            <Select
                options={options}
                name={field.name}
                value={
                    options ? options.find((option) => option.value === field.value) : ""
                }
                onChange={(option) => {
                    form.setFieldValue(field.name, option.value)

                }}
                onBlur={field.onBlur}
                placeholder={placeholder}
            />
        );
    } else {
        return (
            <Select
                className="react-select-container"
                classNamePrefix="react-select"
                name={field.name}
                value={selectedOptions}
                onChange={onChange}
                options={options}
                isMulti={isMulti}
                placeholder={placeholder}
            />
        );
    }
};

export default MultiSelect;
