import * as Yup from "yup";

const SignupSchema = Yup.object().shape({
	email: Yup.string().email().required("Required"),
	password: Yup.string()
		.min(5, "Too Short!")
		.max(50, "Too Long!")
		.required("Required"),
});

export default SignupSchema;