
import Axios from "../../../components/Universal/Axios";
import COMMON_ACTIONS from "../actions";

const getUserDetails = (navigate, loader) => async (dispatch) => {
    try {
        dispatch({ type: COMMON_ACTIONS.LOADING_USER_DETAILS, loading: true });
        const response = await Axios.get("/common/checkUserType", {}, loader);

        if (!response) {
            localStorage.clear();
            navigate('/login');

        }
        else {
            dispatch({ type: COMMON_ACTIONS.USER_DETAILS_SUCCESS, user: response.data, loading: false });
            dispatch({ type: COMMON_ACTIONS.LOADING_USER_DETAILS, loading: false });
        };


    }
    catch (err) {
        dispatch({ type: COMMON_ACTIONS.LOADING_USER_DETAILS, loading: false });
    }
};

const propertyList = (query, loader) => async (dispatch) => {
    try {
        dispatch({ type: COMMON_ACTIONS.REQUEST_PROPERTY_LIST, status: true });
        const { data } = await Axios.get("/property", query, loader);

        dispatch({ type: COMMON_ACTIONS.PROPERTIES_LIST_SUCCESS, properties: data.properties, count: data.count });
        dispatch({ type: COMMON_ACTIONS.REQUEST_PROPERTY_LIST, status: false });
    }
    catch (err) { }
};

const carsList = (query, loader) => async (dispatch) => {
    try {
        dispatch({ type: COMMON_ACTIONS.REQUEST_CAR_LIST, status: true });
        const response = await Axios.get("/car", query, loader);

        dispatch({ type: COMMON_ACTIONS.CAR_LIST_SUCCESS, cars: response.data.cars, count: response.data.count });
        dispatch({ type: COMMON_ACTIONS.REQUEST_CAR_LIST, status: false });
    }
    catch (err) { }
};

const categoriesList = (query, loader) => async (dispatch) => {
    try {
        const response = await Axios.get("/category", query, loader);

        dispatch({ type: COMMON_ACTIONS.CATEGORY_LIST_SUCCESS, categories: response.data.categories, count: response.data.count });
    }
    catch (err) { }
};

const subCategoriesList = (categoryId, loader) => async (dispatch) => {
    try {
        const response = await Axios.get(`/category/${categoryId}`, {}, loader);

        dispatch({ type: COMMON_ACTIONS.SUB_CATEGORY_LIST_SUCCESS, subCategories: response.data.sub_categories });
    }
    catch (err) { }
};

const specificCar = (query, loader) => async (dispatch) => {
    try {
        dispatch({ type: COMMON_ACTIONS.REQUEST_SPECIFIC_CAR, status: true });
        const response = await Axios.get(`/car/${query.id}`, {}, loader);

        dispatch({ type: COMMON_ACTIONS.SET_IMAGES, key: 'selectedImage', value: response.data.images });
        dispatch({ type: COMMON_ACTIONS.SET_IMAGES, key: 'images', value: response.data.images });

        dispatch({ type: COMMON_ACTIONS.SET_SPECIFIC_CAR, car: response.data });
        dispatch({ type: COMMON_ACTIONS.REQUEST_SPECIFIC_CAR, status: false });
    }
    catch (err) {
        dispatch({ type: COMMON_ACTIONS.REQUEST_SPECIFIC_CAR, status: false });
    }
};

const specificProperty = (query, loader) => async (dispatch) => {
    try {
        dispatch({ type: COMMON_ACTIONS.REQUEST_SPECIFIC_PROPERTY, status: true });
        const response = await Axios.get("/property/details/vendor", query, loader);

        dispatch({ type: COMMON_ACTIONS.SET_SPECIFIC_PROPERTY, property: response.data });
        dispatch({ type: COMMON_ACTIONS.REQUEST_SPECIFIC_PROPERTY, status: false });
    }
    catch (err) {
        dispatch({ type: COMMON_ACTIONS.REQUEST_SPECIFIC_PROPERTY, status: false });
        dispatch({ type: COMMON_ACTIONS.SET_SPECIFIC_PROPERTY, property: {} });
    }
};

const toursList = (query, loader) => async (dispatch) => {
    try {
        dispatch({ type: COMMON_ACTIONS.REQUEST_TOUR_LIST, status: true });
        const response = await Axios.get("/tour", query, loader);

        dispatch({ type: COMMON_ACTIONS.TOURS_LIST_SUCCESS, tours: response.data.tour, count: response.data.count });
        dispatch({ type: COMMON_ACTIONS.REQUEST_TOUR_LIST, status: false });
    }
    catch (err) {
        dispatch({ type: COMMON_ACTIONS.REQUEST_TOUR_LIST, status: false });
    }
};


const specificTour = (slug, currency, loader) => async (dispatch) => {
    try {
        const response = await Axios.get(`/tour/${slug}/${currency}`, {}, loader);

        dispatch({ type: COMMON_ACTIONS.SET_SPECIFIC_TOUR, tour: response.data });
    }
    catch (err) { }
};

const notificationsList = (params, loader) => async (dispatch) => {
    try {
        const response = await Axios.get(`/notification/listing`, params, loader);

        dispatch({
            type: COMMON_ACTIONS.SET_NOTIFICATIONS,
            notifications: response.data.notifications,
            notificationCount: response.data.count,
            unreadNotificationsCount: response.data.notificationCount,
        });
    }
    catch (err) { }
};


export {
    getUserDetails,
    propertyList,
    carsList,
    categoriesList,
    subCategoriesList,
    specificCar,
    specificProperty,
    toursList,
    specificTour,
    notificationsList,
};