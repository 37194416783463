const ADMIN_ACTIONS = {
    ADMIN_DASHBOARD_SUCCESS: 'ADMIN_DASHBOARD_SUCCESS',
    REQUEST_SUB_ADMIN_LIST: 'REQUEST_SUB_ADMIN_LIST',
    SUB_ADMIN_LIST_SUCCESS: 'SUB_ADMIN_LIST_SUCCESS',
    CREATE_SUB_ADMIN_SUCCESS: 'CREATE_SUB_ADMIN_SUCCESS',
    REQUEST_USER_LIST: 'REQUEST_USER_LIST',
    USER_LIST_SUCCESS: 'USER_LIST_SUCCESS',
    REQUEST_VENDOR_LIST: 'REQUEST_VENDOR_LIST',
    VENDOR_LIST_SUCCESS: 'VENDOR_LIST_SUCCESS',
    SPECIFIC_USER_SUCCESS: 'SPECIFIC_USER_SUCCESS',
    CREATE_VENDOR_SUCCESS: 'CREATE_VENDOR_SUCCESS',
    SET_PROMOTIONS: 'SET_PROMOTIONS',
    REQUEST_PROMOTIONS_LIST: 'REQUEST_PROMOTIONS_LIST',
    CREATE_PROMOTIONS_SUCCESS: 'CREATE_PROMOTIONS_SUCCESS',
    SET_SPECIFIC_PROMOTION: 'SET_SPECIFIC_PROMOTION',
    CITIES_LIST_SUCCESS: 'CITIES_LIST_SUCCESS',
    AREAS_LIST_SUCCESS: 'AREAS_LIST_SUCCESS',
    BUILDING_LIST_SUCCESS: 'BUILDING_LIST_SUCCESS',
    VOUCHERS_LIST_SUCCESS: 'VOUCHERS_LIST_SUCCESS',
    CREATE_UPDATE_VOUCHER_SUCCESS: 'CREATE_UPDATE_VOUCHER_SUCCESS',
    SET_SPECIFIC_VOUCHER: 'SET_SPECIFIC_VOUCHER',
    CLAIMED_VOUCHER_USERS_LIST: 'CLAIMED_VOUCHER_USERS_LIST',
    SPECIFIC_VENDOR_SUCCESS: 'SPECIFIC_VENDOR_SUCCESS',
    SPECIFIC_LOCATION_SUCCESS: 'SPECIFIC_LOCATION_SUCCESS',
    SPECIFIC_CITY_SUCCESS: 'SPECIFIC_CITY_SUCCESS',
    SPECIFIC_AREA_SUCCESS: 'SPECIFIC_AREA_SUCCESS',
    SPECIFIC_BUILDING_SUCCESS: 'SPECIFIC_BUILDING_SUCCESS',
    CATEGORIES_LIST_SUCCESS: 'SPECIFIC_CATEGORIES_LIST_SUCESS',
};

export default ADMIN_ACTIONS;