/* eslint-disable react-hooks/exhaustive-deps */
import { useLocation, useParams } from 'react-router-dom';
import { useEffect, useState, useRef } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { useFormik } from 'formik';

import { VendorBookingRequestAction } from '../../../../store/vendor/thunk';
import { CATEGORY_TYPE, INVOICE_STATUS, MESSAGES, PAYMENT_TYPE, SOCKET_EVENTS, USER_TYPE } from '../../../../constants';
import { SocketAction } from '../../../../store/common/thunk';
import COMMON_ACTIONS from '../../../../store/common/actions';
import { AppIcon } from '../../../../assets/images';
import styles from './style.module.css';

const SpecificPropertyBooking = ({ getSpecificBooking, loader, acceptPayment, category }) => {
    const params = useParams();
    const location = useLocation();
    const dispatch = useDispatch();

    const { bookingRequests: { specificBooking, specificBookingLoader } } = useSelector((state) => state.vendor);
    const { socket, user, reservationMessagesPagination } = useSelector((state) => state.common);

    const [room, setRoom] = useState(null);
    const [disableChat, setDisableChat] = useState(false);
    const [roomMessages, setRoomMessages] = useState([]);

    const messageBoxRef = useRef(null);
    const rootMessageBoxRef = useRef(null);

    const newChatFormik = useFormik({
        initialValues: { message: '' },
        onSubmit: ({ message }, { resetForm }) => {
            if (message && message.length) {
                const chat = {
                    roomId: room.roomId,
                    message: message,
                    messageType: 1,
                };

                socket.emit(SOCKET_EVENTS.SEND_MESSAGE, chat);
                resetForm();
            }
        },
    });

    const getMessages = async () => {
        const params = {
            roomId: specificBooking._id,
            limit: reservationMessagesPagination.limit,
            offset: reservationMessagesPagination.offset,
        };

        const response = await SocketAction.getSpecificRoomMessages(params);

        let finalData = [...roomMessages, ...response.data];

        if (response.data.length && roomMessages.length) {
            if (response.data[0].roomId !== roomMessages[0].roomId) {
                finalData = response.data;
            }
        }

        const isDisable = typeof (response.disableChat) === 'boolean' ? response.disableChat : false;

        setRoomMessages(finalData);
        setDisableChat(isDisable);
        socket.emit(SOCKET_EVENTS.READ_MESSAGE, params);
    };

    const acceptBookingPayment = () => {
        const params = {
            bookingId: specificBooking._id,
            type: category,
        };

        acceptPayment(params, loader);
    };

    useEffect(() => {
        if (specificBooking && specificBooking._id) {
            getMessages();
        }
    }, [specificBooking, reservationMessagesPagination])

    useEffect(() => {
        let type = CATEGORY_TYPE.PROPERTY;

        if (location.pathname.startsWith('/carBookings')) type = CATEGORY_TYPE.CAR;

        const payload = {
            type: type,
            bookingId: params.bookingId,
        };

        getSpecificBooking(payload, loader);
    }, []);

    useEffect(() => {
        socket.on(SOCKET_EVENTS.NEW_ROOM, (data) => {
            socket.emit(SOCKET_EVENTS.JOIN_ROOM, { roomId: data.roomId });

            setRoom(data);
        });

        socket.on(SOCKET_EVENTS.ROOM_ALREADY_CREATED, async (response) => {
            if (response && response.message) {
                socket.emit(SOCKET_EVENTS.JOIN_ROOM, { roomId: response.data.roomId });
                setRoom(response.data);
            }
        });
    }, [socket])

    useEffect(() => {
        if (messageBoxRef.current && rootMessageBoxRef.current && room._id) {
            setTimeout(() => {
                const observer = new IntersectionObserver((entries) => {
                    entries.forEach((entry) => {
                        if (entry.isIntersecting) {
                            dispatch({ type: COMMON_ACTIONS.SET_RESERVATION_MESSAGES_PAGINATION });
                        }
                    });
                }, { rootMargin: '0px', threshold: 0.1, root: rootMessageBoxRef.current });

                if (messageBoxRef.current instanceof Element) {
                    observer.observe(messageBoxRef.current);
                }

                return () => observer.disconnect();
            }, 2000)
        }
    }, [messageBoxRef, room, rootMessageBoxRef]);

    useEffect(() => {
        if (Object.keys(specificBooking).length) {
            let type = CATEGORY_TYPE.PROPERTY;

            if (location.pathname.startsWith('/carBookings')) type = CATEGORY_TYPE.CAR;

            const createRoomPayload = {
                bookingId: specificBooking._id,
                type,
            };
            socket.emit(SOCKET_EVENTS.CREATE_ROOM, createRoomPayload);

        }
    }, [specificBooking]);

    useEffect(() => {
        socket.on(SOCKET_EVENTS.MESSAGE_RECEIVED, function (data) {
            const finalData = [data, ...roomMessages]

            setRoomMessages(finalData);
        })
    }, [roomMessages]);

    if (specificBookingLoader) {
        return <div className="d-flex justify-content-center align-items-center flex-column loading-text">
            <h5>{MESSAGES.LOADING}</h5>
        </div>
    }

    // const bookingId = specificBooking._id;
    // console.log('bookingId', bookingId);
    // var bookingNumber = bookingId.substr(bookingId.length - 5); 


    return (
        <div className='property-list-out'>
            <div className='proety-top'>
                <h3 className="mb-0">Reservation Details</h3>
            </div>
            <div className='row'>
                <div className='col-md-4'>
                    <div className='detail-box'>
                        <div className='pro-detail-mg'>
                            <div className='left-pro-det'>
                                <h5>{specificBooking?.user_id?.firstName} {specificBooking?.user_id?.lastName}</h5>
                                <p> {specificBooking.memberString}</p>
                                <p>Booking No: {specificBooking._id}</p>
                            </div>
                        </div>
                        <div className='pro-detail-mg'>
                            <div className='left-pro-det'>
                                {specificBooking.property_id && specificBooking.property_id._id ? <h5>{specificBooking.property_id.name}</h5> : null}
                                {specificBooking.car_id && specificBooking.car_id._id ? <h5>{specificBooking.car_id.name}</h5> : null}

                                {/* {specificBooking.property_id && specificBooking.property_id._id ? <p>Unit Number: {specificBooking.property_id.unitNumber}</p> : null}
                                {specificBooking.car_id && specificBooking.car_id._id ? <p>Unit Number: {specificBooking.car_id.unitNumber}</p> : null} */}
                            </div>
                            <div className='right-pro-del'>
                                <img src={specificBooking.property_id && specificBooking.property_id.image && specificBooking.property_id.image} alt="" />
                            </div>
                        </div>
                        <div className='check-date'>
                            <div className='left-date-chk'>
                                <span>{moment(specificBooking.checkIn).format('ddd')}</span>
                                <h5>{moment(specificBooking.checkIn).format('MMM DD, YYYY')}</h5>
                                <h6>Check in</h6>
                            </div>

                            <div className='left-date-chk'>
                                <span>{moment(specificBooking.checkOut).format('ddd')}</span>
                                <h5>{moment(specificBooking.checkOut).format('MMM DD, YYYY')}</h5>
                                <h6>Check out</h6>
                            </div>
                        </div>

                        <div className='due-date'>
                            <ul>
                                <li>
                                    <span>Amount per day</span>
                                    <label>{specificBooking.currencySymbol} {specificBooking.amountPerDay}</label>
                                </li>
                                <li>
                                    <span className={styles.vat}>VAT</span>
                                    <label className={styles.vat}>+ {specificBooking.currencySymbol} {specificBooking.vat}</label>
                                </li>
                                {
                                    specificBooking.userPayout && specificBooking.userPayout.promotionData && specificBooking.userPayout.promotionData.promotionDiscount ?
                                        <li>
                                            <span className={styles.discount}>Promotion Applied<span className={styles.discount}>({specificBooking.userPayout.promotionData.promotionName})</span></span>
                                            <label className={styles.discount}>- {specificBooking.userPayout && specificBooking.userPayout.currencySymbol} {specificBooking.userPayout.promotionData.promotionDiscount}</label>
                                        </li>
                                        :
                                        null
                                }

                                {
                                    specificBooking.userPayout && specificBooking.userPayout.voucherData && specificBooking.userPayout.voucherData.promotionDiscount ?
                                        <li>
                                            <span className={styles.discount}>Voucher Applied <span className={styles.discount}>({specificBooking.userPayout.voucherData.voucherName})</span></span>
                                            <label className={styles.discount}>- {specificBooking.userPayout && specificBooking.userPayout.currencySymbol} {specificBooking.userPayout.voucherData.voucherDiscount}</label>
                                        </li>
                                        :
                                        null
                                }
                                <li>
                                    <span>
                                        <b>Total</b>
                                    </span>
                                    <label>
                                        <b>{specificBooking.currencySymbol} {specificBooking.overAllAmountToPaid}</b>
                                    </label>
                                </li>
                                <li>
                                    <span>Mode of Payment</span>
                                    <label>{PAYMENT_TYPE[specificBooking.modeOfPayment]}</label>
                                </li>
                            </ul>
                        </div>

                        {specificBooking.userPayout && specificBooking.userPayout.payCurrency &&
                            <div className='due-date'>
                                <ul>
                                    <li>
                                        <span>Amount paid in crypto</span>
                                        <label>{specificBooking.userPayout && specificBooking.userPayout.amountSentInCrypto} {specificBooking.userPayout && specificBooking.userPayout.payCurrency}</label>
                                    </li>
                                </ul>
                            </div>
                        }

                        {specificBooking.paymentButton && <div className='due-date d-flex justify-content-end'><button className={styles.acceptPayment} type="button" onClick={acceptBookingPayment}>Accept Payment</button></div>}
                    </div>

                    <div className='detail-box mt-5'>
                        <div className='pro-detail-mg'>
                            <div className='left-pro-det'>
                                <h6>Vendor Payout</h6>
                                <h5>{specificBooking?.user_id?.firstName} {specificBooking?.user_id?.lastName}</h5>
                            </div>
                        </div>
                        <div className='due-date'>
                            <ul>
                                <li><span>Amount per day</span> <label>{specificBooking.currencySymbol} {specificBooking.amountPerDay}</label></li>
                                <li><span className={styles.discount}>Service Charge</span> <label className={styles.discount}>- 0</label></li>
                                <li><span>Payout Status</span> <label>{ (specificBooking.hasDonePayment == true) ? INVOICE_STATUS[2] : INVOICE_STATUS[1]}</label></li>

                                <li><span><b>Settlement Amount</b></span> <label><b>{specificBooking.currencySymbol} {specificBooking.overAllAmountToPaid}</b></label></li>
                            </ul>
                        </div>
                    </div>
                </div>
                {
                    room ? <div className='col-md-8'>
                        <div className='chat-otr'>
                            <div className="caht-head">
                                <div className="left-chat-hd">
                                    <span>
                                        <img src={user.userType === USER_TYPE.ADMIN ? AppIcon : room.image ? room.image : "https://mdbcdn.b-cdn.net/img/Photos/new-templates/bootstrap-chat/ava1-bg.webp"}
                                            alt="avatar"
                                            className="d-flex rounded-circle align-self-center me-3"
                                            width="60"
                                            height={60}
                                        />
                                    </span>
                                    <div className="detils">
                                        {user.userType === USER_TYPE.ADMIN ? <h4>{room.userFirstName} {room.userLastName} - {room.vendorFirstName} {room.vendorLastName}</h4> : <h4>{room.userFirstName} {room.userLastName}</h4>}
                                    </div>
                                </div>
                            </div>
                            <div ref={rootMessageBoxRef}>
                                <div id="scrollableDiv" className="pt-3 pe-3 reservation-message-box">

                                    {roomMessages.map((item, index) => (
                                        <div key={index} className={`d-flex flex-row ${user.userType === item.senderType.toUpperCase() ? 'justify-content-end' : 'justify-content-start'}`}>
                                            {
                                                user.userType !== item.senderType.toUpperCase()
                                                    ?
                                                    <img
                                                        src={item.senderType && item.senderType.toUpperCase() === USER_TYPE.ADMIN ? AppIcon : item.senderId && item.senderId.image ? item.senderId.image : "https://mdbcdn.b-cdn.net/img/Photos/new-templates/bootstrap-chat/ava6-bg.webp"}
                                                        alt="avatar 1"
                                                        style={{ width: "45px", height: "45px" }}
                                                        className="rounded-circle"
                                                    />
                                                    :
                                                    null
                                            }
                                            <div>
                                                <p className={`small p-2 ms-3 mb-1 rounded-3 ${user.userType === item.senderType.toUpperCase() ? 'text-white bg-primary' : ''}`} style={{ backgroundColor: "#f5f6f7" }}>
                                                    {item.message}
                                                </p>
                                                <p className={`small ms-3 mb-3 rounded-3 text-muted ${user.userType === item.senderType.toUpperCase() ? 'float-end' : ''}`}>
                                                    {moment(item.createdAt).format('hh:mm A | MMM DD')}
                                                </p>
                                            </div>
                                        </div>
                                    ))}
                                    <div ref={messageBoxRef}></div>
                                </div>
                            </div>
                            {
                                !disableChat ?
                                    <div className="text-muted d-flex justify-content-start align-items-center pe-3 pt-3 mt-2">
                                        <img
                                            src={user.userType === USER_TYPE.ADMIN ? AppIcon : room.image ? room.image : "https://mdbcdn.b-cdn.net/img/Photos/new-templates/bootstrap-chat/ava1-bg.webp"}
                                            alt="avatar 3"
                                            className='senderImage'
                                        />
                                        <input
                                            type="text"
                                            className="form-control form-control-lg message"
                                            placeholder="Type message"
                                            onChange={newChatFormik.handleChange}
                                            name="message"
                                            value={newChatFormik.values.message}
                                            id='exampleFormControlInput2'
                                            onKeyUp={(event) => {
                                                if (event.keyCode === 13) {
                                                    newChatFormik.submitForm(event);
                                                }
                                            }}
                                        />
                                        <a className="ms-1 text-muted" href="#!">
                                            <i className="fas fa-paperclip"></i>
                                        </a>
                                        <a className="ms-3 text-muted" href="#!">
                                            <i className="fas fa-smile"></i>
                                        </a>
                                        <i className="fas fa-paper-plane ms-3" onClick={(event) => newChatFormik.submitForm(event)}></i>
                                    </div> : <p>CHat is disabled</p>
                            }
                        </div>
                    </div> : null
                }
            </div>
        </div>
    );
};


const mapDispatchToProps = (dispatch) => ({
    getSpecificBooking: (query, loader) => dispatch(VendorBookingRequestAction.specificBookingRequest(query, loader)),
    acceptPayment: (payload, loader) => dispatch(VendorBookingRequestAction.acceptBooking(payload, loader)),
});

export default connect(null, mapDispatchToProps)(SpecificPropertyBooking);