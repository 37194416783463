import { connect, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import moment from 'moment';
import Swal from "sweetalert2";
import { Link, useNavigate } from "react-router-dom";

import { AdminVoucherAction } from "../../../store/admin/thunk";
import { APP_ROUTES, MESSAGES, SORTING_FIELDS } from "../../../constants";
import Button from "../../../components/Button";
import Pagination from "../../../components/Universal/Pagination";
import { Toaster } from "../../../components/Toaster";
import { TOAST_TYPE } from "../../../constants";
import { SortIcon } from "../../../assets/images";

const AdminVouchers = (props) => {
    const navigate = useNavigate();

    const { vouchers: { vouchers, count } } = useSelector((state) => state.admin);

    const [pagination, setPagination] = useState({ offset: 0, limit: 10 });
    const [sorting, setSorting] = useState({ type: null, order: null });

    const removeVoucher = (voucherId, startDate, endDate) => {

        if ((moment().isAfter(moment(startDate))) && moment().isBefore(moment(endDate))) {
            Toaster(TOAST_TYPE.ERROR, MESSAGES.VOUCHER.DELETE.STARTED);
        }
        else if (moment().isAfter(moment(endDate))) {
            Toaster(TOAST_TYPE.ERROR, MESSAGES.VOUCHER.DELETE.ENDED);
        }
        else {
            Swal.fire({
                title: 'Are you sure?',
                text: `You want to remove this voucher`,
                confirmButtonText: 'Yes',
                showCancelButton: true,
            }).then((result) => {
                if (result.isConfirmed) {
                    props.remove(voucherId, props.loader);
                }
            });
        };
    };

    const editVoucher = (voucherId, startDate, endDate) => {

        if (moment().isAfter(moment(startDate)) && moment().isBefore(moment(endDate))) {
            Toaster(TOAST_TYPE.ERROR, MESSAGES.VOUCHER.EDIT.STARTED);
        }
        else if (moment().isAfter(moment(endDate))) {
            Toaster(TOAST_TYPE.ERROR, MESSAGES.VOUCHER.EDIT.ENDED);
        }
        else {
            navigate(`/vouchers/${voucherId}`);
        };
    };

    const handleSorting = (field) => {
        if (sorting.type === field) {
            setSorting({ ...sorting, order: -1 * sorting.order });
        }
        else {
            setSorting({ type: field, order: 1 });
        };
    };

    const addNewVoucher = () => navigate(APP_ROUTES.ADD_VOUCHER);

    useEffect(() => {
        const query = {
            ...pagination,
            sort: sorting.type,
            sortType: sorting.order,
        }
        props.list(query, props.loader);
    }, [pagination, sorting]);

    return (
        <div className="vendor-listing">
            <div className="d-flex justify-content-between align-items-start my-3">
                {/* <p className={styles.heading}>Vouchers</p> */}
                <div className="d-flex justify-content-between align-items-center">
                    <ul className="paginaion">
                        <li><Link to="/">Dashboard</Link></li>
                        <li className="active"><Link to="/vouchers">Vouchers</Link></li>
                    </ul>
                </div>
                <Button title="Add" type="button" handleClick={addNewVoucher} />
            </div>
            <div className="listing-table mb-2">
                <div className="listing-table-scrl">
                    <table cellPadding={0} cellSpacing={0}>
                        <thead>
                            <tr>
                                <th></th>
                                <th className="cursor-pointer" onClick={() => handleSorting(SORTING_FIELDS.TITLE)}>
                                    <span className="me-1">Title</span>
                                    {sorting.type !== SORTING_FIELDS.TITLE ? <img src={SortIcon} width={15} height={15} /> : null}
                                    {sorting.type === SORTING_FIELDS.TITLE ? sorting.order === 1 ? <i className="fa fa-angle-up" /> : <i className="fa fa-angle-down" /> : null}
                                </th>

                                <th className="cursor-pointer" onClick={() => handleSorting(SORTING_FIELDS.DISCOUNT)}>
                                    <span className="me-1">Discount</span>
                                    {sorting.type !== SORTING_FIELDS.DISCOUNT ? <img src={SortIcon} width={15} height={15} /> : null}
                                    {sorting.type === SORTING_FIELDS.DISCOUNT ? sorting.order === 1 ? <i className="fa fa-angle-up" /> : <i className="fa fa-angle-down" /> : null}
                                </th>

                                <th className="cursor-pointer" onClick={() => handleSorting(SORTING_FIELDS.START_DATE)}>
                                    <span className="me-1">Start Date</span>
                                    {sorting.type !== SORTING_FIELDS.START_DATE ? <img src={SortIcon} width={15} height={15} /> : null}
                                    {sorting.type === SORTING_FIELDS.START_DATE ? sorting.order === 1 ? <i className="fa fa-angle-up" /> : <i className="fa fa-angle-down" /> : null}
                                </th>

                                <th className="cursor-pointer" onClick={() => handleSorting(SORTING_FIELDS.END_DATE)}>
                                    <span className="me-1">End Date</span>
                                    {sorting.type !== SORTING_FIELDS.END_DATE ? <img src={SortIcon} width={15} height={15} /> : null}
                                    {sorting.type === SORTING_FIELDS.END_DATE ? sorting.order === 1 ? <i className="fa fa-angle-up" /> : <i className="fa fa-angle-down" /> : null}
                                </th>

                                <th className="cursor-pointer text-right" onClick={() => handleSorting(SORTING_FIELDS.MINIMUM_SPEND)}>
                                    <span className="me-1">Min. Amount to spend</span>
                                    {sorting.type !== SORTING_FIELDS.MINIMUM_SPEND ? <img src={SortIcon} width={15} height={15} /> : null}
                                    {sorting.type === SORTING_FIELDS.MINIMUM_SPEND ? sorting.order === 1 ? <i className="fa fa-angle-up" /> : <i className="fa fa-angle-down" /> : null}
                                </th>

                                <th className="cursor-pointer text-right" onClick={() => handleSorting(SORTING_FIELDS.FREQUENCY_PER_USER)}>
                                    <span className="me-1">Frequency of Voucher per user</span>
                                    {sorting.type !== SORTING_FIELDS.FREQUENCY_PER_USER ? <img src={SortIcon} width={15} height={15} /> : null}
                                    {sorting.type === SORTING_FIELDS.FREQUENCY_PER_USER ? sorting.order === 1 ? <i className="fa fa-angle-up" /> : <i className="fa fa-angle-down" /> : null}
                                </th>
                                <th>Minimum spend Currency</th>
                                <th>Actions</th>
                            </tr>
                        </thead>
                        <tbody>
                            {vouchers.map((voucher, index) => (
                                <tr key={voucher._id}>
                                    <td>{pagination.offset + index + 1}</td>
                                    <td>{voucher.title}</td>
                                    <td>{voucher.discountCurrency} {voucher.discount}</td>
                                    <td>{moment(voucher.startDate).format('DD-MMM-YYYY')}</td>
                                    <td>{moment(voucher.endDate).format('DD-MMM-YYYY')}</td>
                                    <td align="right">{voucher.minimumSpend}</td>
                                    <td align="right">{voucher.frequencyPerUser}</td>
                                    <td>{voucher.minimumSpendCurrency}</td>
                                    
                                    <td>
                                        <i className="action fa fa-user" onClick={() => navigate(`/vouchers/${voucher._id}/users`)}></i>
                                        <i className="action fa fa-edit" onClick={() => editVoucher(voucher._id, voucher.startDate, voucher.endDate)}></i>
                                        <i onClick={() => removeVoucher(voucher._id, voucher.startDate, voucher.endDate)} className="action fas fa-trash py-2"></i>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </div>
            <Pagination
                count={count}
                setPagination={setPagination}
            />
        </div>

    );
};

const mapDispatchToProps = (dispatch) => ({
    list: (params, loader) => dispatch(AdminVoucherAction.list(params, loader)),
    remove: (promotionId, loader) => dispatch(AdminVoucherAction.remove(promotionId, loader)),
})

export default connect(null, mapDispatchToProps)(AdminVouchers);