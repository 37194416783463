/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Slider from "react-slick";
import { connect, useDispatch, useSelector } from "react-redux";
import MDEditor from '@uiw/react-md-editor';

import { CommonAction } from "../../../../store/common/thunk";
import EditProperty from "./EditPopup";
import COMMON_ACTIONS from "../../../../store/common/actions";
import { EDIT_PROPERTY_TYPE, MESSAGES, USER_TYPE } from "../../../../constants";
import styles from './style.module.css';
import { BedroomWithBathroom, BedroomWithoutBathroom, LocationIcon } from "../../../../assets/images";
import useWindowWidth from "../../../../hooks/useWindowWidth";

const ViewProperty = ({ loader, specificProperty }) => {
    const { id } = useParams();
    const dispatch = useDispatch();
    const windowWidth = useWindowWidth();

    const [nav1, setNav1] = useState();
    const [nav2, setNav2] = useState();
    const [editProperty, setEditProperty] = useState({ show: false, type: '' });
    const [locationDetails, setLocationDetails] = useState({ area: '', building: '', city: '' });
    const [propertyFeatures, setPropertyFeatures] = useState({ bedrooms: [], nonAttachedBathrooms: [], bedIcons: [] });
    const [propertyItems, setPropertyItems] = useState({ bedrooms: 0, bathrooms: 0, capacity: 0, beds: 0 });

    const { property, propertyLoader, user } = useSelector((state) => state.common);

    const handlePropertyOptionClick = (type) => {
        if (user.userType !== USER_TYPE.VENDOR) return;

        setEditProperty({ show: true, type });
    };

    useEffect(() => {
        const query = { id };

        specificProperty(query, loader);
    }, []);

    useEffect(() => {
        if (Object.keys(property).length) {
            const selectedCity = property.cityData.find(item => item.isSelected);
            const selectedArea = selectedCity.areaData.find(item => item.isSelected);
            const selectedBuilding = selectedArea.buildingData.find(item => item.isSelected);

            let bedrooms = [];
            let bathroomCount = 0;
            let bedCount = 0;

            for (const item of property.bedRoomData) {
                let obj = item;
                if (item.bathRoom) {
                    obj.bathRoomData = property.bathRooms.find(it => it.bedRoomNumber === item.bedRoomNumber)
                    bathroomCount += 1;
                }
                else obj.bathRoomData = [];

                let icons = item.bedData.map(item => ({ icon: item.bedIcon }));

                bedCount += item.bedData.length;
                obj.bedIcons = icons;

                bedrooms.push(obj);
            }

            const nonAttachedBathrooms = property.bathRooms.filter(item => !item.bedRoomNumber);

            dispatch({ type: COMMON_ACTIONS.SET_IMAGES, key: 'selectedImage', value: property.images });
            dispatch({ type: COMMON_ACTIONS.SET_IMAGES, key: 'images', value: property.images });

            setPropertyItems({ ...propertyItems, capacity: property.personCapacity, bedrooms: bedrooms.length, bathrooms: bathroomCount + nonAttachedBathrooms.length, beds: bedCount });

            setPropertyFeatures({ bedrooms: bedrooms, nonAttachedBathrooms });
            setLocationDetails({ city: selectedCity.name, building: selectedBuilding.name, area: selectedArea.name });
        }
    }, [property]);

    const closeEditPropertyModal = () => setEditProperty({ show: false, type: '' });

    if (propertyLoader) {
        return <div className="d-flex justify-content-center align-items-center flex-column loading-text">
            <h5>{MESSAGES.LOADING}</h5>
        </div>
    }
    else if (!Object.keys(property).length) {
        return (
            <div className="d-flex justify-content-center align-items-center flex-column no-property">
                <i className="fa fa-exclamation-triangle" />
                <h4>{MESSAGES.PROPERTY.NOT_FOUND}</h4>
            </div>
        )
    }

    return (
        <div className="dasboad-property-detail">
            <div className="product-slider-main">
                <div className="main-sider">
                    <div className="side-left">
                        <Slider
                            asNavFor={nav2}
                            ref={(slider1) => setNav1(slider1)}
                            dots={true}
                        >
                            {property.images.map((slideimg, key) => (
                                <div key={key}>
                                    <img src={slideimg} alt="property" />
                                </div>
                            ))}
                        </Slider>
                    </div>

                    <div className="side-sider">
                        <Slider
                            asNavFor={nav1}
                            ref={(slider2) => setNav2(slider2)}
                            slidesToShow={3}
                            swipeToSlide={true}
                            focusOnSelect={true}
                        >
                            <div>
                                <img src={property.images && property.images[0]} alt="propertyView" />
                            </div>
                            <div>
                                <img src={property.images && property.images[1]} alt="propertyView" />
                            </div>
                            <div>
                                <img src={property.images && property.images[2]} alt="propertyView" />
                            </div>
                        </Slider>
                        {
                            user.userType === USER_TYPE.VENDOR ?
                                <div onClick={() => setEditProperty({ show: true, type: EDIT_PROPERTY_TYPE.IMAGES })} className="show-all">
                                    <p>Edit</p>
                                </div> : null
                        }
                    </div>
                </div>
                <div>
                    <div
                        onClick={() => handlePropertyOptionClick(EDIT_PROPERTY_TYPE.NAME)}
                        className={`name d-flex ${user.userType === USER_TYPE.VENDOR ? 'cursor-pointer' : ''}`}
                    >
                        <h4>{property.name}{" "} ({property.unitNumber})</h4>
                    </div>
                    <h5
                        className={`loc d-flex ${user.userType === USER_TYPE.VENDOR ? 'cursor-pointer' : ''}`}
                        onClick={() => handlePropertyOptionClick(EDIT_PROPERTY_TYPE.LOCATION)}
                    >
                        <img src={LocationIcon} alt="location" />
                        {locationDetails.building}, {locationDetails.area}, {locationDetails.city}
                    </h5>
                    <div className="pro-featu">
                        <ul>
                            <li>{propertyItems.capacity} Guest</li>
                            <li>{propertyItems.bedrooms} Bedroom</li>
                            <li>{propertyItems.bathrooms} Bathroom</li>
                            <li>{propertyItems.beds} Beds</li>
                        </ul>
                    </div>
                    <div
                        onClick={() => handlePropertyOptionClick(EDIT_PROPERTY_TYPE.DESCRIPTION)}
                        className={`description d-flex ${user.userType === USER_TYPE.VENDOR ? 'cursor-pointer' : ''}`}
                    >
                        <h4>Description</h4>
                    </div>
                    <div className="cursor-pointer" onClick={() => handlePropertyOptionClick(EDIT_PROPERTY_TYPE.DESCRIPTION)}>
                        <MDEditor.Markdown source={property.description} style={{ whiteSpace: 'pre-wrap' }} className="mb-2 meta-desciption" />
                    </div>

                    <div
                        className={`description d-flex mt-4 mb-2 ${user.userType === USER_TYPE.VENDOR ? 'cursor-pointer' : ''}`}
                        onClick={() => handlePropertyOptionClick(EDIT_PROPERTY_TYPE.FEATURES)}
                    >
                        <h4>Property Feature</h4>
                    </div>

                    <div className="main-room-slider cursor-pointer" onClick={() => handlePropertyOptionClick(EDIT_PROPERTY_TYPE.FEATURES)}>
                        <Slider
                            slidesToShow={windowWidth < 766 ? 1 : propertyFeatures.bedrooms.length < 3 ? propertyFeatures.bedrooms.length : 3}
                            slidesToScroll={windowWidth < 766 ? 1: propertyFeatures.bedrooms.length < 3 ? propertyFeatures.bedrooms.length : 3}
                            dots={true}
                        >
                            {propertyFeatures.bedrooms.map((bedroom, index) => (
                                <div key={index} className={`${styles.propertyFeature} ${propertyFeatures.bedrooms.length === 1 ? 'w-25' : ''}`}>

                                    <p>Room {index + 1}</p>
                                    <ul>
                                        {bedroom.bedData.map((it, innerInd) => (
                                            <li key={innerInd}>
                                                <div>
                                                    <img src={bedroom.bedIcons[innerInd].icon} className={styles.bedIcon} alt="double_bed" />{it.bedName} ({it.numberOfBeds})
                                                </div>
                                            </li>
                                        ))}
                                    </ul>
                                </div>
                            ))}
                        </Slider>
                    </div>
                    <div className="listing-table">
                        <div className="listing-table-scrl">
                            {/* {propertyFeatures.nonAttachedBathrooms.length ?
                                <Fragment>
                                    <p>Non Attached Bathrooms</p>
                                    {propertyFeatures.nonAttachedBathrooms.map((feature, index) => (
                                        <table cellPadding={0} cellSpacing={0}>
                                            <tbody>
                                                <tr>
                                                    <th className="bathroomTypeIndex"></th>
                                                    <th>Bathroom Type</th>
                                                </tr>
                                                <tr>
                                                    <td>{index + 1}</td>
                                                    <td>
                                                        {feature.bathRoomPrivate ? 'Private' : 'Public Bathroom'} {feature.bathRoomEnsuite ? ', Ensuite' : null}
                                                    </td>

                                                </tr>
                                            </tbody>
                                        </table>
                                    ))}
                                </Fragment> : null} */}
                        </div>
                    </div>

                    <div
                        className={`description d-flex mt-4 mb-2 ${user.userType === USER_TYPE.VENDOR ? 'cursor-pointer' : ''}`}
                        onClick={() => handlePropertyOptionClick(EDIT_PROPERTY_TYPE.AMMENITIES)}
                    >
                        <h4>Ammenities</h4>
                    </div>
                    <ul className="amnites aminity-wh-img cursor-pointer" onClick={() => handlePropertyOptionClick(EDIT_PROPERTY_TYPE.AMMENITIES)}>
                        {property.amenities && property.amenities.map((item, key) => (
                            <li key={key}>
                                <img src={item.icon} alt="" />
                                {item.title}
                            </li>
                        ))}
                    </ul>

                    <div
                        className={`description d-flex mb-3 ${user.userType === USER_TYPE.VENDOR ? 'cursor-pointer' : ''}`}
                        onClick={() => handlePropertyOptionClick(EDIT_PROPERTY_TYPE.OTHER_ROOM)}
                    >
                        <h4>Other Rooms</h4>
                    </div>

                    <ul className="amnites aminity-wh-img other-room cursor-pointer" onClick={() => handlePropertyOptionClick(EDIT_PROPERTY_TYPE.OTHER_ROOM)}>
                        {
                            property.otherRoom.driverRoom ?
                                <li>
                                    <img alt="driverRoom" className={styles.room} src={property.otherRoom.driverRoomBathRoom ? BedroomWithBathroom : BedroomWithoutBathroom} />
                                    Driver Room {property.otherRoom.driverRoomBathRoom ? ' with attached bathroom' : ''}</li>
                                : <li>No Driver Room</li>
                        }

                        {
                            property.otherRoom.maidRoom ?
                                <li>
                                    <img alt="maidRoom" className={styles.room} src={property.otherRoom.maidRoomBathRoom ? BedroomWithBathroom : BedroomWithoutBathroom} />
                                    Maid Room {property.otherRoom.maidRoomBathRoom ? ' with attached bathroom' : ''}</li>
                                : <li>No Maid Room</li>
                        }
                    </ul>
                    <div className='description d-flex mt-4 mb-2'>
                        <h4>View on map</h4>
                    </div>
                    <div id="map-show" className="map">
                        <iframe title="Car Location" height="500px" width="100%" src={`https://maps.google.com/maps?q=${property && property.latitude},${property && property.longitude}&h1=es;&output=embed`}>
                        </iframe>
                    </div>
                </div>
            </div>

            <EditProperty {...editProperty} property={property} closeEditPropertyModal={closeEditPropertyModal} loader={loader} />
        </div>
    );
}

const mapDispatchToProps = (dispatch) => ({
    specificProperty: (query, loader) => dispatch(CommonAction.specificProperty(query, loader)),
});

export default connect(null, mapDispatchToProps)(ViewProperty);
