import React from "react";
import { Navigate } from "react-router-dom";

const PrivateRoute = (props) => {
  if(localStorage.getItem('accessToken')) {
    if(props.role.includes(props.activeRole)) {
      return props.children;
    }
    else return <Navigate to="/dashboard" />;
  }
  else return <Navigate to="/login" />
}

export default PrivateRoute;
