import * as Yup from 'yup';

import { MESSAGES } from '../constants';

const editTourNameSchema = Yup.object().shape({
    name: Yup.string().required(MESSAGES.VALIDATION_ERRORS.REQUIRED),
    city_id: Yup.string().required(MESSAGES.VALIDATION_ERRORS.REQUIRED),
});

const editTourCategorySchema = Yup.object().shape({
    subCategorySlots: Yup
    .array()
    .of(
        Yup.object().shape({
            title: Yup.string().required(MESSAGES.VALIDATION_ERRORS.REQUIRED),
            subCategoryId: Yup.string().required(MESSAGES.VALIDATION_ERRORS.REQUIRED),
            startTime: Yup.string().required(MESSAGES.VALIDATION_ERRORS.REQUIRED),
            endTime: Yup.string().required(MESSAGES.VALIDATION_ERRORS.REQUIRED),
            maximumPerson: Yup.number().required(MESSAGES.VALIDATION_ERRORS.REQUIRED),
            pickUpDrop: Yup.string().required(MESSAGES.VALIDATION_ERRORS.REQUIRED),
            whatToExpect: Yup.array().required(MESSAGES.VALIDATION_ERRORS.REQUIRED).min(1, MESSAGES.VALIDATION_ERRORS.EMPTY_WHAT_TO_EXPECT)
                .test('what-to-exprect', function (whatToExpect) {
                    const values = whatToExpect.filter(item => item === undefined || item === '');

                    if (values.length) {
                        return this.createError({ message: MESSAGES.VALIDATION_ERRORS.REQUIRED, path: this.path });
                    }
                    return { path: this.path };
                }),
        })
    )
    .required(),

});

export {
    editTourNameSchema,
    editTourCategorySchema,
};