import VENDOR_ACTIONS from "../actions";

const initialState = {
    payoutLoader: false,
    payouts: [],
    payoutsCount: 0,
};

const vendorPayoutReducer = (state = initialState, action) => {

    switch (action.type) {
        case VENDOR_ACTIONS.REQUEST_PAYOUTS:
            return {
                ...state,
                payoutLoader: action.status,
            };


        case VENDOR_ACTIONS.SET_PAYOUTS:
            return {
                ...state,
                payouts: action.payouts,
                payoutsCount: action.count,
            };
        default:
            return state;
    }
};

export default vendorPayoutReducer;