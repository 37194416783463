import { Toaster } from "../../../components";
import { TOAST_TYPE, USER_TYPE } from "../../../constants";
import COMMON_ACTIONS from "../../common/actions";

const { default: Axios } = require("../../../components/Universal/Axios");

const getInvoiceList = (query, userType, loader) => async (dispatch) => {
    try {
        dispatch({ type: COMMON_ACTIONS.REQUEST_INVOICE_LIST, status: true });

        if (userType === USER_TYPE.ADMIN) {
            const response = await Axios.get("/payout/admin/invoiceList", query, loader);
            dispatch({ type: COMMON_ACTIONS.SET_INVOICE_LIST, invoices: response.data.invoices, count: response.data.count });
        }
        else {
            const response = await Axios.get("/payout/invoiceList", query, loader);
            dispatch({ type: COMMON_ACTIONS.SET_INVOICE_LIST, invoices: response.data.invoices, count: response.data.count });
        }

        dispatch({ type: COMMON_ACTIONS.REQUEST_INVOICE_LIST, status: false });
    }
    catch (err) {
        dispatch({ type: COMMON_ACTIONS.REQUEST_INVOICE_LIST, status: false });
    }
};

const specificInvoiceDetails = (query, userType, loader) => async (dispatch) => {

    try {
        dispatch({ type: COMMON_ACTIONS.REQUEST_SPECIFIC_INVOICE, status: true });

        if (userType === USER_TYPE.ADMIN) {
            const response = await Axios.get("/payout/admin/bookingsInInvoice", query, loader);
            dispatch({ type: COMMON_ACTIONS.SET_SPECIFIC_INVOICE_DETAILS, invoices: response.data.payouts, count: response.data.count });
        }
        else {
            const response = await Axios.get("/payout/bookingsInInvoice", query, loader);

            dispatch({ type: COMMON_ACTIONS.SET_SPECIFIC_INVOICE_DETAILS, invoices: response.data.payouts, count: response.data.count });
        }

        dispatch({ type: COMMON_ACTIONS.REQUEST_SPECIFIC_INVOICE, status: false });
    }
    catch (err) {
        dispatch({ type: COMMON_ACTIONS.REQUEST_SPECIFIC_INVOICE, status: false });
    }
};


const markPayoutSuccess = (payload, loader, query, userType) => async (dispatch) => {

    try {

        const response = await Axios.post("/payout/admin/markPayoutSuccess", payload, loader);
        Toaster(TOAST_TYPE.SUCCESS, 'Payout marked successfully');

        dispatch(getInvoiceList(query, userType, loader));
    }
    catch (err) {}
};

export {
    getInvoiceList,
    specificInvoiceDetails,
    markPayoutSuccess
};