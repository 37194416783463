export const APP_ROUTES = {
    LOGIN: '/login',
    FORGET_PASSWORD: '/forgot-password',
    SUB_ADMIN_LIST: '/subAdmin',
    VIEW_SUB_ADMIN: '/sub-admin',
    USERS_LIST: '/users',
    EDIT_USER: '/edit-user',
    VIEW_USER: '/users/:id',
    VENDOR_LIST: '/vendors',
    PROPERTIES_LIST: '/properties',
    CARS_LIST: '/cars',
    TOURS_LIST: '/tours',
    PROPERTY_REQUESTS: '/propertyBookings',
    SPECIFIC_PROPERTY_REQUEST: '/propertyBookings/:bookingId',
    SPECIFIC_CAR_REQUEST: 'carBookings/:bookingId',
    CATEGORIES_LIST: '/categories',
    CITIES_LIST: '/cities',
    CHATS_LIST: '/chats',
    NOTIFICATIONS_LIST: '/notifications',
    CMS: '/cms',
    FAQS: '/faqs',
    ADD_VENDOR: '/vendors/new',
    VIEW_VENDOR: '/vendor/:id',
    PROPERTY_DETAILS: '/property/:id',
    CAR_DETAILS: '/car/:id',
    SUB_CATEGORY_LIST: '/category',
    ADD_CATEGORY: '/add-category',
    ADD_SUB_CATEGORY: '/add-subcategory',
    ADD_CITY: '/cities/new',
    ADD_CAR: '/car/new',
    EDIT_CAR: '/car/:slug',
    ADD_TOUR: '/tour/new',
    TOUR_BOOKINGS: '/toursbookings',
    REVENUES_LIST: '/revenues',
    DAILY_REVENUE: '/daily-revenues',
    VENDOR_REVENUE_LIST: '/vendor-revenues',
    VENDOR_DAILY_REVENUE: '/vendor-daily-revenues',
    DASHBOARD: '/dashboard',
    ADD_PROPERTY: '/property/new',
    VIEW_CAR: '/car/:id',
    VIEW_TOUR: '/tour/:id',
    EDIT_CITY: '/cities/:citySlug',
    AREAS_LIST: '/cities/:citySlug/areas',
    EDIT_AREA: '/cities/:citySlug/areas/:areaSlug',
    BUILDINGS_LIST: '/cities/:citySlug/areas/:areaSlug/buildings',
    EDIT_BUILDING: '/cities/:citySlug/areas/:areaSlug/buildings/:buildingSlug',
    ADD_AREA: '/cities/:citySlug/add-area',
    ADD_BUILDING: '/cities/:citySlug/areas/:areaSlug/add-building',
    EDIT_CMS :'/editcms',
    ADD_FAQ: '/add-faq',
    EDIT_FAQ: '/edit-faq',
    CAR_BOOKINGS_LIST: '/carBookings',
    ADD_SUB_ADMIN: '/add-sub-admin',
    VIEW_FAQ: '/faq',
    PROPERTY_CALENDAR: '/calendar/property',
    TOUR_CALENDAR: '/calendar/tour',
    NEW_CHAT: '/newchats',
    PROMOTIONS: '/promotions',
    ADD_PROMOTION: '/promotions/new',
    EDIT_PROMOTION: '/promotions/:id',
    PROPERTY_PROMOTION: '/properties/:id/promotions',
    SELECT_NEW_PROMOTION: '/properties/:id/promotions/select',
    ADD_PROPERTY_PROMOTION: '/properties/:id/promotions/:promotionId/new',
    EDIT_PROPERTY_PROMOTION: '/properties/:id/promotions/:promotionId/edit',
    VOUCHERS: '/vouchers',
    ADD_VOUCHER: '/voucher/new',
    CLAIMED_VOUCHER_USERS: '/vouchers/:id/users',
    EDIT_VOUCHER: '/vouchers/:id',
    VIEW_PAYOUTS: '/payouts',
    VIEW_INVOICES: '/invoices',
    SPECIFIC_VENDOR_PAYOUTS: '/payouts/:id',
};