import moment from "moment";
import * as Yup from "yup";

import { MESSAGES } from "../constants";

const AddCompaignDealsSchema = Yup.object().shape({
	promotionName: Yup.string().required(MESSAGES.VALIDATION_ERRORS.REQUIRED),
	discount: Yup
		.number().required(MESSAGES.VALIDATION_ERRORS.REQUIRED)
		.min(10, MESSAGES.VALIDATION_ERRORS.DISCOUNT_BETWEEN_10_20)
		.max(20, MESSAGES.VALIDATION_ERRORS.DISCOUNT_BETWEEN_10_20),
	description: Yup.string().required(MESSAGES.VALIDATION_ERRORS.REQUIRED),
	bookableStartDate: Yup.string().required(MESSAGES.VALIDATION_ERRORS.REQUIRED),
	bookableEndDate: Yup.string().required(MESSAGES.VALIDATION_ERRORS.REQUIRED).test('booking-date', function(bookableEndDate) {
		let { bookableStartDate } = this.parent;

		if(bookableStartDate && bookableStartDate.length && bookableEndDate) {
			bookableStartDate = moment(bookableStartDate);
			bookableEndDate = moment(bookableEndDate);

			if(!bookableEndDate.isAfter(bookableStartDate)) {
				return this.createError({ message: MESSAGES.VALIDATION_ERRORS.BOOKING_END_DATE_RANGE, path: this.path })
			}
			return { path: this.path };
		}
	}),
	stayStartDate: Yup.string().required(MESSAGES.VALIDATION_ERRORS.REQUIRED).test('stay-start-date', function(value) {
		let { bookableStartDate } = this.parent;

		if(value && value.length && bookableStartDate) {
			bookableStartDate = moment(bookableStartDate);
			let stayStartDate = moment(value);

			if(stayStartDate.isBefore(bookableStartDate)) {
				return this.createError({ message: MESSAGES.VALIDATION_ERRORS.BOOKING_STAY_START_DATE_RANGE, path: this.path })
			}
			return { path: this.path };
		}
	}),
	stayEndDate: Yup.string().required(MESSAGES.VALIDATION_ERRORS.REQUIRED).test('stay-date', function(stayEndDate) {
		let { stayStartDate, bookableEndDate } = this.parent;

		if(stayStartDate && stayStartDate.length && stayEndDate) {
			stayStartDate = moment(stayStartDate);
			stayEndDate = moment(stayEndDate);
			bookableEndDate = moment(bookableEndDate);

			if(!stayEndDate.isAfter(stayStartDate)) {
				return this.createError({ message: MESSAGES.VALIDATION_ERRORS.BOOKING_END_DATE_RANGE, path: this.path })
			}
			else if(stayEndDate.isAfter(bookableEndDate)) {
				return this.createError({ message: MESSAGES.VALIDATION_ERRORS.BOOKING_STATY_END_DATE_RANGE, path: this.path })
			}
			return { path: this.path };
		}
	}),
});

export default AddCompaignDealsSchema;