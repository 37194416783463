import { Fragment, useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { connect, useSelector } from 'react-redux';
import moment from 'moment';
import Calendar from "react-calendar";
import { useFormik } from "formik";

import { AdminPromotionAction } from '../../../../../store/admin/thunk';
import style from './style.module.css';
import { VendorPromotionAction } from "../../../../../store/vendor/thunk";
import { VendorPromotionSchema } from "../../../../../schemas";
import { Button } from '../../../../../components';
import { PROPERTY_PROMOTIONS_TYPE, PORTFOLIO_DEALS_TYPE } from "../../../../../constants/promotions";
import { CommonAction } from "../../../../../store/common/thunk";

const AddPropertyPromotion = (props) => {
    const params = useParams();
    const location = useLocation();
    const navigate = useNavigate();

    const { promotions: { promotion } } = useSelector((state) => state.admin);
    const { promotions: { vendorPromotion } } = useSelector((state) => state.vendor);
    const [selectedStayDates, setSelectedStayDates] = useState([]);
    const [editPromotion, setEditPromotion] = useState(false);

    const promotionFormik = useFormik({
        initialValues: {
            stayDates: [],
            promotionName: '',
            discount: 0,
            type: 0,
            dealName: '',
        },
        validationSchema: VendorPromotionSchema,
        onSubmit: (values) => {
            if (editPromotion) {
                let payload = {
                    promotionName: values.promotionName,
                    id: params.promotionId,
                };

                if (![PORTFOLIO_DEALS_TYPE.MONTHLY, PORTFOLIO_DEALS_TYPE.WEEKLY].includes(promotionFormik.values.dealName)) {
                    payload.discount = values.discount;
                }

                payload.stayStartDate = moment(values.stayDates[0]).format('YYYY-MM-DD');
                payload.stayEndDate = moment(values.stayDates[values.stayDates.length - 1]).format('YYYY-MM-DD');

                props.updatePromotion(payload, props.loader, navigate);
            }
            else {
                let payload = {
                    stayStartDate: moment(values.stayDates[0]).format('YYYY-MM-DD'),
                    stayEndDate: moment(values.stayDates[values.stayDates.length - 1]).format('YYYY-MM-DD'),
                    promotionName: values.promotionName,
                    promotionId: params.promotionId,
                    propertyId: params.id,
                    discount: values.discount,
                };

                // if(![PORTFOLIO_DEALS_TYPE.MONTHLY, PORTFOLIO_DEALS_TYPE.WEEKLY].includes(promotionFormik.values.dealName)) {
                //     payload.discount = values.discount;
                // }
                props.createPromotion(payload, props.loader, navigate);
            }
        }
    });

    const isDateSelected = (date) => {
        return promotionFormik.values.stayDates.some(selectedDate => selectedDate.toDateString() === date.toDateString());
    };

    useEffect(() => {
        const payload = { id: params.promotionId };

        if (location.pathname.endsWith('edit')) {
            props.specificVendorPromotion(payload, props.loader);
            setEditPromotion(true);
        }
        else {
            props.specificAdminPromotion(params.promotionId, props.loader, params.id);
        };
    }, []);

    useEffect(() => {
        if (promotion) {
            let startDate = moment(promotion.stayStartDate).tz(promotion.timezone || 'Asia/Kolkata');
            let endDate = moment(promotion.stayEndDate).tz(promotion.timezone || 'Asia/Kolkata');

            let selectedDates = [];

            let currentDate = startDate.clone();
            while (currentDate.isSameOrBefore(endDate)) {
                selectedDates.push({ date: currentDate.get('date'), month: currentDate.get('month'), year: currentDate.get('year'), fullDate: currentDate.toISOString() });
                currentDate.add(1, 'day');
            }
            setSelectedStayDates(selectedDates);
        }

    }, [promotion]);

    useEffect(() => {
        if (vendorPromotion && Object.keys(vendorPromotion).length && editPromotion && vendorPromotion.promotionId) {

            props.specificAdminPromotion(vendorPromotion.promotionId._id, props.loader);

            promotionFormik.setValues({
                promotionName: vendorPromotion.promotionName,
                discount: vendorPromotion.discount,
                dealName: vendorPromotion.promotionId.dealName,
                type: vendorPromotion.type,
                stayDates: [new Date(vendorPromotion.stayStartDate), new Date(vendorPromotion.stayEndDate)],
            });
        }
        else if (!editPromotion && promotion) {
            let discount = 0;
            if (promotion.dealName === PORTFOLIO_DEALS_TYPE.MONTHLY) discount = 10;
            else if (promotion.dealName === PORTFOLIO_DEALS_TYPE.WEEKLY) discount = 5;
            else if (promotion.type === 1) discount = 1;

            promotionFormik.setValues({
                discount: discount,
                stayDates: [],
                promotionName: '',
                dealName: promotion.dealName,
                type: promotion.type,
            });
        };
    }, [vendorPromotion, editPromotion, promotion]);

    return (
        <Fragment>
            <p className={style.promotionName}>{editPromotion ? 'Edit promotion' : 'Add new promotion'}: {promotion?.promotionName}</p>
            <p className={style.promotionSubHeading}>This promotion has some preselected items – review and customise the rest</p>
            {promotion?.dealName === PROPERTY_PROMOTIONS_TYPE.CAMPAIGN ? <div className="container-fluid p-0">
                <div className={`col-md-12 py-2 px-4 py-3 bg-white d-flex justify-content-between col-lg-7 ${style.promotionCard}`}>
                    <p className={style.promotionHeading}>Book Dates</p>
                    <p className="mb-0">{moment(promotion?.bookableStartDate).format('DD MMM YYYY')} - {moment(promotion?.bookableEndDate).format('DD MMM YYYY')}</p>
                </div>
            </div> : null}

            <div className="container-fluid p-0 mt-3">
                <div className={`col-md-12 bg-white col-lg-7 pb-3 px-3 py-3 ${style.promotionCard}`}>
                    <p className={style.promotionDetailHeading}>Promotion details</p>
                    <p className={style.promotionDetailSubHeading}>{[PORTFOLIO_DEALS_TYPE.MONTHLY, PORTFOLIO_DEALS_TYPE.WEEKLY].includes(promotionFormik.values.dealName) ? 'Discount available for this promotion' : 'How much of a discount do you want to give ?'}</p>
                    {promotion && promotion.type !== 3 ? <p className={style.promotionDetailInfo}>Please set a discount between {promotion && promotion.dealName === PORTFOLIO_DEALS_TYPE.LAST_MINUTE ? '1 and 10%' : '1 and 20%'}</p> : null}

                    <div className="form-group w-50">
                        <input
                            type="number"
                            name="discount"
                            className="form-control"
                            disabled={[PORTFOLIO_DEALS_TYPE.MONTHLY, PORTFOLIO_DEALS_TYPE.WEEKLY].includes(promotionFormik.values.dealName)}
                            value={promotionFormik.values.discount}
                            onChange={promotionFormik.handleChange}
                            onBlur={promotionFormik.handleBlur}
                            onWheel={event => event.currentTarget.blur()}
                        />
                    </div>
                    {promotionFormik.touched.discount && promotionFormik.errors.discount ? <p className="error mb-0 mt-1">{promotionFormik.errors.discount}</p> : null}
                </div>
            </div>
            <div className="container-fluid p-0 mt-3">
                <div className={`col-md-12 bg-white col-lg-7 pb-3 px-3 py-3 ${style.promotionCard}`}>
                    <p className={style.promotionDetailHeading}>Stay dates</p>
                    <p className={style.promotionDetailSubHeading}>When can guests stay using this promotion ?</p>
                    <Calendar
                        onChange={(selectedDate) => {
                            let [startDate, endDate] = selectedDate;

                            let tempDates = [new Date(startDate), new Date(endDate)];

                            promotionFormik.setFieldValue('stayDates', tempDates);
                        }}
                        tileClassName={({ date }) => isDateSelected(date) ? style.selectedDate : null}
                        value={promotionFormik.values.stayDates}
                        selectRange={true}
                        name="stayDates"
                        tileDisabled={({ date }) => {
                            const currentDate = moment();
                            const specifiedDate = moment(date.getTime());

                            if (specifiedDate.isBefore(currentDate)) {
                                return true;
                            }

                            const status = selectedStayDates.findIndex(item => (item.date === date.getDate() && item.month === date.getMonth() && item.year === date.getFullYear()));
                            return status < 0;
                        }}
                        className="mt-2"
                    />
                    {promotionFormik.touched.stayDates && promotionFormik.errors.stayDates ? <p className="error mb-0 mt-1">{promotionFormik.errors.stayDates}</p> : null}
                </div>
            </div>
            <div className="container-fluid p-0 mt-3">
                <div className={`col-md-12 bg-white col-lg-7 pb-3 px-3 py-3 ${style.promotionCard}`}>
                    <p className={style.promotionDetailHeading}>Promotion name</p>
                    <p className={style.promotionDetailSubHeading}>What do you want to name this promotion ?</p>
                    <p className={style.promotionDetailInfo}>This name is just for you. We won’t show it to customers</p>
                    <div className="form-group w-50">
                        <input
                            type="text"
                            name="promotionName"
                            className="form-control"
                            value={promotionFormik.values.promotionName}
                            onChange={promotionFormik.handleChange}
                            onBlur={promotionFormik.handleBlur}
                        />
                    </div>

                    {promotionFormik.touched.promotionName && promotionFormik.errors.promotionName ? <p className="error mb-0 mt-1">{promotionFormik.errors.promotionName}</p> : null}
                </div>
            </div>
            <div className="container-fluid p-0 mt-3">
                <Button type="button" handleClick={promotionFormik.handleSubmit} title={editPromotion ? 'Update' : 'Create'} />
            </div>
        </Fragment>
    );
};

const mapDispatchToProps = (dispatch) => ({
    specificAdminPromotion: (promotionId, loader) => dispatch(AdminPromotionAction.specific(promotionId, loader)),
    specificVendorPromotion: (promotionId, loader) => dispatch(VendorPromotionAction.specific(promotionId, loader)),
    createPromotion: (payload, loader, navigate) => dispatch(VendorPromotionAction.create(payload, loader, navigate)),
    updatePromotion: (payload, loader, navigate) => dispatch(VendorPromotionAction.update(payload, loader, navigate)),
    specificProperty: (query, loader) => dispatch(CommonAction.specificProperty(query, loader)),
});

export default connect(null, mapDispatchToProps)(AddPropertyPromotion);