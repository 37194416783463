import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Switch from "react-switch";
import Swal from "sweetalert2";
import { connect, useSelector } from "react-redux";

import Pagination from "../../../components/Universal/Pagination";
import { APP_ROUTES, SORTING_FIELDS } from "../../../constants";
import { AdminCategoryAction } from "../../../store/admin/thunk";
import { SortIcon } from "../../../assets/images";

const Categories = ({ loader, list, update }) => {
  const { categories: { categories, count } } = useSelector((state) => state.admin);

  const [filterType, setFilterType] = useState("PROPERTY");
  const [pagination, setPagination] = useState({ offset: 0, limit: 10 });
  const [sorting, setSorting] = useState({ type: null, order: null });

  const Categories = async () => {
    const params = {
      type: filterType,
      ...pagination,
      showAllCategories: true,
      sort: sorting.type,
      sortType: sorting.order,
    };

    list(params, loader);
  };

  const switchHandler = async (category_id, status) => {
    Swal.fire({
      title: 'Are you sure?',
      text: `You want to ${status.toLowerCase()} this category`,
      confirmButtonText: 'Yes',
      showCancelButton: true,
    }).then(async (result) => {
      if (result.isConfirmed) {
        let payload = { category_id, status, type: filterType };

        const params = {
          type: filterType,
          ...pagination,
          showAllCategories: true,
        };

        update(payload, loader, {}, params);
      }
    });
  };

  const filter = (type) => {
    setFilterType(type);
  };

  const handleSorting = (field) => {
    if (sorting.type === field) {
      setSorting({ ...sorting, order: -1 * sorting.order });
    }
    else {
      setSorting({ type: field, order: 1 });
    };
  };

  useEffect(() => {
    Categories();
  }, [filterType, pagination, sorting]);

  return (
    <div className="vendor-listing">
      <div className="d-flex justify-content-between align-items-center">
        <ul className="paginaion">
          <li><Link to="/">Dashboard</Link></li>
          <li className="active"><Link to="/vouchers">Categories</Link></li>
        </ul>
        <Link className="cm-btn" to={APP_ROUTES.ADD_CATEGORY}>
          Add Category
        </Link>
      </div>
      <ul className="filter-tab">
        <li>
          <span
            className={filterType === "PROPERTY" ? "active" : "inactive"}
            onClick={() => filter("PROPERTY")}
          >
            Property
          </span>
        </li>
        <li>
          <span
            className={filterType === "CAR" ? "active" : "inactive"}
            onClick={() => filter("CAR")}
          >
            Cars
          </span>
        </li>
        <li>
          <span
            className={filterType === "TOUR" ? "active" : "inactive"}
            onClick={() => filter("TOUR")}
          >
            Tour
          </span>
        </li>
      </ul>
      <div className="listing-table">
        <div className="listing-table-scrl">
          <table cellPadding={0} cellSpacing={0}>
            <tr>
              <th></th>
              <th className="cursor-pointer" onClick={() => handleSorting(SORTING_FIELDS.NAME)}>
                <span className="me-1">Name</span>
                {sorting.type !== SORTING_FIELDS.NAME ? <img src={SortIcon} width={15} height={15} /> : null}
                {sorting.type === SORTING_FIELDS.NAME ? sorting.order === 1 ? <i className="fa fa-angle-up" /> : <i className="fa fa-angle-down" /> : null}
              </th>
              {filterType === "TOUR" ? null : <th className="cursor-pointer" onClick={() => handleSorting(SORTING_FIELDS.VAT)}>
                <span className="me-1">Total commision</span>
                {sorting.type !== SORTING_FIELDS.VAT ? <img src={SortIcon} width={15} height={15} /> : null}
                {sorting.type === SORTING_FIELDS.VAT ? sorting.order === 1 ? <i className="fa fa-angle-up" /> : <i className="fa fa-angle-down" /> : null}
              </th>}

              <th className="cursor-pointer" onClick={() => handleSorting(SORTING_FIELDS.SUB_CATEGORIES_COUNT)}>
                <span className="me-1">No. of sub-categories</span>
                {sorting.type !== SORTING_FIELDS.SUB_CATEGORIES_COUNT ? <img src={SortIcon} width={15} height={15} /> : null}
                {sorting.type === SORTING_FIELDS.SUB_CATEGORIES_COUNT ? sorting.order === 1 ? <i className="fa fa-angle-up" /> : <i className="fa fa-angle-down" /> : null}
              </th>
              <th>Status</th>
              <th>Actions</th>
            </tr>

            {categories.map((category, index) => (
              <tr key={category._id}>
                <td>{++index}</td>
                <td>{category.name}</td>
                {filterType === "TOUR" ? null : <td>{category.vat} %</td>}
                <td>{category.sub_categories_count}</td>
                <td>
                  <Switch
                    onColor="#e89532"
                    onChange={() =>
                      switchHandler(
                        category._id,
                        category.status === "ACTIVE" ? "INACTIVE" : "ACTIVE"
                      )
                    }
                    checked={category.status === "ACTIVE" ? true : false}
                  />
                </td>
                <td>
                  <Link state={{ category }} to={APP_ROUTES.SUB_CATEGORY_LIST}>
                    <i className="fas fa-eye"></i>
                  </Link>
                </td>
              </tr>
            ))}
          </table>
        </div>
      </div>
      <Pagination count={count} setPagination={setPagination} />
    </div>
  );
};

const mapDispatchToProps = (dispatch) => ({
  list: (payload, loader) => dispatch(AdminCategoryAction.list(payload, loader)),
  update: (payload, loader, navigate, params) => dispatch(AdminCategoryAction.update(payload, loader, navigate, params)),
});

export default connect(null, mapDispatchToProps)(Categories);
