import React, { useEffect, useState } from "react";
import LoadingBar from "react-top-loading-bar";

const Loader = ({ progress }) => {
  const [Progress, setProgress] = useState(0);

  useEffect(() => {
    setProgress(progress);
  }, [progress]);

  return (
    <div>
      <LoadingBar
        color="#e89532"
        height={3}
        progress={Progress}
        onLoaderFinished={() => setProgress(0)}
      />
    </div>
  );
};

export default Loader;
