import ADMIN_ACTIONS from "../actions";

const initialState = {
  categories: [],
  count: 0,
};

const adminCategoryReducer = (state = initialState, action) => {
  switch (action.type) {
    case ADMIN_ACTIONS.CATEGORIES_LIST_SUCCESS:
      return {
        categories: action.categories,
        count: action.count
      };

    default:
      return state;
  }
};

export default adminCategoryReducer;