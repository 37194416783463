import VENDOR_ACTIONS from "../actions";

const initialState = {
   promotions: [],
   vendorPromotion: {},
};

const vendorPromotionReducer = (state = initialState, action) =>  {
    if(action.type === VENDOR_ACTIONS.SET_PROPERTY_PROMOTIONS) {
        return {
            ...state,
            promotions: action.promotions,
        };
    }
    else if(action.type === VENDOR_ACTIONS.SET_SPECIFIC_PROMOTION) {
        return {
            ...state,
            vendorPromotion: action.promotion,
        };
    }
    return state;
};

export default vendorPromotionReducer;