import { useFormik } from "formik";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import DatePicker from "react-datepicker";
import { connect, useDispatch, useSelector } from "react-redux";
import moment from 'moment';

import { AddCompaignDealsSchema, AddPortfolioDealsSchema } from "../../../../schemas";
import { AdminPromotionAction } from "../../../../store/admin/thunk";
import ADMIN_ACTIONS from "../../../../store/admin/actions";
import { APP_ROUTES, MESSAGES, TOAST_TYPE } from "../../../../constants";
import Button from "../../../../components/Button";
import styles from './style.module.css';
import { PROPERTY_PROMOTIONS_TYPE, PORTFOLIO_DEALS_TYPE } from "../../../../constants/promotions";
import { Toaster } from "../../../../components";

const AddEditPromotion = (props) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const params = useParams();

    const [editPromotion, setEdit] = useState(false);

    const { promotions: { createPromotion, promotion } } = useSelector((state) => state.admin);
    const { timezone } = useSelector((state) => state.common);

    const addPromotionFormik = useFormik({
        initialValues: {
            promotionName: '',
            discount: 0,
            description: '',
            bookableStartDate: '',
            bookableEndDate: '',
            stayStartDate: '',
            stayEndDate: '',
            dealName: '',
        },
        enableReinitialize: true,
        validationSchema: !editPromotion || (editPromotion && promotion && promotion.type === PROPERTY_PROMOTIONS_TYPE.CAMPAIGN) ? AddCompaignDealsSchema : AddPortfolioDealsSchema,
        onSubmit: (values) => {
            if (!timezone) {
                Toaster(TOAST_TYPE.ERROR, MESSAGES.ENABLE_BROWSER_LOCATION);
                return;
            }

            if (!editPromotion) {
                const payload = {
                    promotionName: values.promotionName,
                    discount: values.discount,
                    description: values.description,
                    bookableStartDate: moment(values.bookableStartDate).format('YYYY-MM-DD'),
                    bookableEndDate: moment(values.bookableEndDate).format('YYYY-MM-DD'),
                    stayStartDate: moment(values.stayStartDate).format('YYYY-MM-DD'),
                    stayEndDate: moment(values.stayEndDate).format('YYYY-MM-DD'),
                };
                props.create(payload, props.loader);
            }
            else {
                let payload = {};

                values.promotionId = params.id;
                if (promotion.type === PROPERTY_PROMOTIONS_TYPE.CAMPAIGN) {
                    payload = {
                        promotionName: values.promotionName,
                        discount: values.discount,
                        description: values.description,
                        bookableStartDate: moment(values.bookableStartDate).format('YYYY-MM-DD'),
                        bookableEndDate: moment(values.bookableEndDate).format('YYYY-MM-DD'),
                        stayStartDate: moment(values.stayStartDate).format('YYYY-MM-DD'),
                        stayEndDate: moment(values.stayEndDate).format('YYYY-MM-DD'),
                        promotionId: promotion._id,
                    };
                }
                else {
                    payload = {
                        promotionName: values.promotionName,
                        discount: values.discount,
                        description: values.description,
                        promotionId: values.promotionId,
                    };
                };
                props.update(payload, props.loader, navigate);
            };
        },
    });

    useEffect(() => {
        if (params.id) {
            props.specific(params.id, props.loader);
            setEdit(true);
        }
        dispatch({ type: ADMIN_ACTIONS.CREATE_PROMOTIONS_SUCCESS, status: false });
    }, []);

    useEffect(() => {
        if (createPromotion) {
            navigate(APP_ROUTES.PROMOTIONS);
        }


    }, [createPromotion]);

    useEffect(() => {
        if (promotion) {
            addPromotionFormik.setFieldValue('promotionName', promotion.promotionName);
            addPromotionFormik.setFieldValue('discount', promotion.discount);
            addPromotionFormik.setFieldValue('description', promotion.description);

            if (promotion.type === PROPERTY_PROMOTIONS_TYPE.CAMPAIGN) {
                addPromotionFormik.setFieldValue('bookableStartDate', new Date(promotion.bookableStartDate));
                addPromotionFormik.setFieldValue('bookableEndDate', new Date(promotion.bookableEndDate));
            }

            addPromotionFormik.setFieldValue('stayStartDate', new Date(promotion.stayStartDate));
            addPromotionFormik.setFieldValue('stayEndDate', new Date(promotion.stayEndDate));
            addPromotionFormik.setFieldValue('dealName', promotion.dealName);
        }
    }, [promotion])

    return (
        <div className={`container bg-white p-4 ${styles.addPromotionCard}`}>
            <p className={styles.heading}>{editPromotion ? 'Edit' : 'Add'} Promotion</p>
            <div className="row">
                <form className="promo-form">
                    <div className="col-md-12">
                        <div className="form-group">
                            <label className="w-100">Name</label>
                            <input
                                type="text"
                                name="promotionName"
                                onChange={addPromotionFormik.handleChange}
                                onBlur={addPromotionFormik.handleBlur}
                                value={addPromotionFormik.values.promotionName}
                            />
                            {addPromotionFormik.touched.promotionName && addPromotionFormik.errors.promotionName ? <p className="error">{addPromotionFormik.errors.promotionName}</p> : null}
                        </div>
                    </div>
                    <div className="col-md-12">
                        <div className="form-group">
                            <label className="w-100">Description</label>
                            <textarea
                                name="description"
                                onChange={addPromotionFormik.handleChange}
                                onBlur={addPromotionFormik.handleBlur}
                                value={addPromotionFormik.values.description}
                            />
                            {addPromotionFormik.touched.description && addPromotionFormik.errors.description ? <p className="error">{addPromotionFormik.errors.description}</p> : null}
                        </div>
                    </div>
                    {!editPromotion || (promotion && ![PORTFOLIO_DEALS_TYPE.MONTHLY, PORTFOLIO_DEALS_TYPE.WEEKLY].includes(promotion.dealName)) ?
                        <div className="col-md-12">
                            <div className="form-group">
                                <label className="w-100">Discount (%)</label>
                                <p>How much of a discount do you want to give?</p>
                                <input
                                    type="number"
                                    name="discount"
                                    onChange={addPromotionFormik.handleChange}
                                    value={addPromotionFormik.values.discount}
                                />
                                {addPromotionFormik.touched.discount && addPromotionFormik.errors.discount ? <p className="error">{addPromotionFormik.errors.discount}</p> : null}
                            </div>
                        </div> : null}
                    <div className="row">


                        <div className="col-md-3">
                            {!editPromotion || (editPromotion && promotion && promotion.type === PROPERTY_PROMOTIONS_TYPE.CAMPAIGN) ?
                                <div className="form-group">
                                    <label className="w-100">Bookable Start Date</label>
                                    <DatePicker
                                        name="bookableStartDate"
                                        onChange={(date) => addPromotionFormik.setFieldValue('bookableStartDate', new Date(date))}
                                        selected={addPromotionFormik.values.bookableStartDate}
                                        minDate={timezone ? moment().tz(timezone).toDate() : moment().toDate()}
                                        dateFormat="dd-MMM-yyyy"
                                    />
                                    {addPromotionFormik.touched.bookableStartDate && addPromotionFormik.errors.bookableStartDate ? <p className="error">{addPromotionFormik.errors.bookableStartDate}</p> : null}
                                </div>
                                :
                                null
                            }

                        </div>
                        <div className="col-md-3">

                            {!editPromotion || (editPromotion && promotion && promotion.type === PROPERTY_PROMOTIONS_TYPE.CAMPAIGN) ?
                                <div className="form-group">
                                    <label className="w-100">Bookable End Date</label>
                                    <DatePicker
                                        name="bookableEndDate"
                                        onChange={(date) => addPromotionFormik.setFieldValue('bookableEndDate', new Date(date))}
                                        selected={addPromotionFormik.values.bookableEndDate}
                                        minDate={timezone ? moment().tz(timezone).toDate() : moment().toDate()}
                                        dateFormat="dd-MMM-yyyy"
                                    />
                                    {addPromotionFormik.touched.bookableEndDate && addPromotionFormik.errors.bookableEndDate ? <p className="error">{addPromotionFormik.errors.bookableEndDate}</p> : null}
                                </div>
                                :
                                null
                            }
                        </div>
                        <div className="col-md-3">
                            {!editPromotion || (editPromotion && promotion && promotion.type === PROPERTY_PROMOTIONS_TYPE.CAMPAIGN) ?
                                <div className="form-group">
                                    <label className="w-100">Stay Start Date</label>
                                    <DatePicker
                                        name="stayStartDate"
                                        onChange={(date) => addPromotionFormik.setFieldValue('stayStartDate', new Date(date))}
                                        selected={addPromotionFormik.values.stayStartDate}
                                        minDate={timezone ? moment().tz(timezone).toDate() : moment().toDate()}
                                        dateFormat="dd-MMM-yyyy"
                                    />
                                    {addPromotionFormik.touched.stayStartDate && addPromotionFormik.errors.stayStartDate ? <p className="error">{addPromotionFormik.errors.stayStartDate}</p> : null}
                                </div>
                                :
                                null
                            }
                        </div>
                        <div className="col-md-3">
                            {!editPromotion || (editPromotion && promotion && promotion.type === PROPERTY_PROMOTIONS_TYPE.CAMPAIGN) ?
                                <div className="form-group">
                                    <label className="w-100">Stay End Date</label>
                                    <DatePicker
                                        name="stayEndDate"
                                        onChange={(date) => addPromotionFormik.setFieldValue('stayEndDate', new Date(date))}
                                        selected={addPromotionFormik.values.stayEndDate}
                                        minDate={timezone ? moment().tz(timezone).toDate() : moment().toDate()}
                                        dateFormat="dd-MMM-yyyy"
                                    />
                                    {addPromotionFormik.touched.stayEndDate && addPromotionFormik.errors.stayEndDate ? <p className="error">{addPromotionFormik.errors.stayEndDate}</p> : null}
                                </div>
                                :
                                null
                            }
                        </div>
                    </div>

                    {/* {editPromotion && promotion && promotion.dealName === PORTFOLIO_DEALS_TYPE.LAST_MINUTE ?
                        <div className="col-md-12">
                            <label className="w-100">No of hours to book in advance</label>
                            <input
                                type="number"
                                name="advanceBookingDaysInSeconds"
                                onChange={addPromotionFormik.handleChange}
                                value={addPromotionFormik.values.advanceBookingDaysInSeconds}
                            />
                            {addPromotionFormik.touched.advanceBookingDaysInSeconds && addPromotionFormik.errors.advanceBookingDaysInSeconds ? <p>{addPromotionFormik.errors.advanceBookingDaysInSeconds}</p> : null}
                        </div> : null} */}
                    <Button type="button" handleClick={addPromotionFormik.submitForm} title={editPromotion ? 'Update' : 'Create'} />

                </form>
            </div>
        </div>
    );
};

const mapDispatchToProps = (dispatch) => ({
    create: (payload, loader) => dispatch(AdminPromotionAction.create(payload, loader)),
    update: (payload, loader, navigate) => dispatch(AdminPromotionAction.update(payload, loader, navigate)),
    specific: (promotionId, loader) => dispatch(AdminPromotionAction.specific(promotionId, loader)),
});



export default connect(null, mapDispatchToProps)(AddEditPromotion);