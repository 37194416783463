import Axios from "../../../components/Universal/Axios";
import { Toaster } from "../../../components/Toaster";
import { TOAST_TYPE, APP_ROUTES, USER_TYPE, MESSAGES } from "../../../constants";
import ADMIN_ACTIONS from "../actions";

const list = (payload, loader, userType) => async (dispatch) => {
    try {
        const { data } = await Axios.get("/location/city", payload, loader);

        if(userType === USER_TYPE.VENDOR) {
            dispatch({ type: ADMIN_ACTIONS.CITIES_LIST_SUCCESS, cities: data, count: 0 });
        }
        else {
            dispatch({ type: ADMIN_ACTIONS.CITIES_LIST_SUCCESS, cities: data.cities, count: data.count });
        };

        
    }
    catch (err) { }
};

const update = (payload, loader, navigate) => async (dispatch) => {
    try {
        const response = await Axios.patch("/location/city", payload, loader);

        if (response.status) {
            Toaster(TOAST_TYPE.SUCCESS, MESSAGES.CITY.UPDATE_SUCCESS);

            if (payload.name || payload.image) {
                navigate(APP_ROUTES.CITIES_LIST);
            }
            else {
                const params = {
                    offset: 0,
                    limit: 10,
                    showAllCities: true,
                };

                dispatch(list(params, loader));
            };
        }
    }
    catch (err) { }
};

const create = (payload, loader, navigate) => async (dispatch) => {
    try {
        const response = await Axios.post("/location/city", payload, loader);

        if (response.status) {
            Toaster(TOAST_TYPE.SUCCESS, MESSAGES.CITY.CREATE_SUCCESS);

            if (payload.name || payload.image) {
                navigate(APP_ROUTES.CITIES_LIST);
            }
        }
    }
    catch (err) { }
};

const specific = (payload, loader, type) => async (dispatch) => {
    try {
        const response = await Axios.get("/location/details", payload, loader);

        if (type === 'CITY') {
            dispatch({ type: ADMIN_ACTIONS.SPECIFIC_CITY_SUCCESS, location: response.data });
        }
        else if (type === 'AREA') {
            dispatch({ type: ADMIN_ACTIONS.SPECIFIC_AREA_SUCCESS, location: response.data });
        }
        else {
            dispatch({ type: ADMIN_ACTIONS.SPECIFIC_BUILDING_SUCCESS, location: response.data });
        };


    }
    catch (err) { }
};

export {
    list,
    update,
    create,
    specific
};