import React, { useEffect, useState } from "react";
import Linechart from "../../../components/Linechart/Linechart";
import Linecharttwo from "../../../components/Linechart/Linecharttwo";
import Axios from "../../../components/Universal/Axios";
import RecentTable from "../../../components/Dashboard/RecentTable";
import { Link } from "react-router-dom";
import Linechartthree from "../../../components/Linechart/Linechartthree";
import { APP_ROUTES } from "../../../constants";

const VendorDashboard = ({ loader }) => {
  const [vendorDash, setvendorDash] = useState({});
  const vendorDashboard = async () => {
    try {
      const { status, data } = await Axios.get("/vendor/dashboard", {}, loader);
      if (status) setvendorDash(data);
    } catch (error) {
      console.error("ERROR-->", error);
    }
  };

  useEffect(() => {
    vendorDashboard();
  }, []);

  return (
    <>
     <div className="proerty-tabs">
        <div className="row">
          <div className="col-md-6">
            <Link to={APP_ROUTES.VENDOR_REVENUE_LIST}>          
        
            <div className="inner-tabs card-shadow">
              <h5>Total Revenue</h5>
              <h4>{vendorDash.total_revenue}</h4>
              <span>
                <i className="fas fa-coins"></i>
              </span>
            </div>
            </Link>
          </div>
          <div className="col-md-6">
          <Link to={APP_ROUTES.VENDOR_DAILY_REVENUE}>
            <div className="inner-tabs card-shadow">
              <h5>Daily Revenue</h5>
              <h4>{vendorDash.daily_revenue}</h4>
              <span>
                <i className="fas fa-chart-pie"></i>
              </span>
            </div>
            </Link>
          </div>
        </div>
      </div>
      <div className="proerty-tabs mt-4">
        <div className="row">
          <div className="col-md-4">
            <div className="inner-tabs card-shadow">
              <h5>Property Booking Requests</h5>
              <h4>{vendorDash.total_booking_requests?.property}</h4>
              <span>
                <i className="fas fa-city"></i>
              </span>
            </div>
          </div>
          <div className="col-md-4">
            <div className="inner-tabs card-shadow">
              <h5>Vehicle Booking Requests</h5>
              <h4>{vendorDash.total_booking_requests?.vehicle}</h4>
              <span>
                <i className="fas fa-car"></i>
              </span>
            </div>
          </div>
          <div className="col-md-4">
            <div className="inner-tabs card-shadow">
              <h5>Tour Booking Requests</h5>
              <h4>{vendorDash.total_booking_requests?.tours}</h4>
              <span>
              <i className="fa fa-fighter-jet"></i>
              </span>
            </div>
          </div>
        </div>
      </div>

      <div className="pie-chart">
        <div className="row">
          <div className="col-md-4">
            <div className="pie-chart-left card-shadow">
              <h3>Properties</h3>
              <Linechart data={vendorDash.bookedProperties} />
            </div>
          </div>
          <div className="col-md-4">
            <div className="pie-chart-right card-shadow">
              <h3>Cars</h3>
              <Linecharttwo data={vendorDash.bookedCars} />
            </div>
          </div>
          <div className="col-md-4">
            <div className="pie-chart-right card-shadow">
              <h3>Cars</h3>
              <Linechartthree data={vendorDash.bookedCars} />
            </div>
          </div>
        </div>
      </div>

      <div className="table-chart">
        <div className="row">
          <div className="col-md-4">
            <div className="table-chart-left card-shadow">
              <RecentTable
                listing={vendorDash.recent_properties}
                name="Recent Properties"
              />
            </div>
          </div>
          <div className="col-md-4">
            <div className="table-chart-right card-shadow">
              <RecentTable
                listing={vendorDash.recent_cars}
                name="Recent Cars"
              />
            </div>
          </div>
          <div className="col-md-4">
            <div className="table-chart-right card-shadow">
              <RecentTable
                listing={vendorDash.recent_tours}
                name="Recent Tours"
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default VendorDashboard;
