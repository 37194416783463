import * as Yup from "yup";

import { PORTFOLIO_DEALS_TYPE } from "../constants/promotions";
import { MESSAGES } from "../constants";

const AddPortfolioDealsSchema = Yup.object().shape({
    promotionName: Yup.string().required(MESSAGES.VALIDATION_ERRORS.REQUIRED),
    discount: Yup
        .number().required(MESSAGES.VALIDATION_ERRORS.REQUIRED)
        .test('test-discount', function (value) {
            if ([PORTFOLIO_DEALS_TYPE.MONTHLY, PORTFOLIO_DEALS_TYPE.WEEKLY].includes(this.parent.dealName)) {
                return { path: this.path };
            }
            else if (this.parent.dealName === PORTFOLIO_DEALS_TYPE.LAST_MINUTE && (value < 1 || value > 10)) {
                return this.createError({ message: MESSAGES.VALIDATION_ERRORS.DISCOUNT_BETWEEN_1_10, path: this.path });
            }
            return { path: this.path };
        }),
    description: Yup.string().required(MESSAGES.VALIDATION_ERRORS.REQUIRED),
});

export default AddPortfolioDealsSchema;