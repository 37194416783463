import Axios from "../components/Universal/Axios";

const calculateGCDOfFile = (width, height) => {
    return height === 0 ? width : calculateGCDOfFile(height, width % height);
};

const isAspectRatio4by3 = (width, height) => {
    const divisor = calculateGCDOfFile(width, height);
    const aspectRatio = width / divisor + ":" + height / divisor;

    return aspectRatio === "4:3";
};

const processImage = (file) => {
    return new Promise((resolve) => {
        const reader = new FileReader();

        reader.onload = (e) => {

            const img = new Image();
            img.src = e.target.result;

            img.onload = () => {
                const aspectRatio = img.width / img.height;
                const isApprox4by3 = isAspectRatio4by3(img.width, img.height);

                resolve({
                    file,
                    aspectRatio,
                    is4by3: isApprox4by3,
                });
            };
        };

        reader.readAsDataURL(file);
    });
};

const uploadImage = (imagesFormData) => {
    return Axios.post('files/upload', imagesFormData);
};

export {
    calculateGCDOfFile,
    isAspectRatio4by3,
    processImage,
    uploadImage,
};