import Axios from "../../../components/Universal/Axios";
import { Toaster } from "../../../components/Toaster";
import { TOAST_TYPE } from "../../../constants";
import VENDOR_ACTIONS from "../actions";

const list = (propertyId, loader, status) => async (dispatch) => {
    try {
        const params = { propertyId, status };
        const response = await Axios.get("/promotion/property", params, loader);

        dispatch({ type: VENDOR_ACTIONS.SET_PROPERTY_PROMOTIONS, promotions: response.data });
    }
    catch (err) { }
};

const create = (payload, loader, navigate) => async (_dispatch) => {
    try {
        await Axios.post("/promotion/property", payload, loader);
        Toaster(TOAST_TYPE.SUCCESS, "Promtion added successfully");
        navigate(-1);
    }
    catch (err) {
        if(!err.status) {
            Toaster(TOAST_TYPE.ERROR, err.error);
        }
    }
};

const update = (payload, loader, navigate) => async (_dispatch) => {
    try {
        const response = await Axios.put("/promotion/property", payload, loader);

        if (response.status) {
            Toaster(TOAST_TYPE.SUCCESS, 'Promotion updated successfully');
            navigate(-1);
        }
        else {
            Toaster(TOAST_TYPE.ERROR, response.error);
        };
    }
    catch (err) {
        if(!err.status) {
            Toaster(TOAST_TYPE.ERROR, err.error);
        }
    }
};

const remove = (params, loader, propertyId) => async (dispatch) => {
    try {
        await Axios.delete("/promotion/property", { params }, loader);

        Toaster(TOAST_TYPE.SUCCESS, "Promtion remove successfully");

        dispatch(list(propertyId, loader));
    }
    catch (err) {
        if(!err.status) {
            Toaster(TOAST_TYPE.ERROR, err.error);
        }
    }
};

const specific = (params, loader) => async (dispatch) => {
    try {
        const response = await Axios.get("/promotion/promotionId", params, loader);
        dispatch({ type: VENDOR_ACTIONS.SET_SPECIFIC_PROMOTION, promotion: response.data });
    }
    catch (err) {
        if(!err.status) {
            Toaster(TOAST_TYPE.ERROR, err.error);
        }
    }
};

export {
    list,
    create,
    update,
    remove,
    specific
};