/* eslint-disable react-hooks/exhaustive-deps */
import { Fragment, useEffect, useState } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import { ErrorMessage, Field, FieldArray, FormikProvider, useFormik } from 'formik';
import MDEditor from '@uiw/react-md-editor';
import moment from 'moment';
import DatePicker from "react-datepicker";

import {
    EDIT_TOUR_TYPE,
    FILE_UPLOAD_TYPE,
    IMAGE_FILE_TYPES,
    MAXIMUM_FILE_SIZE_LIMIT,
    MINIMUM_FILE_UPLOAD_LIMIT,
    TOAST_TYPE,
    MESSAGES
} from '../../../../../constants';
import { VendorTourAction } from '../../../../../store/vendor/thunk';
import { processImage, uploadImage } from '../../../../../utils/imageHelper';
import { Button, Toaster, DropImage, MultiSelect } from '../../../../../components';
import { editTourNameSchema, editTourCategorySchema } from '../../../../../schemas/editTourSchema';
import style from '../../../../Vendor/Tour/Add/style.module.css';
import { CommonAction } from '../../../../../store/common/thunk';
import COMMON_ACTIONS from '../../../../../store/common/actions';

const EditTour = (props) => {
    const dispatch = useDispatch();

    const { images, categories, subCategories } = useSelector((state) => state.common);
    const { locations: { cities } } = useSelector((state) => state.admin);

    const [nonSelectedCategories, setNonSelectedCategories] = useState([]);
    const [isAddSubCategory, setIsAddSubCategory] = useState(null);
    const [newSubCategories, setNewSubCategories] = useState([]);

    const editNameFormik = useFormik({
        initialValues: { name: '', city_id: '' },
        enableReinitialize: true,
        validationSchema: editTourNameSchema,
        onSubmit: (values) => {
            props.update({ ...values, tourId: props.tour._id }, props.loader, props.slug);

            props.closeEditTourModal();
        },
    });

    const editDescriptionFormik = useFormik({
        initialValues: { description: '' },
        enableReinitialize: true,
        onSubmit: (values) => {
            props.update({ ...values, tourId: props.tour._id }, props.loader, props.slug);
            props.closeEditTourModal();
        },
    });

    const handleValidate = async () => {
        const errors = {};
        let fileImages = [];

        for (let i = 0; i < images.length; i++) {
            if (images[i] instanceof File) {
                const result = processImage(images[i]);
                fileImages.push(result);
            }
        }

        const processedImages = await Promise.all(fileImages);
        const invalidAspectRatio = processedImages.filter(item => item.is4by3 === false);
        const invalidFileTypes = processedImages.filter(item => !IMAGE_FILE_TYPES.includes(item.file.type));
        const invalidOldFileSize = images.filter(item => item.size > MAXIMUM_FILE_SIZE_LIMIT);
        const invalidNewFileSize = processedImages.filter(item => item.file.size > MAXIMUM_FILE_SIZE_LIMIT);

        if (images.length < MINIMUM_FILE_UPLOAD_LIMIT) {
            errors.images = MESSAGES.MINIMUM_UPLOAD_FILE_LIMIT;
        }
        // else if (invalidAspectRatio.length) {
        //     errors.images = MESSAGES.FILE_UPLOAD_ASPECT_RATIO;
        // }
        else if (invalidFileTypes.length) {
            errors.images = MESSAGES.VALID_IMAGE_FILE_TYPE;
        }
        else if (invalidNewFileSize.length || invalidOldFileSize.length) {
            errors.images = MESSAGES.UPLOAD_FILE_SIZE_LIMIT;
        }

        return errors;
    };

    const editImageFormik = useFormik({
        initialValues: { images: [] },
        validate: handleValidate,
        onSubmit: async () => {
            let uploadedImages = await Promise.all(
                images?.map((val) => {
                    if (typeof val !== 'string') {
                        const formData = new FormData();
                        formData.append("file", val);
                        formData.append("type", FILE_UPLOAD_TYPE.TOUR);

                        return uploadImage(formData);
                    }
                })
            );

            let fileError = uploadedImages.find(item => item && item.status === false && item.error === MESSAGES.LARGE_FILE_SIZE);

            if (fileError) {
                Toaster(TOAST_TYPE.ERROR, MESSAGES.UPLOAD_FILE_SIZE_LIMIT);
                return;
            }

            uploadedImages = uploadedImages.filter(item => item !== undefined).map((val) => val.data.fileUrl);
            uploadedImages = [
                ...uploadedImages,
                ...images.filter(item => typeof (item) === 'string' && item.startsWith('http')).map(val => {
                    let splitValue = val.split('/');
                    return splitValue[4];
                })
            ];
            props.update({ images: uploadedImages, tourId: props.tour._id }, props.loader, props.slug);
            props.closeEditTourModal();
        },
    });

    const editCategoryFormik = useFormik({
        initialValues: {
            subCategorySlots: [],
            newSubCategories: [],
            categoryId: '',
            subCategory: [],
            newSubCategory: [],
        },
        validationSchema: editTourCategorySchema,
        enableReinitialize: true,
        onSubmit: async (values) => {
            const updatePayload = {
                name: values.name,
                city_id: values.city,
                description: values.description,
                tourId: props.tour._id,
            };

            if (isAddSubCategory) {
                const whatToExpect = isAddSubCategory.whatToExpect.filter(item => item === '');

                let error = false;

                if (isAddSubCategory.price === 0) {
                    editCategoryFormik.setFieldError(`subCategorySlots.${isAddSubCategory.index}.price`, MESSAGES.VALIDATION_ERRORS.NON_ZERO_PRICE);
                    error = true;
                }
                else if (isAddSubCategory.price === '') {
                    editCategoryFormik.setFieldError(`subCategorySlots.${isAddSubCategory.index}.price`, MESSAGES.VALIDATION_ERRORS.REQUIRED);
                    error = true;
                }

                if (isAddSubCategory.maximumPerson === '') {
                    editCategoryFormik.setFieldError(`subCategorySlots.${isAddSubCategory.index}.maximumPerson`, MESSAGES.VALIDATION_ERRORS.REQUIRED);
                    error = true;
                }

                if (whatToExpect.length) {
                    editCategoryFormik.setFieldError(`subCategorySlots.${isAddSubCategory.index}.price`, MESSAGES.VALIDATION_ERRORS.REQUIRED);
                    error = true;
                }

                if (error) return;

                let payload = {
                    title: isAddSubCategory.title,
                    tourId: props.tour._id,
                    maximumPerson: isAddSubCategory.maximumPerson,
                    startTime: moment(isAddSubCategory.startTime).format('HH:mm'),
                    endTime: moment(isAddSubCategory.endTime).format('HH:mm'),
                    price: isAddSubCategory.price,
                    whatToExpect: isAddSubCategory.whatToExpect,
                    _id: isAddSubCategory.subCategoryId,
                    categoryId: editCategoryFormik.values.categoryId,
                    pickupAndDrop: isAddSubCategory.pickUpDrop === '1',
                };

                props.createTourSubCategory(payload, props.loader, props.slug);
                setIsAddSubCategory(null);
            }
            else props.update(updatePayload, props.loader);

            props.closeEditTourModal();
        }
    });

    const addWhatToExpect = (index) => {
        const slots = editCategoryFormik.values.subCategorySlots;
        slots[index].whatToExpect.push('');
        editCategoryFormik.setFieldValue('subCategorySlots', slots);
    };

    const removeWhatToExpect = (index, innerIndex) => {
        let slots = editCategoryFormik.values.subCategorySlots;

        slots[index].whatToExpect.splice(innerIndex, 1);
        editCategoryFormik.setFieldValue('subCategorySlots', slots);
    };

    const updateSubCategory = (values) => {
        const payload = {
            tourId: props.tour._id,
            title: values.title,
            subCategoryId: values.subCategoryId,
            whatToExpect: values.whatToExpect,
            startTime: moment(values.startTime).format('HH:mm'),
            endTime: moment(values.endTime).format('HH:mm'),
        };

        props.updateTourSubCategory(payload, props.loader, props.slug);
        props.closeEditTourModal();
    };

    const addSubCategory = (item, index) => {
        setIsAddSubCategory({ ...item, index });

        editCategoryFormik.submitForm();
    };

    const removeSubCategory = (values, index) => {
        if (editCategoryFormik.values.subCategorySlots.length === 1) {
            Toaster(TOAST_TYPE.ERROR, MESSAGES.ATLEAST_ONE_TOUR_SUB_CATEGORY);
        }
        else {
            const payload = {
                tourId: props.tour._id,
                subCategoryId: values.subCategoryId,
            };
            let tempValue = nonSelectedCategories;

            const specificCategory = props.tour.categoryData.find(item => item._id === editCategoryFormik.values.categoryId);

            const subCategory = specificCategory.subCategoryData.find(item => item._id === values.subCategoryId);

            tempValue.push({ label: subCategory.name, value: subCategory._id })
            setNonSelectedCategories(tempValue);

            const subCategorySlots = editCategoryFormik.values.subCategorySlots;
            subCategorySlots.splice(index, 1);
            props.removeTourSubCategory(payload, props.loader, props.slug);
            props.closeEditTourModal();
        };
    };

    const setInputValues = (clearValues = false) => {
        editNameFormik.setValues({
            name: props.tour.name,
            city_id: props.tour.cityData._id,
        });

        const selectedCategory = props.tour.categoryData.find(item => item.isSelected);
        const selectedSubCategory = selectedCategory.subCategoryData.filter(item => item.isSelected).map(item => ({ label: item.name, value: item._id, ...item }));
        const newSelectedSubCategory = selectedCategory.subCategoryData.filter(item => !item.isSelected).map(item => ({ label: item.name, value: item._id }));
        setNonSelectedCategories(nonSelectedCategories);

        editCategoryFormik.setFieldValue('categoryId', selectedCategory._id);
        editCategoryFormik.setFieldValue('city', props.tour.cityData._id);
        if (clearValues) {
            editCategoryFormik.setFieldValue('newSubCategory', []);
        }

        setNewSubCategories(newSelectedSubCategory);
        editCategoryFormik.setFieldValue('subCategory', selectedSubCategory);

        editDescriptionFormik.setValues({ description: props.tour.description });

        dispatch({ type: COMMON_ACTIONS.SET_IMAGES, key: 'selectedImage', value: props.tour.images });
        dispatch({ type: COMMON_ACTIONS.SET_IMAGES, key: 'images', value: props.tour.images });
    };

    const closeModal = () => {
        setInputValues(true);
        props.closeEditTourModal();
    };

    useEffect(() => {
        if (Object.keys(props.tour).length) {
            setInputValues();
        }
    }, [props.tour]);

    useEffect(() => {
        let newArr = [];

        for (let i = 0; i < editCategoryFormik.values.subCategory.length; i++) {
            const existingSubCategory = editCategoryFormik.values.subCategorySlots.find(item => item.subCategoryId === editCategoryFormik.values.subCategory[i].value);

            if (existingSubCategory) {
                newArr.push({ ...existingSubCategory, new: false, maximumPerson: '1', pickUpDrop: '1', startTime: moment().toDate(), endTime: moment().toDate() });;
            }
            else {
                newArr.push({
                    title: editCategoryFormik.values.subCategory[i].title,
                    name: editCategoryFormik.values.subCategory[i].label,
                    subCategoryId: editCategoryFormik.values.subCategory[i].value,
                    maximumPerson: '1',
                    startTime: moment(editCategoryFormik.values.subCategory[i].startTime, 'HH:mm').toDate(),
                    endTime: moment(editCategoryFormik.values.subCategory[i].endTime, 'HH:mm').toDate(),
                    price: editCategoryFormik.values.subCategory[i].price,
                    whatToExpect: editCategoryFormik.values.subCategory[i].whatToExpect,
                    pickUpDrop: editCategoryFormik.values.subCategory[i].pickupAndDrop === 'true' ? 1 : 0,
                    new: false,
                });
            };
        }

        editCategoryFormik.setFieldValue('subCategorySlots', newArr);
    }, [editCategoryFormik.values.subCategory]);

    useEffect(() => {
        const { subCategorySlots, newSubCategory } = editCategoryFormik.values;
        let newArr = subCategorySlots;


        for (let i = 0; i < newSubCategory.length; i++) {
            const existing = subCategorySlots.find(item => item.subCategoryId === newSubCategory[i].value);

            if (!existing) {
                newArr.push({
                    whatToExpect: [''],
                    pickUpDrop: '',
                    maximumPerson: '',
                    startTime: '',
                    endTime: '',
                    price: '',
                    subCategoryId: newSubCategory[i].value,
                    name: newSubCategory[i].label,
                    title: '',
                    new: true,
                });
            }
        }

        for (let i = 0; i < newArr.length; i++) {

            if (newArr[i].new) {
                const index = newSubCategory.findIndex(it => it.value === newArr[i].subCategoryId);

                if (index < 0) {
                    newArr.splice(i, 1);
                }
            }
        }

        editCategoryFormik.setFieldValue('subCategorySlots', newArr);
    }, [editCategoryFormik.values.newSubCategory]);

    useEffect(() => {
        if (subCategories.length) {
            const subCategoryList = subCategories.map(item => ({ label: item.name, value: item._id }));
            setNewSubCategories(subCategoryList);
        }
    }, [subCategories]);

    return (
        <div className={`modal fade ${props.show ? 'show d-block' : ''}`}>
            <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title">{props.tour.name}</h5>
                        <button
                            type="button"
                            className="btn-close"
                            onClick={closeModal}
                        ></button>
                    </div>
                    <div className={`modal-body ${props.type === EDIT_TOUR_TYPE.CATEGORY ? style.category : ''}`}>
                        {props.type === EDIT_TOUR_TYPE.IMAGES ?
                            <FormikProvider value={editImageFormik}>
                                <DropImage formik={editImageFormik} />
                            </FormikProvider> : props.type === EDIT_TOUR_TYPE.NAME ?
                                <Fragment>

                                    <div className="px-3 pt-2">
                                        <label className="mb-1 d-flex align-items-center">
                                            Name
                                            {editNameFormik.touched.name && editNameFormik.errors.name ? <p className="error my-0 ms-1">{editNameFormik.errors.name}</p> : null}
                                        </label>
                                        <MDEditor
                                            value={editNameFormik.values.name}
                                            onChange={(value) => editNameFormik.setFieldValue('name', value)}
                                            preview="edit"
                                            commandsFilter={(cmd) => cmd && /(fullscreen|edit|live|preview|link|code|image)/.test(cmd.name) ? false : cmd}
                                            className='h-100'
                                        />

                                    </div>
                                    <div className='px-3 pt-2'>
                                        <label>Enter City</label>
                                        <select value={editNameFormik.values.city_id} name="city_id" onChange={editNameFormik.handleChange}>
                                            <option value="">Click to select</option>
                                            {cities.map((city) => (
                                                <option key={city._id} value={city._id}>
                                                    {city.name}
                                                </option>
                                            ))}
                                        </select>
                                    </div>
                                    {editNameFormik.errors.city_id && editNameFormik.touched.city_id ? (
                                        <div className="error">{editNameFormik.errors.city_id}</div>
                                    ) : null}
                                </Fragment> : props.type === EDIT_TOUR_TYPE.DESCRIPTION ?
                                    <div className="form-group description" data-color-mode="light">
                                        <label className="mb-1 d-flex align-items-center">
                                            Enter Description
                                            {editDescriptionFormik.errors.description && editDescriptionFormik.touched.description ? (
                                                <p className="error my-0 ms-1">{editDescriptionFormik.errors.description}</p>
                                            ) : null}
                                        </label>
                                        <MDEditor
                                            value={editDescriptionFormik.values.description}
                                            onChange={(value) => editDescriptionFormik.setFieldValue('description', value)}
                                            preview="edit"
                                            commandsFilter={(cmd) => cmd && /(fullscreen|edit|live|preview|link|code|image)/.test(cmd.name) ? false : cmd}
                                            className='h-100'
                                        />

                                    </div> : props.type === EDIT_TOUR_TYPE.CATEGORY ?
                                        <FormikProvider value={editCategoryFormik}>
                                            <div className='form-group mb-2'>
                                                <label>Select category</label>
                                                <Field
                                                    onChange={(e) => {
                                                        editCategoryFormik.setFieldValue('categoryId', e.target.value);

                                                        editCategoryFormik.setFieldValue('subCategorySlots', []);
                                                        props.subCategoriesList(e.target.value, props.loader);
                                                    }}
                                                    as="select"
                                                    name="categoryId"
                                                >
                                                    <option value="">Click to select</option>
                                                    {categories.map((category) => (
                                                        <option key={category._id} value={category._id}>
                                                            {category.name}
                                                        </option>
                                                    ))}
                                                </Field>
                                            </div>
                                            <div className='form-group mb-2'>
                                                <label>Select sub category</label>
                                                <Field
                                                    name="newSubCategory"
                                                    placeholder="Select sub-categories"
                                                    isMulti={true}
                                                    component={MultiSelect}
                                                    defaultOption={editCategoryFormik.values.newSubCategory}
                                                    options={newSubCategories}
                                                />
                                            </div>
                                            <FieldArray
                                                name="subCategorySlots"
                                                render={() => (
                                                    editCategoryFormik.values.subCategorySlots.map((item, index) => (
                                                        <div key={index} className="mb-3">
                                                            <p className={style.subCategoryHeading}>{item.name}</p>

                                                            <div className="row mb-3">
                                                                <div className='col-md-12'>
                                                                    <label className='w-100'>Title</label>
                                                                    <Field
                                                                        name={`subCategorySlots.${index}.title`}
                                                                        type="text"
                                                                        placeholder="Enter title"
                                                                        value={item.title}
                                                                        className='w-100'
                                                                    />
                                                                    <ErrorMessage component="div" className="error" name={`subCategorySlots.${index}.title`} />
                                                                </div>

                                                                {item.new ? <div className="col-md-12">
                                                                    <label>Pick up/drop facility</label>
                                                                    <Field
                                                                        onChange={(e) => editCategoryFormik.setFieldValue(`subCategorySlots.${index}.pickUpDrop`, e.target.value)}
                                                                        as="select"
                                                                        name={`subCategorySlots.${index}.pickUpDrop`}
                                                                    >
                                                                        <option value="">Click to select</option>
                                                                        <option value={1}>Yes</option>
                                                                        <option value={0}>No</option>
                                                                    </Field>
                                                                    <ErrorMessage component="div" className="error" name={`subCategorySlots.${index}.pickUpDrop`} />
                                                                </div> : null}

                                                                {item.new ? <div className="col-md-12">
                                                                    <label className='w-100'>Price</label>
                                                                    <Field
                                                                        name={`subCategorySlots.${index}.price`}
                                                                        type="number"
                                                                        className='w-100'
                                                                        validate={(value) => {
                                                                            if (!/^\d*$/.test(value)) {
                                                                                return 'Please enter a whole number for the price.';
                                                                            }
                                                                        }}
                                                                        placeholder="Enter price"
                                                                    />
                                                                    <ErrorMessage component="div" className="error" name={`subCategorySlots.${index}.price`} />
                                                                </div> : null}
                                                            </div>

                                                            {item.new ? <div className="row mb-3">
                                                                <div className="col-md-6">
                                                                    <label>Start Time</label>
                                                                    <DatePicker
                                                                        name={`subCategorySlots.${index}.startTime`}
                                                                        onChange={(time) => editCategoryFormik.setFieldValue(`subCategorySlots.${index}.startTime`, time)}
                                                                        selected={editCategoryFormik.values.subCategorySlots[index].startTime}
                                                                        dateFormat="HH:mm"
                                                                        showTimeSelect
                                                                        showTimeSelectOnly
                                                                        className='w-100'
                                                                    />
                                                                    <ErrorMessage component="div" className="error" name={`subCategorySlots.${index}.startTime`} />
                                                                </div>
                                                                <div className="col-md-6">
                                                                    <label>End Time</label>
                                                                    <DatePicker
                                                                        name={`subCategorySlots.${index}.endTime`}
                                                                        onChange={(time) => editCategoryFormik.setFieldValue(`subCategorySlots.${index}.endTime`, time)}
                                                                        selected={editCategoryFormik.values.subCategorySlots[index].endTime}
                                                                        dateFormat="HH:mm"
                                                                        className='w-100'
                                                                        showTimeSelect
                                                                        showTimeSelectOnly
                                                                    />
                                                                    <ErrorMessage component="div" className="error" name={`subCategorySlots.${index}.endTime`} />
                                                                </div>
                                                            </div> : null}

                                                            {item.new ? <div className="col-md-12">
                                                                <label className='w-100'>Maximum Persons</label>
                                                                <Field
                                                                    min={1}
                                                                    name={`subCategorySlots.${index}.maximumPerson`}
                                                                    type="number"
                                                                    placeholder="Enter total number of person"
                                                                    pattern="[0-9]*"
                                                                    className='w-100'
                                                                    validate={(value) => {
                                                                        if (!/^\d*$/.test(value)) {
                                                                            return 'Please enter a whole number';
                                                                        }
                                                                    }}
                                                                />
                                                                <ErrorMessage component="div" className="error" name={`subCategorySlots.${index}.maximumPerson`} />
                                                            </div> : null}

                                                            <div className="row">
                                                                <label>What to expect</label>
                                                                {editCategoryFormik.values.subCategorySlots[index].whatToExpect.map((item, innerIndex) => (
                                                                    <Fragment key={innerIndex}>
                                                                        <div className='outer-btn-div'>
                                                                            <div className="col-md-12 mb-2 addpadding">
                                                                                <Field
                                                                                    name={`subCategorySlots.${index}.whatToExpect.${innerIndex}`}
                                                                                    type="text"
                                                                                    placeholder="What to Expect"
                                                                                    className='w-100'
                                                                                />

                                                                            </div>
                                                                            <div className="btnedits">
                                                                                {innerIndex === editCategoryFormik.values.subCategorySlots[index].whatToExpect.length - 1 ? <i className={`fa fa-plus align-self-center ${style.addSubcatgory}`} onClick={() => addWhatToExpect(index)} /> : null}
                                                                                {editCategoryFormik.values.subCategorySlots[index].whatToExpect.length !== 1 ? <i className={`fa align-self-center fa-minus ${style.removeSubCategory}`} onClick={() => removeWhatToExpect(index, innerIndex)} /> : null}
                                                                            </div>
                                                                        </div>
                                                                    </Fragment>
                                                                ))}
                                                                <ErrorMessage component="div" className="error" name={`subCategorySlots.${index}.whatToExpect`} />
                                                            </div>

                                                            {!item.new ? <button type="button" className={style.removeButton} onClick={() => removeSubCategory(item, index)}>Remove</button> : null}
                                                            {
                                                                item.new ?
                                                                    <Button type="button" title="Add" handleClick={() => addSubCategory(item, index)} /> :
                                                                    <Button type="button" title="Update" handleClick={() => updateSubCategory(item)} />}
                                                        </div>
                                                    ))
                                                )}
                                            />
                                        </FormikProvider>
                                        : <p>defrg</p>
                        }
                    </div>
                    {
                        props.type !== EDIT_TOUR_TYPE.CATEGORY ?
                            <div className="modal-footer">
                                <button
                                    type="submit"
                                    className="btn btn-primary"
                                    onClick={() => {
                                        if (props.type === EDIT_TOUR_TYPE.IMAGES) editImageFormik.submitForm();
                                        else if (props.type === EDIT_TOUR_TYPE.NAME) editNameFormik.submitForm();
                                        else if (props.type === EDIT_TOUR_TYPE.DESCRIPTION) editDescriptionFormik.submitForm();
                                    }}
                                >
                                    Update
                                </button>
                            </div>
                            :
                            null
                    }
                </div>
            </div>
        </div >
    )
};

const mapDispatchToProps = (dispatch) => ({
    update: (payload, loader, slug) => dispatch(VendorTourAction.update(payload, loader, slug)),
    subCategoriesList: (categoryId, loader) => dispatch(CommonAction.subCategoriesList(categoryId, loader)),
    updateTourSubCategory: (payload, loader, slug) => dispatch(VendorTourAction.updateTourSubCategory(payload, loader, slug)),
    createTourSubCategory: (payload, loader, slug) => dispatch(VendorTourAction.createTourSubCategory(payload, loader, slug)),
    removeTourSubCategory: (payload, loader, slug) => dispatch(VendorTourAction.removeTourSubCategory(payload, loader, slug)),
});

export default connect(null, mapDispatchToProps)(EditTour)