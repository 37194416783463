import React from "react";

const RecentTable = ({ listing, name }) => {
  return (
    <>
      <h3>{name}</h3>
      <div className="listing-table">
        <div className="listing-table-scrl">
          <table cellPadding={0} cellSpacing={0}>
            <thead>
              <tr>
                <th>Image</th>
                <th>Name</th>
                <th>Location</th>
                {/* <th>Price</th> */}
              </tr>
            </thead>
            <tbody>
              {listing?.map((val) => (
                <tr key={val._id}>
                  <td>
                    <img
                      src={
                        val.images
                      }
                      alt={val.name}
                    />
                  </td>
                  <td>{val.name}</td>
                  <td>{val.location}</td>
                  {/* <td>
                  <b>{val.price}</b>
                </td> */}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
};

export default RecentTable;
