import React from "react";
import { useNavigate } from "react-router-dom";
import { Formik, Form, Field } from "formik";
import Axios from "../Universal/Axios";
import { CategorySchema } from "../../schemas";
import { APP_ROUTES } from "../../constants";

const AddCategory = ({ loader }) => {
  const navigate = useNavigate();

  const addCategory = async ({ name, type, vat }) => {
    try {
      const { status } = await Axios.post(
        "/category",
        { name, type, vat },
        loader
      );

      if (status) navigate(APP_ROUTES.CATEGORIES_LIST);
    } catch (error) { }
  };
  
  return (
    <div className="edit-users-sc">
      <Formik
        initialValues={{ name: "", type: "", vat:"" }}
        validationSchema={CategorySchema}
        onSubmit={(values) => addCategory(values)}
      >
        {({ errors, touched }) => (
          <Form>
            <div className="form-group">
              <label>Category name</label>
              <Field name="name" placeholder="Category name" />
              {errors.name && touched.name ? (
                <div className="error">{errors.name}</div>
              ) : null}
            </div>
            <div className="form-group">
              <label>Select type</label>
              <Field as="select" name="type">
                <option value="">click to select</option>
                <option value="PROPERTY">Property</option>
                <option value="CAR">Car</option>
              </Field>
              {errors.type && touched.type ? (
                <div className="error">{errors.type}</div>
              ) : null}
            </div>
            <div className="form-group">
              <label>Vendor Commission</label>
              <Field  type="number" min="1%" max="100%" name="vat" placeholder="Vendor Commission" />
              {errors.vat && touched.vat ? (
                <div className="error">{errors.vat}</div>
              ) : null}
            </div>

           

            <button type="submit">Add</button>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default AddCategory;
