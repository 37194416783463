import VENDOR_ACTIONS from "../actions";

const initialState = {
   car: {},
};

const vendorCarReducer = (state = initialState, action) =>  {
    if(action.type === VENDOR_ACTIONS.SET_CAR_DETAILS) {
        return {
            ...state,
            car: action.car,
        };
    }
    return state;
};

export default vendorCarReducer;