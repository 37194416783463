import React from "react";
import { Field, Formik, Form } from "formik";
import { Link } from "react-router-dom";
import axios from "axios";

import { PhoneIcon } from "../../../assets/images";
import { Toaster } from "../../../components";
import { ForgotPassSchema } from '../../../schemas';
import { TOAST_TYPE } from "../../../constants";

const ForgotPass = () => {
  const forgotFunction = async ({ email }) => {
    try {
      const BASE_URL = "https://socket.rentvip.com/api/v1/",
        auth = { email },
        messages = { invalidEmail: "Email Id does not exists." };

      const [admin, vendor] = await Promise.all([
        axios.post(`${BASE_URL}admin/forgot/password`, auth),
        axios.post(`${BASE_URL}vendor/forgot/password`, auth),
      ]);

      if (
        admin.data.message === messages.invalidEmail &&
        vendor.data.message === messages.invalidEmail
      ) {
        Toaster(TOAST_TYPE.INFO, messages.invalidEmail);
        return;
      }

      if (admin.status === 200 || vendor.status === 200) {
        Toaster(
          TOAST_TYPE.SUCCESS,
          admin.status === 200 ? admin.data.message : vendor.data.message
        );
        // navigate("/reset-password");
        return;
      } else {
        Toaster(TOAST_TYPE.WARN, "Something Went Wrong");
      }
    } catch (error) {}
  };

  return (
    <div className="login-page">
      <div className="container">
        <div className="row d-flex align-items-center">
          <div className="col-md-7">
            <div className="login-page-left"></div>
          </div>
          <div className="col-md-5">
            <div className="login-page-right">
              <h5>Forgot Password</h5>
              <p>Enter your email to reset your password</p>

              <Formik
                initialValues={{ email: "" }}
                validationSchema={ForgotPassSchema}
                onSubmit={(values) => {
                  forgotFunction(values);
                }}
              >
                {({ errors, touched }) => (
                  <Form>
                    <div className="form-group">
                      <span>
                        <img src={PhoneIcon} alt="Email" />
                      </span>
                      <Field
                        name="email"
                        type="email"
                        placeholder="Email"
                      ></Field>
                      {errors.email && touched.email ? (
                        <div className="error">{errors.email}</div>
                      ) : null}
                    </div>
                    <div className="form-group"></div>
                    <div className="form-group">
                      <Link to="/login">Sign in</Link>
                    </div>
                    <div className="form-group">
                      <button type="submit">send</button>
                    </div>
                  </Form>
                )}
              </Formik>

              <div className="copy-right-lo">
                <p>Copyright 2023 by smart. All rights reserved</p>
                <ul>
                  <li>
                    <Link to="/">Privacy Policy</Link>
                  </li>
                  <li>
                    <Link to="/">Terms & Conditions</Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ForgotPass;
