import Axios from "../../../components/Universal/Axios";
import ADMIN_ACTIONS from "../actions";

const getDashboardDetails = (loader) => async (dispatch) => {
    try {
        const response = await Axios.get("/admin/dashboard", {}, loader);
        dispatch({ type: ADMIN_ACTIONS.ADMIN_DASHBOARD_SUCCESS, dashboardDetails: response.data });
    }
    catch(err) {}
};

export {
    getDashboardDetails
};