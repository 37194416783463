import React, { Fragment, useEffect, useState, useRef } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";

import { clearLocalStorage } from "../Auth/LocalStorage";
import { TopBar } from "./topBarRoutes";
import Axios from "../Universal/Axios";
import COMMON_ACTIONS from "../../store/common/actions";
import { CommonAction } from "../../store/common/thunk";
import { Bell } from "../../assets/images";

const Topbar = ({ open, pathname, notificationList }) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [openNotification, setOpenNotification] = useState(false);
    const [lastElement, setLastElement] = useState(null);
    const notificationRef = useRef();

    const { user, notifications, unreadNotificationsCount, notificationPagination } = useSelector((state) => state.common);

    const scrollObserver = useRef(
        new IntersectionObserver((entries) => {
            const first = entries[0];

            if (first.isIntersecting) {
                dispatch({ type: COMMON_ACTIONS.SET_NOTIFICATION_PAGINATION });
            }

        })
    );

    const collapse = () => {
        open();
    };

    const getNotifications = async () => {
        notificationList({ userType: user.userType, ...notificationPagination });
    };

    const checkForOutsideClick = (e) => {
        if (notificationRef.current && !notificationRef.current.contains(e.target)) {
            setOpenNotification(false);
        }
    };

    const notification = async () => {
        try {
            await Axios.put("/notification/count");

            // if (status) setCategory(data);
        } catch (error) {
            console.error("ERROR-->", error);
        }

        setOpenNotification(!openNotification);
    };

    useEffect(() => {
        collapse();

        document.addEventListener('click', checkForOutsideClick);

        return () => {
            document.removeEventListener('click', checkForOutsideClick)
        };
    }, []);

    useEffect(() => {
        const currentElement = lastElement;
        const currentObserver = scrollObserver.current;

        if (currentElement) {
            currentObserver.observe(currentElement);
        }

        return () => {
            if (currentElement) {
                currentObserver.unobserve(currentElement);
            }
        };
    }, [lastElement]);

    useEffect(() => {
        getNotifications();
    }, [notificationPagination]);

    return (
        <div id="top-bar-main" className="bg-white">
            <div className="row d-flex align-items-center">
                <div className="col-md-6">
                    <div className="left-top-bar">
                        <div className="togglr-btn">
                            <span onClick={collapse}>
                                <i className="fas fa-bars"></i>
                            </span>
                            <span>
                                <i
                                    className="fas fa-chevron-left"
                                    onClick={() => navigate(-1)}
                                ></i>
                            </span>
                        </div>

                        <h4>{TopBar[pathname] ? TopBar[pathname] : "Rental VIP"}</h4>
                    </div>
                </div>
                <div className="col-md-6">
                    <div className="right-top-bar">
                        <div className="user-profile">
                            {
                                user && user.firstName && user.lastName ?
                                    <Fragment>
                                        <span>{`${user.firstName} ${user.lastName}`}</span>
                                        <img src={user.image} alt="" />
                                    </Fragment>
                                    :
                                    null
                            }
                        </div>
                        <div className="notfication">
                            <span onClick={notification}>
                                <i className="fas fa-bell cursor-pointer"></i>
                                {unreadNotificationsCount !== 0 ? (
                                    <label>
                                        {unreadNotificationsCount}
                                    </label>
                                ) : (
                                    false
                                )}
                            </span>
                            {openNotification && (
                                <div className="notification" ref={notificationRef}>
                                    <ul>
                                        {notifications.map(
                                            (notify) => (
                                                <li
                                                    ref={setLastElement}
                                                    key={notify._id}
                                                    style={{ background: `${notify && notify.isSeen === false ? "#ececec" : "#fff"}` }}
                                                >
                                                    <span>
                                                        <img src={Bell} alt="bell" />
                                                    </span>
                                                    <div>
                                                        <h5>{notify.title}</h5>
                                                        <p>{notify.body}</p>
                                                    </div>
                                                    {notify.isNew === true ? (
                                                        <label className="dot"></label>
                                                    ) : (
                                                        false
                                                    )}
                                                </li>
                                            )
                                        )}
                                    </ul>
                                    {/* <Link
                                            onClick={notification}
                                            className="view_all"
                                            to="/notifications"
                                        >
                                            View all
                                        </Link> */}

                                </div>
                            )}
                        </div>
                        <div className="logout">
                            <Link
                                to="/login"
                                onClick={() => {
                                    clearLocalStorage(["loginDetails", "accessToken"]);
                                    dispatch({ type: COMMON_ACTIONS.USER_DETAILS_SUCCESS, user: {} })
                                }}
                            >
                                <i className="fas fa-sign-out-alt"></i> Logout
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        </div >
    );
}

const mapDispatchToProps = (dispatch) => ({
    notificationList: (query, loader) => dispatch(CommonAction.notificationsList(query, loader)),
});

export default connect(null, mapDispatchToProps)(Topbar);
