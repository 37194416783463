import { connect, useDispatch, useSelector } from "react-redux";
import { Fragment, useEffect } from "react";
import moment from 'moment';
import Swal from "sweetalert2";
import { Link, useNavigate } from "react-router-dom";

import { AdminPromotionAction } from "../../../store/admin/thunk";
import { APP_ROUTES } from "../../../constants";
import ADMIN_ACTIONS from "../../../store/admin/actions";
import styles from './style.module.css';
import Button from "../../../components/Button";
import { PROMOTIONS_TYPE_NAMES, PROPERTY_PROMOTIONS_TYPE } from "../../../constants/promotions";

const AdminPromotions = (props) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const { promotions: { promotions } } = useSelector((state) => state.admin);

    const removePromotion = (promotionId) => {
        Swal.fire({
            title: 'Are you sure?',
            text: `You want to remove this promotion`,
            confirmButtonText: 'Yes',
            showCancelButton: true,
        }).then((result) => {
            if (result.isConfirmed) {
                props.remove(promotionId, props.loader);
            }
        });
    };

    const addNewPromotion = () => navigate(APP_ROUTES.ADD_PROMOTION);

    useEffect(() => {
        props.list();
        dispatch({ type: ADMIN_ACTIONS.SET_SPECIFIC_PROMOTION, promotion: null });
    }, []);

    return (
        <div>
            {promotions.map(item => (
                <Fragment key={item._id}>
                    <div className="d-flex justify-content-between align-items-start my-3">
                        <p className={styles.promotionName}>{PROMOTIONS_TYPE_NAMES[item._id]}</p>
                        {item._id === PROPERTY_PROMOTIONS_TYPE.CAMPAIGN ? <Button title="Add" type="button" handleClick={addNewPromotion} /> : null}
                    </div>
                    <div className="listing-table mb-2">
                        <div className="listing-table-scrl">
                            <table cellPadding={0} cellSpacing={0}>
                                <thead>
                                    <tr>
                                        <th></th>
                                        <th>Name</th>
                                        <th>Discount</th>
                                        {item._id === PROPERTY_PROMOTIONS_TYPE.CAMPAIGN ? <th>Bookable Start Date</th> : null}
                                        {item._id === PROPERTY_PROMOTIONS_TYPE.CAMPAIGN ? <th>Bookable End Date</th> : null}
                                        {item._id === PROPERTY_PROMOTIONS_TYPE.CAMPAIGN ? <th>Stay Start Date</th> : null}
                                        {item._id === PROPERTY_PROMOTIONS_TYPE.CAMPAIGN ? <th>Stay End Date</th> : null}
                                        <th>Actions</th>

                                    </tr>
                                </thead>
                                <tbody>
                                    {item.data.length == 0 ? <tr><td colSpan={8} className="text-center">No Deal Found</td></tr> : null}
                                    {item.data.map((promotion, index) => (
                                        <tr key={promotion._id}>
                                            <td>{++index}</td>
                                            <td>{promotion.promotionName}</td>
                                            <td>{promotion.discount}</td>
                                            {item._id === PROPERTY_PROMOTIONS_TYPE.CAMPAIGN ? <td>{moment(promotion.bookableStartDate).format('DD-MMM-YYYY')}</td> : null}
                                            {item._id === PROPERTY_PROMOTIONS_TYPE.CAMPAIGN ? <td>{moment(promotion.bookableEndDate).format('DD-MMM-YYYY')}</td> : null}
                                            {item._id === PROPERTY_PROMOTIONS_TYPE.CAMPAIGN ? <td>{moment(promotion.stayStartDate).format('DD-MMM-YYYY')}</td> : null}
                                            {item._id === PROPERTY_PROMOTIONS_TYPE.CAMPAIGN ? <td>{moment(promotion.stayEndDate).format('DD-MMM-YYYY')}</td> : null}
                                            <td>

                                                <Link state={{ _id: item._id }} to={`/promotions/${promotion._id}`}>
                                                    <i className="fa fa-edit py-2"></i>
                                                </Link>
                                                <i onClick={() => removePromotion(promotion._id)} className="action fas fa-trash py-2"></i>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </Fragment>
            ))}
        </div>
    );
};

const mapDispatchToProps = (dispatch) => ({
    list: (loader) => dispatch(AdminPromotionAction.list(loader)),
    remove: (promotionId, loader) => dispatch(AdminPromotionAction.remove(promotionId, loader)),
})

export default connect(null, mapDispatchToProps)(AdminPromotions);