import React, { useState, useEffect } from 'react';
import { connect, useSelector } from 'react-redux';
import moment from 'moment';
import { Link } from 'react-router-dom';
import { debounce } from 'lodash';

import { AdminInvoiceAction } from '../../../store/admin/thunk';
import { APP_ROUTES, INVOICE_STATUS, MESSAGES, USER_TYPE } from '../../../constants';
import { Pagination } from '../../../components';
import { SearchIcon } from '../../../assets/images';
import styles from './style.module.css';

const ViewInvoices = ({ viewInvoices, loader, updatePayouts }) => {

    const { invoices, invoicesCount, invoiceListLoader, user } = useSelector((state) => state.common);

    const [pagination, setPagination] = useState({ offset: 0, limit: 10 });
    const [invoiceNoSearch, setInvoiceNoSearch] = useState('');

    const getPayouts = (offset = pagination.offset) => {
        const query = {
            offset,
            limit: pagination.limit,
        };

        if (invoiceNoSearch && invoiceNoSearch.length) query.invoiceNumber = invoiceNoSearch;
        viewInvoices(query, user.userType, loader);
    };


    const markPayouts = (invoiceId) => {
        const query = {
            offset: pagination.offset,
            limit: pagination.limit,
        };

        updatePayouts({ invoiceId: invoiceId }, loader, query, user.userType);
    };

    useEffect(() => {
        getPayouts();
    }, [pagination.offset]);

    const clearResult = () => {
        setInvoiceNoSearch('');
        getPayouts(0);
    };

    const debouncePayoutsList = debounce(() => getPayouts(0), 500);


    return (
        <div className="vendor-listing">
            <div className="d-flex justify-content-between align-items-center">
                <ul className="paginaion">
                    <li>
                        <Link to="/">Dashboard</Link>
                    </li>
                    <li className="active">
                        <Link to={APP_ROUTES.VIEW_INVOICES}>Invoices</Link>
                    </li>
                </ul>
            </div>
            <div className='filter-main'>
                <div className='filter-left'>

                    <div className="form-group">
                        <span className="search-c">
                            <img src={SearchIcon} alt="search" />
                        </span>
                        <input
                            type='invoiceNoSearch'
                            placeholder='Search by Invoice no'
                            onChange={(e) => setInvoiceNoSearch(e.target.value)}
                            onKeyDown={(e) => {
                                if (e.key === 'Enter') debouncePayoutsList();
                            }}
                            value={invoiceNoSearch}
                            className='me-2'
                        />
                        {(invoiceNoSearch.length) ?
                            <button className="search-button" onClick={clearResult}>
                                <i className="fa fa-eraser" />
                            </button>
                            :
                            null
                        }
                    </div>
                </div>
            </div>

            <div className="listing-table">
                <div className="listing-table-scrl">
                    <table cellPadding={0} cellSpacing={0}>
                        <thead>
                            <tr>
                                <th></th>
                                <th>Inovice No.</th>
                                {user.userType === USER_TYPE.ADMIN ? <th>Vendor Name</th> : null}
                                {user.userType === USER_TYPE.VENDOR ? <th>Status</th>: null}
                                <th>Amount</th>
                                <th>Invoiced At</th>
                                {user.userType === USER_TYPE.ADMIN && <th className='text-center'>Update Payouts</th>}
                                <th>Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            {invoiceListLoader ? <tr className="noresult"><td colSpan={user.userType === USER_TYPE.ADMIN ? 8 : 7}>{MESSAGES.LOADING}</td></tr> : null}
                            {!invoiceListLoader && invoices.length === 0 ? <tr className="noresult"><td colSpan={user.userType === USER_TYPE.ADMIN ? 8 : 7}>{MESSAGES.PAYOUTS.NO_PAYOUTS_FOUND}</td></tr> : null}
                            {!invoiceListLoader && invoices.map((item, index) => (
                                <tr key={item._id}>
                                    <td>{++index}</td>
                                    <td>{item.invoiceNumber}</td>
                                    {user.userType === USER_TYPE.VENDOR ? <td>{INVOICE_STATUS[item.status]}</td>: null}
                                    {user.userType === USER_TYPE.ADMIN ? <td>{item.vendorData && item.vendorData.firstName} {item.vendorData && item.vendorData.lastName}</td> : null}
                                    <td>{item.currency} {item.amount} </td>
                                    <td>{moment(item.createdAt).format('YYYY-MM-DD')}</td>
                                    {user.userType === USER_TYPE.ADMIN && <td className='text-center' style={{ width: '170px' }}>
                                        {item.status === 1 ? <p onClick={() => markPayouts(item._id)} className={styles.pay}>Pay</p> : <p className={styles.paid}>Paid</p>}
                                    </td>}
                                    <td>
                                        <Link to={`/payouts/${item._id}`}>
                                            <i className="fas fa-eye"></i>
                                        </Link>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>

                <Pagination count={invoicesCount} setPagination={setPagination} />
            </div>
        </div>
    );
};

const mapDispatchToProps = (dispatch) => ({
    viewInvoices: (query, userType, loader) => dispatch(AdminInvoiceAction.getInvoiceList(query, userType, loader)),
    updatePayouts: (payload, loader, query, userType) => dispatch(AdminInvoiceAction.markPayoutSuccess(payload, loader, query, userType))
});

export default connect(null, mapDispatchToProps)(ViewInvoices);