import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import Switch from "react-switch";
import Axios from "../Universal/Axios";
import { APP_ROUTES } from "../../constants";

const Category = ({ loader }) => {
  const {
    state: { category },
  } = useLocation();

  const [subCategories, setSubCategories] = useState([]);
  const getSubCategories = async () => {
    try {
      const { status, data } = await Axios.get(`/category/${category._id}`,
        
      );

      if (status) setSubCategories(data.sub_categories);
    } catch (error) { }
  };

  const switchHandler = async (subcategory_id, status) => {
    setSubCategories((prev) =>
      prev.map((ele) => {
        if (ele._id === subcategory_id) ele.status = status;
        return ele;
      })
    );

    await Axios.patch(
      "/category/subcategory",
      { subcategory_id, status },
      loader
    );
  };

  useEffect(() => {
    getSubCategories();
  }, []);

  return (
    <div className="vendor-listing">
      <h3>
        {category.name}
        <Link className="cm-btn" state={{ category }} to={APP_ROUTES.ADD_SUB_CATEGORY}>
          Add Sub-Category
        </Link>
      </h3>

      <div className="listing-table">
        <div className="listing-table-scrl">
          <table cellPadding={0} cellSpacing={0}>
            <tr>
              <th></th>
              <th>Name</th>
              <th>Total commision</th>
              <th>Status</th>
            </tr>

            {subCategories &&
              subCategories.map((subCategory, index) => (
                <tr key={subCategory._id}>
                  <td>{++index}</td>
                  <td>{subCategory.name}</td>
                  <td>{subCategory.vat} %</td>
                  <td>
                    <Switch
                      onColor="#e89532"
                      onChange={() =>
                        switchHandler(
                          subCategory._id,
                          subCategory.status === "ACTIVE"
                            ? "INACTIVE"
                            : "ACTIVE"
                        )
                      }
                      checked={subCategory.status === "ACTIVE" ? 1 : 0}
                    />
                  </td>
                </tr>
              ))}
          </table>
        </div>
      </div>
    </div>
  );
};

export default Category;
