import Axios from "../../../components/Universal/Axios";
import COMMON_ACTIONS from "../actions";

const getUserChatRooms = (loader) => async (dispatch) => {
    try {
        const { data } = await Axios.get("/socket/room", {}, loader);

        dispatch({ type: COMMON_ACTIONS.SET_USER_ROOMS, chatList: data.rooms, count: data.count });

    }
    catch (err) {
    }
};

// const getSpecificRoomMessages = (params, loader) => async (dispatch) => {
//     try {
//         const { data } = await Axios.get("/socket/messages", params, loader);

//         data.data = data.data.reverse();

//         dispatch({
//             type: COMMON_ACTIONS.SET_SPECIFIC_ROOM_MESSAGES,
//             roomMessages: data.data,
//             roomMessagesCount: data.count,
//             disableChat: data.isDisable ?? false
//         });

//     }
//     catch (err) {
//     }
// };

const getSpecificRoom = (roomId, loader) => {
    return Axios.get(`/socket/${roomId}`, {}, loader).then(res => res.data);
};

const getSpecificRoomMessages = (params, loader) => {
    return Axios.get("/socket/messages", params, loader).then(res => res.data);
};

export {
    getUserChatRooms,
    // getSpecificRoomMessages,
    getSpecificRoomMessages,
    getSpecificRoom
};