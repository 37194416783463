import * as Yup from "yup";

const SubadminSchema = Yup.object().shape({
	firstName: Yup
		.string()
		.required("Required")
		.min(2, "First name must be at least 2 characters")
		.max(50, "First name can't exceed 50 characters")
		.matches(/^[A-Za-z\s]+$/, "First name can only contain letters and spaces"),
	lastName: Yup
		.string()
		.required("Required")
		.min(2, "Last name must be at least 2 characters")
		.max(50, "Last name can't exceed 50 characters")
		.matches(/^[A-Za-z\s]+$/, "Last name can only contain letters and spaces"),
	email: Yup.string().email("Invalid email format").required("Required"),
	password: Yup
		.string()
		.required("Required")
		.min(8, "Password must be at least 8 characters long")
		.matches(
			/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[\W_])/,
			"Password must include at least one uppercase letter, one lowercase letter, one number, and one special character"
		),
});

export default SubadminSchema;