import { APP_ROUTES } from './appRoutes';
import { MESSAGES } from './messages';
import { API_URL } from './apiURL';

const USER_TYPE = {
    ADMIN: 'ADMIN',
    VENDOR: 'VENDOR',
    USER: 'USER',
};

const TOAST_TYPE = {
    INFO: "info",
    SUCCESS: "success",
    WARN: "warn",
    ERROR: "error",
};

const IMAGE_FILE_TYPES = [
    'image/jpeg',
    'image/png',
    'image/jpg',
    'image/webp'
];

const CMS_TYPE = {
    ABOUT_US: 'ABOUT_US',
    TERM_AND_CONDITION: 'TERM_AND_CONDITION',
    PRIVACY_POLICY: 'PRIVACY_POLICY'
};

const FILE_UPLOAD_TYPE = {
    MARKET_LEAD: 1,
    WEBSITE_IMAGES: 2,
    PROPERTY: 3,
    CAR: 4,
    TOUR: 5,
    CITY: 6,
    USERS_PROFILE: 7,
    USERS_DOCUMENTS: 8
};

const MAXIMUM_FILE_SIZE_LIMIT = 5072000;
const MAXIMUM_FILE_UPLOAD_LIMIT = 25;
const MINIMUM_FILE_UPLOAD_LIMIT = 3;

const ACCESS_TOKEN = "accessToken";

const CATEGORY_TYPE = {
    PROPERTY: 'PROPERTY',
    CAR: 'CAR',
    TOUR: 'TOUR',
};

const BOOKING_RESERVATIONS_TYPE = {
    ACTIVE: 'ACTIVE',
    UPCOMING: 'UPCOMING',
    PAST: 'PAST',
};

const BOOKING_MEMBER_TYPES = {
    ADULTS: 1,
    INFANTS: 2,
    CHILDREN: 3
};

const SOCKET_EVENTS = {
    MESSAGE_RECEIVED: 'messageReceived',
    SEND_MESSAGE: 'message',
    READ_MESSAGE: 'read',
    CREATE_ROOM: 'createRoom',
    NEW_ROOM: 'newRoom',
    ROOM_ALREADY_CREATED: 'roomAlreadyCreated',
    JOIN_ROOM: 'joinRoom',
};

const SORTING_FIELDS = {
    EMAIL: 'email',
    PHONE_NO: 'phoneNumber',
    FIRST_NAME: 'firstName',
    NO_OF_PROPERTIES: 'no_of_properties',
    NO_OF_CARS: 'no_of_cars',
    REFERENCE_NO: 'referenceNumber',
    REFERENCE_NO_ASC: 'referenceNumber_1',
    REFERENCE_NO_DEC: 'referenceNumber_0',
    UNIT_NUMBER_ASC: 'unitNumber_1',
    UNIT_NUMBER_DEC: 'unitNumber_0',
    NAME: 'name',
    NAME_ASC: 'name_1',
    NAME_DEC: 'name_0',
    CATEGORY_NAME: 'category_id.name',
    CATEGORY_NAME_ASC: 'categoryData.name_1',
    CATEGORY_NAME_DEC: 'categoryData.name_0',
    TOUR_CATEGORY: 'categoryData.name',
    TOUR_CATEGORY_ASC: 'categoryData.name_1',
    TOUR_CATEGORY_DEC: 'categoryData.name_0',
    PRICE: 'price',
    PRICE_ASC: 'price_1',
    PRICE_DEC: 'price_0',
    LOCATION: 'location',
    LOCATION_ASC: 'location_1',
    LOCATION_DEC: 'location_0',
    TITLE: 'title',
    DISCOUNT: 'discount',
    START_DATE: 'startDate',
    END_DATE: 'endDate',
    MINIMUM_SPEND: 'minimumSpend',
    FREQUENCY_PER_USER: 'frequencyPerUser',
    VAT: 'vat',
    SUB_CATEGORIES_COUNT: 'sub_categories_count',
    CITIES_PROPERTIES: 'properties'
};

const TIMEZONE_URL = 'https://api.wheretheiss.at/v1/coordinates';

const EDIT_CAR_TYPE = {
    NAME: 'NAME',
    DESCRIPTION: 'DESCRIPTION',
    IMAGES: 'IMAGES',
    FEATURES: 'FEATURES',
    LOCATION: 'LOCATION',
};

const EDIT_PROPERTY_TYPE = {
    AMMENITIES: 'AMMENITIES',
    DESCRIPTION: 'DESCRIPTION',
    FEATURES: 'FEATURES',
    IMAGES: 'IMAGES',
    LOCATION: 'LOCATION',
    NAME: 'NAME',
    OTHER_ROOM: 'OTHER_ROOM',
};

const EDIT_TOUR_TYPE = {
    NAME: 'NAME',
    IMAGES: 'IMAGES',
    DESCRIPTION: 'DESCRIPTION',
    CATEGORY: 'CATEGORY',
};

const BOOKING_PAYMENT_STATUS = {
    1: 'Pending',
    2: 'Success',
    3: 'Failed',
    4: 'Refund Initiated',
    5: 'Refunded',
    6: 'Refund Failed',
    7: 'Cancelled',
};

const PAYMENT_TYPE = {
    1: 'Cash',
    2: 'Card',
    3: 'Crypto'
};

const INVOICE_STATUS = {
    1: 'Pending',
    2: 'Paid'
};

export {
    MESSAGES,
    APP_ROUTES,
    USER_TYPE,
    TOAST_TYPE,
    IMAGE_FILE_TYPES,
    CMS_TYPE,
    FILE_UPLOAD_TYPE,
    MAXIMUM_FILE_SIZE_LIMIT,
    MAXIMUM_FILE_UPLOAD_LIMIT,
    MINIMUM_FILE_UPLOAD_LIMIT,
    ACCESS_TOKEN,
    CATEGORY_TYPE,
    BOOKING_RESERVATIONS_TYPE,
    BOOKING_MEMBER_TYPES,
    SOCKET_EVENTS,
    TIMEZONE_URL,
    SORTING_FIELDS,
    EDIT_CAR_TYPE,
    EDIT_PROPERTY_TYPE,
    EDIT_TOUR_TYPE,
    BOOKING_PAYMENT_STATUS,
    API_URL,
    PAYMENT_TYPE,
    INVOICE_STATUS
};
