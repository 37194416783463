
import Axios from "../../../components/Universal/Axios";
import VENDOR_ACTIONS from "../actions";
import { Toaster } from "../../../components/Toaster";
import { CommonAction } from "../../common/thunk";
import { MESSAGES, TOAST_TYPE } from "../../../constants";

const pricingList = (params, loader, resetDates) => async (dispatch) => {
    try {
        const { data: { data, promotionData } } = await Axios.get("/property/calendar/pricing", params, loader);

        dispatch({ type: VENDOR_ACTIONS.SET_PROPERTY_PRICE, pricing: data, promotions: promotionData });

        if (resetDates) {
            dispatch({ type: VENDOR_ACTIONS.SET_PRICE_DATES, selectedDates: [] });
        }
    }
    catch (err) { }
};

const updateCalendarPrice = (payload, loader, params) => async (dispatch) => {
    try {
        await Axios.put("/property/calendar/pricing", payload, loader);

        Toaster(TOAST_TYPE.SUCCESS, MESSAGES.CALENDAR.UPDATE_SUCCESS);

        params = { fromDate: params.startDate, toDate: params.endDate, propertyId: payload.propertyId };

        dispatch(pricingList(params, loader, true));
        dispatch({ type: VENDOR_ACTIONS.SET_PRICE_MODAL, status: false });
    }
    catch (err) { }
};

const remove = (params, loader, pagination) => async (dispatch) => {
    try {
        const response = await Axios.delete("property", { params }, loader);
        if (response.status) {
            Toaster(TOAST_TYPE.SUCCESS, MESSAGES.PROPERTY.REMOVE_SUCCESS);

            dispatch(CommonAction.propertyList(pagination, loader));
        }
        else {
            Toaster(TOAST_TYPE.ERROR, response.error);
        }
    }
    catch (err) { }
};

const update = (payload, loader, id) => async (dispatch) => {
    try {
        const response = await Axios.patch("property", payload, loader);
        if (response.status) {
            Toaster(TOAST_TYPE.SUCCESS, MESSAGES.PROPERTY.UPDATE_SUCCESS);
            dispatch(CommonAction.specificProperty({ id }, loader));
        }
        else {
            Toaster(TOAST_TYPE.ERROR, response.error);
        }
    }
    catch (err) { }
};

export {
    pricingList,
    remove,
    updateCalendarPrice,
    update
};