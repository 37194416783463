import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { connect, useSelector } from "react-redux";
import Swal from "sweetalert2";
import Slider from "react-slick";

import Pagination from "../../../components/Universal/Pagination";
import { APP_ROUTES, SORTING_FIELDS, USER_TYPE, CATEGORY_TYPE, MESSAGES } from "../../../constants";
import { CommonAction } from "../../../store/common/thunk";
import { AdminTourAction } from "../../../store/admin/thunk";
import { VendorTourAction } from "../../../store/vendor/thunk";
import { Button } from "../../../components";
import { DeleteIcon, FilterIcon, LocationWhiteIcon, SearchIcon, ViewIcon } from "../../../assets/images";

const Tours = ({ loader, getToursList, approveTour, removeTour, categoriesList }) => {
    const navigate = useNavigate();

    const { user, toursList, toursCount, categories, loadingTourList } = useSelector((state) => state.common);

    const [textSearch, setTextSearch] = useState('');
    const [pagination, setPagination] = useState({ offset: 0, limit: 6 });
    const [category, setCategory] = useState(null);
    const [approval, setApproval] = useState(null);
    const [sorting, setSorting] = useState({ type: null, order: null });

    const getTours = async (offset = pagination.offset, search = textSearch, categoryId = category, approvalId = approval) => {
        let query = {
            limit: pagination.limit,
            offset,
            sort: sorting.type,
            sortType: sorting.order,
        };

        if (search && search.length) query.searchString = search;
        if (categoryId) query.categoryId = categoryId;
        if (approvalId) query.isApproved = parseInt(approvalId, 10) === 1;


        getToursList(query, loader);
    };

    const handleCategoryChange = (e) => {
        setCategory(e.target.value.length ? e.target.value : null)
        setPagination({ offset: 0, limit: pagination.limit });
    };

    const handleApprovalChange = (e) => {
        setApproval(e.target.value.length ? e.target.value : null)
        setPagination({ offset: 0, limit: pagination.limit });
    };

    const clearResult = () => {
        setCategory('');
        setApproval('');
        setTextSearch('');

        getTours(0, null, null, '');
    };

    const switchHandler = async (tourId, isApproved) => {
        const query = { tourId: tourId, isApproved };

        approveTour(query, loader, pagination);
    };

    const deleteTour = async (tourId) => {
        Swal.fire({
            title: MESSAGES.REMOVE_REQUEST,
            text: MESSAGES.TOUR.REQUEST_REMOVE,
            confirmButtonText: 'Yes',
            showCancelButton: true,
        }).then(async (result) => {
            if (result.isConfirmed) {
                const query = { tourId };

                removeTour(query, loader, pagination);
            }
        });
    };

    const handleSortingChange = (e) => {
        const value = e.target.value.split('_');

        if (value.length > 1) {
            setSorting({ type: value[0], order: parseInt(value[1]) === 0 ? -1 : 1 });
        }
        else setSorting({ type: null, value: null });
    };

    const settings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 2000,
    };

    useEffect(() => {
        getTours();
    }, [pagination, sorting]);

    useEffect(() => {
        const query = { type: CATEGORY_TYPE.TOUR };

        categoriesList(query, loader);
    }, []);

    return (
        <div className="vendor-listing">
            <div className="d-flex justify-content-between mb-3 align-items-center">
                <div className="d-flex justify-content-between align-items-center">
                    <ul className="paginaion">
                        <li>
                            <Link to="/">Dashboard</Link>
                        </li>
                        <li className="active">
                            <Link to="/tours">Tours</Link>
                        </li>
                    </ul>
                </div>
            </div>
            <div className='filter-main'>
                <div className='filter-left'>
                    <select name="categories" onChange={handleApprovalChange} className="mx-1" value={approval}>
                        <option value="">Filter by Approval</option>
                        <option key="1" value="1">Approved</option>
                        <option key="0" value="0">Pending</option>
                    </select>
                    <select name="categories" onChange={handleCategoryChange} className="mx-1" value={category}>
                        <option value="">Filter by Category</option>
                        {
                            categories.map((category) => (
                                <option key={category._id} value={category._id}>
                                    {category.name}
                                </option>
                            ))
                        }
                    </select>
                    <div className="form-group">
                        <span className="filer-iic"><img src={FilterIcon} alt="" /></span>
                        <select name="sorting" className="me-1" onChange={handleSortingChange} value={`${sorting.type}_${sorting.order}`}>
                            <option value="">Sort</option>
                            <option value={SORTING_FIELDS.NAME_ASC}>Name (ASC)</option>
                            <option value={SORTING_FIELDS.NAME_DEC}>Name (DEC)</option>
                            <option value={SORTING_FIELDS.TOUR_CATEGORY_ASC}>Category (ASC)</option>
                            <option value={SORTING_FIELDS.TOUR_CATEGORY_DEC}>Category (DEC)</option>
                            <option value={SORTING_FIELDS.LOCATION_ASC}>Location (ASC)</option>
                            <option value={SORTING_FIELDS.LOCATION_DEC}>Location (DEC)</option>
                        </select>
                    </div>
                    <div className="form-group">
                        <span className="search-c">
                            <img src={SearchIcon} alt="search" />
                        </span>
                        <input
                            type='text'
                            placeholder='Search by name'
                            onChange={(e) => setTextSearch(e.target.value)}
                            onKeyDown={(e) => {
                                if (e.key === 'Enter') getTours(0);
                            }}
                            value={textSearch}
                        />
                        {(textSearch.length || category || approval) ?
                            <button className="search-button" onClick={clearResult}>
                                <i className="fa fa-eraser" />
                            </button>
                            :
                            null
                        }
                    </div>

                </div>
                {user.userType === USER_TYPE.ADMIN ? null : <Button type='button' handleClick={() => navigate(APP_ROUTES.ADD_TOUR)} title="Add" />}
            </div>
            <div className="new-tab-design">
                <div className="row">
                    {
                        loadingTourList ?
                            <div className="d-flex justify-content-center align-items-center flex-column loading-text">
                                <h5>{MESSAGES.LOADING}</h5>
                            </div>
                            :
                            null
                    }
                    {!loadingTourList && toursList.length === 0 ? <p className="text-center">{MESSAGES.TOUR.NOT_FOUND}</p> : null}
                    {!loadingTourList && toursList.map((tour) => (
                        <div className="col-md-4" key={tour._id}>
                            <div className="inner-tab-deisign">
                                <div className="top-card-had">
                                    <div className="left-slider">
                                        <Slider {...settings}>
                                            {tour.images.map((image, key) => (
                                                <div key={key}>
                                                    <img src={image} alt="property" />
                                                </div>
                                            ))}
                                        </Slider>
                                        <div className="locaton-card">
                                            <p><img src={LocationWhiteIcon} alt="location" />{tour?.categoryData?.name}</p>
                                        </div>
                                        <div className="overlay">
                                            <div className="action-btn">
                                                {user.userType === USER_TYPE.VENDOR ? (
                                                    <button className="delet-btn" onClick={() => deleteTour(tour._id)}>
                                                        <img src={DeleteIcon} alt="deleteTour" />
                                                    </button>
                                                ) :
                                                    null
                                                }
                                                <Link to={`/tour/${tour.slug}`}>
                                                    <button className="view-btn"> <img src={ViewIcon} alt="view-tourssss" /></button>
                                                </Link>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="loc-left">
                                        <h3>{tour.name}</h3>
                                        {user.userType === USER_TYPE.ADMIN &&
                                            <label className="switch">
                                                <input type="checkbox" onChange={() => switchHandler(tour._id, !tour.isApproved)} checked={tour.isApproved} />
                                                <span className="slider round" ></span>
                                            </label>
                                        }
                                    </div>
                                </div>
                                <div className="refrela">
                                    <div className="row">
                                        <div className="col-md-6">
                                            <div className="rf-left">
                                                <h6>Reference No:</h6>
                                                <span>{tour.referenceNumber}</span>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="rf-left">
                                                <h6>Category:</h6>
                                                <span>{tour.categoryData?.name || tour.category}</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
                <Pagination
                    count={toursCount}
                    setPagination={setPagination}
                    pageSize={pagination.limit}
                />
            </div>
        </div>
    );
};

const mapDispatchToProps = (dispatch) => ({
    getToursList: (query, loader) => dispatch(CommonAction.toursList(query, loader)),
    approveTour: (params, loader, pagination) => dispatch(AdminTourAction.approve(params, loader, pagination)),
    removeTour: (query, loader, pagination) => dispatch(VendorTourAction.remove(query, loader, pagination)),
    categoriesList: (query, loader) => dispatch(CommonAction.categoriesList(query, loader)),
});

export default connect(null, mapDispatchToProps)(Tours);
