import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Formik, Form, Field } from "formik";

import Axios from "../Universal/Axios";
import { FaqSchema } from "../../schemas";
import { APP_ROUTES } from "../../constants";

const UpdateFaq = ({ loader }) => {
  const [initialValues, setInitialValues] = useState({
    description: "",
    title: "",
  });

  const navigate = useNavigate();

  const {
    state: { faq },
  } = useLocation();

  useEffect(() => {
    if (faq) {
      setInitialValues({
        question: faq.question,
        answer: faq.answer,
      });
    }
  }, [faq]);

  const updateFaq = async ({ question, answer }, faq_id = faq._id) => {
    try {
      const { status } = await Axios.patch(
        "/faq",
        { faq_id, question, answer },
        loader
      );

      if (status) navigate(APP_ROUTES.FAQS);
    } catch (error) {
      console.error("API ERROR", error);
    }
  };

  return (
    <div className="edit-users-sc">
      <Formik
        initialValues={initialValues}
        enableReinitialize={true}
        validationSchema={FaqSchema}
        onSubmit={(values) => updateFaq(values)}
      >
        {({ errors, touched }) => (
          <Form>
            <div className="form-group">
              <label>Question</label>
              <Field name="question" placeholder="Enter question" />
              {errors.question && touched.question ? (
                <div className="error">{errors.question}</div>
              ) : null}
            </div>

            <div className="form-group">
              <label>Answer</label>
              <Field name="answer" placeholder="Enter answer" />
              {errors.answer && touched.answer ? (
                <div className="error">{errors.answer}</div>
              ) : null}
            </div>

            <button type="submit">Update</button>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default UpdateFaq;
