import { Toaster } from "../../../components/Toaster";
import Axios from "../../../components/Universal/Axios";
import { TOAST_TYPE } from "../../../constants";
import VENDOR_ACTIONS from "../actions";

const payoutsList = (params, loader) => async (dispatch) => {
    try {
        dispatch({ type: VENDOR_ACTIONS.REQUEST_PAYOUTS, status: true });
        const response = await Axios.get(`/payout/readyToPayoutBookings`, params, loader);

        dispatch({ type: VENDOR_ACTIONS.REQUEST_PAYOUTS, status: false });
        dispatch({ type: VENDOR_ACTIONS.SET_PAYOUTS, payouts: response.data.payouts, count: response.data.count });
    }
    catch (error) {
        dispatch({ type: VENDOR_ACTIONS.REQUEST_PAYOUTS, status: false });
    }
};

const generateInvoice = (loader, type, limit) => async (dispatch) => {
    try {
        await Axios.post(`/payout/generateInvoice`, {}, loader);
        Toaster(TOAST_TYPE.SUCCESS, 'Invoice generated successfully')

        const query = { type, limit, offset: 0 };

        dispatch(payoutsList(query));
    }
    catch (error) {
        Toaster(TOAST_TYPE.ERROR, error.error);
    }
};

export {
    payoutsList,
    generateInvoice
};