import { consectiveBookableRooms } from "../../../utils/calendar";
import VENDOR_ACTIONS from "../actions";

const initialState = {
    propertyPrices: [],
    promotions: [],
    availableBookings: [],
    selectedDates: [],
    priceLoader: true,
    priceModal: false,
};

const vendorPropertyReducer = (state = initialState, action) => {

    switch (action.type) {
        case VENDOR_ACTIONS.SET_PROPERTY_PRICE:
            const { pricing, promotions } = action;
            let monthlyData = [];

            for (const item of pricing) {
                monthlyData = [...monthlyData, ...item.monthlyData];
            }

            const bookings = consectiveBookableRooms(monthlyData);

            return {
                ...state,
                propertyPrices: pricing,
                promotions: promotions,
                availableBookings: bookings,
                priceLoader: false,
            };

        case VENDOR_ACTIONS.UPDATE_PROPERTY_PRICE:
            return {
                ...state,
                propertyPrices: action.propertyPrices,
            };

        case VENDOR_ACTIONS.SET_PRICE_MODAL:
            return {
                ...state,
                priceModal: action.status,
            };

        case VENDOR_ACTIONS.SET_PRICE_DATES:
            return {
                ...state,
                selectedDates: action.selectedDates,
            };
        default:
            return state;
    }
};

export default vendorPropertyReducer;