import Axios from "../../../components/Universal/Axios";
import { Toaster } from "../../../components/Toaster";
import { APP_ROUTES, MESSAGES } from "../../../constants";
import { TOAST_TYPE } from "../../../constants";
import ADMIN_ACTIONS from "../actions";

const list = (payload, loader) => async (dispatch) => {
    try {
        const { data } = await Axios.get("/location/area/building", payload, loader);
        dispatch({ type: ADMIN_ACTIONS.AREAS_LIST_SUCCESS, areas: data.location, count: data.count });
    }
    catch (err) { }
};

const update = (payload, loader, navigate, params) => async (dispatch) => {
    try {
        const response = await Axios.patch("/location/area", payload, loader);

        if (response.status) {
            Toaster(TOAST_TYPE.SUCCESS, MESSAGES.AREA.UPDATE_SUCCESS);

            if(payload.name || payload.image) {
                navigate(APP_ROUTES.CITIES_LIST);
            }
            else {
                dispatch(list(params, loader));
            }
        }
    }
    catch (err) { }
};

const create = (payload, loader, navigate) => async (_dispatch) => {
    try {
        const response = await Axios.post("/location/area", payload, loader);

        if (response.status) {
            Toaster(TOAST_TYPE.SUCCESS, MESSAGES.AREA.CREATE_SUCCESS);

            if(payload.name || payload.image) {
                navigate();
            }
        }
    }
    catch (err) { }
};

export {
    list,
    update,
    create
};