import Axios from "../../../components/Universal/Axios";
import { Toaster } from "../../../components/Toaster";
import { APP_ROUTES, MESSAGES } from "../../../constants";
import { TOAST_TYPE } from "../../../constants";
import ADMIN_ACTIONS from "../actions";

const list = (payload, loader) => async (dispatch) => {
    try {
        const { data } = await Axios.get("/category", payload, loader);
        dispatch({ type: ADMIN_ACTIONS.CATEGORIES_LIST_SUCCESS, categories: data.categories, count: data.count });
    }
    catch (err) { }
};

const update = (payload, loader, navigate, params) => async (dispatch) => {
    try {
        const response = await Axios.patch("/category", payload, loader);

        if (response.status) {
            Toaster(TOAST_TYPE.SUCCESS, MESSAGES.CATEGORY.UPDATE_SUCCESS);

            if(payload.name || payload.image) {
                navigate(APP_ROUTES.CATEGORIES_LIST);
            }
            else {
                dispatch(list(params, loader));
            }
        }
    }
    catch (err) { }
};

const create = (payload, loader, navigate) => async (_dispatch) => {
    try {
        const response = await Axios.post("/location/building", payload, loader);

        if (response.status) {
            Toaster(TOAST_TYPE.SUCCESS, MESSAGES.CATEGORY.CREATE_SUCCESS);

            if(payload.name || payload.image) {
                navigate();
            }
        }
        else {
            Toaster(TOAST_TYPE.ERROR, response.error);
        };
    }
    catch (err) { }
};

export {
    list,
    update,
    create
};