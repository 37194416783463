import styles from './style.module.css';

const Button = ({ title, type, handleClick }) => {
    return (
        <button
            type={type}
            className={styles.button}
            onClick={handleClick}
        >
            {title}
        </button>
    );
};

export default Button;