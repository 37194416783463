import axios from "axios";
import { asyncLocalStorage } from "../Auth/LocalStorage";
import { Toaster } from "../Toaster";
import { API_URL } from "../../constants";

const AXIOS = axios.create({ baseURL: API_URL.BASE_URL });
AXIOS.interceptors.request.use(
  async (config) => {
    const token = await asyncLocalStorage.getItem("accessToken");
    if (token) config.headers["authorization"] = token;

    const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    config.headers["timeZone"] = timeZone;
    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);

const Axios = {
  SUCCESS: async (res, loader) => {
    if (
      res.data &&
      (res.data.message === "Successfully." || res.data.statusCode === 200)
    ) {
      loader && loader(100);
      return { status: true, data: res.data.data };
    } else if (res.data.message === "Unauthorized")
      Toaster("warn", "token expired please re-login");
    else {
      Toaster("warn", "Something Went Wrong");
      return { status: false, data: "Something Went Wrong" };
    }
  },

  FAILURE:  (err) => {
    let data = { status: false };
    data.error = err.message;
    if (
      err.response &&
      err.response.data &&
      (err.response.data.success === false || err.response.data.statusCode !== 200)
    ) {
      data.error = err.response.data.message;
    };

    return data;
  },

  get: async (endPoint, query, loader = false) => {
    try {
      loader && loader(40);
      const res = await AXIOS.get(endPoint, { params: query });
      loader && loader(60);
      loader && loader(80);
      return Axios.SUCCESS(res, loader);
    } catch (error) {
      loader && loader(60);
      loader && loader(80);
      loader && loader(100);
      Axios.FAILURE(error);
    }
  },

  patch: async (endPoint, data, loader = false) => {
    try {
      loader && loader(40);
      const res = await AXIOS.patch(endPoint, data);
      loader && loader(60);
      loader && loader(80);
      return Axios.SUCCESS(res, loader);
    } catch (error) {
      loader && loader(60);
      loader && loader(80);
      loader && loader(100);
      return Axios.FAILURE(error);
    }
  },

  put: async (endPoint, data, loader = false) => {
    try {
      loader && loader(40);
      const res = await AXIOS.put(endPoint, data);
      loader && loader(60);
      loader && loader(80);
      return Axios.SUCCESS(res, loader);
    } catch (error) {
      loader && loader(60);
      loader && loader(80);
      loader && loader(100);

      return Axios.FAILURE(error);
    }
  },

  post: async (endPoint, data, loader = false) => {
    try {
      loader && loader(40);
      const res = await AXIOS.post(endPoint, data);
      loader && loader(60);
      loader && loader(80);
      return Axios.SUCCESS(res, loader);
    } catch (error) {
      loader && loader(60);
      loader && loader(80);
      loader && loader(100);
      throw Axios.FAILURE(error);
    }
  },

  delete: async (endPoint, data, loader = false) => {
    try {
      loader && loader(40);
      const res = await AXIOS.delete(endPoint, data);
      loader && loader(60);
      loader && loader(80);
      return Axios.SUCCESS(res, loader);
    } catch (error) {
      loader && loader(60);
      loader && loader(80);
      loader && loader(100);
      return Axios.FAILURE(error);
    }
  },
};

export default Axios;
