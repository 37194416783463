import * as Yup from "yup";

const UserSchema = Yup.object().shape({
	name: Yup.string().required("Required"),
	phone: Yup.string()
		.min(8, "Too Short!")
		.max(10, "Too Long!")
		.required("Required"),
	email: Yup.string().email("Invalid email").required("Required"),
});

export default UserSchema;