import React, { useEffect, useState } from "react";
import { connect, useSelector } from "react-redux";

import Pagination from "../../../../components/Universal/Pagination";
import Switch from "react-switch";
import { VendorBookingRequestAction } from "../../../../store/vendor/thunk";
import { BOOKING_REQUESTS_TYPE } from "../../../../constants/promotions";
import { USER_TYPE } from "../../../../constants";
import { Link } from "react-router-dom";


const ToursBookingRequest = ({ loader, getTourBookings, acceptPayment }) => {
    const { bookingRequests: { tourBookings, tourBookingCount } } = useSelector((state) => state.vendor);
    const { user } = useSelector((state) => state.common);

    const [pagination, setPagination] = useState({ offset: 0, limit: 10 });

    const getBookingRequests = async () => {
        const params = {
            bookingType: BOOKING_REQUESTS_TYPE.TOUR_BOOKING,
            limit: pagination.limit,
            offset: pagination.offset,
        };
        getTourBookings(params, loader);
    };
    const switchHandler = async (bookingId) => {

        const payload = {
            type: "TOUR",
            bookingId
        };

        const params = {
            bookingType: BOOKING_REQUESTS_TYPE.TOUR_BOOKING,
            limit: pagination.limit,
            offset: pagination.offset,
        };

        acceptPayment(payload, loader, params);
    };

    useEffect(() => {
        getBookingRequests();
    }, [pagination]);

    return (
        <div className="vendor-listing">
            <div className="d-flex justify-content-between align-items-center">
                    <ul className="paginaion">
                        <li><Link to="/">Dashboard</Link></li>
                        <li className="active"><Link to="/">Tour Bookings</Link></li>
                    </ul>
                </div>
            <div className="listing-table">
                <div className="listing-table-scrl">
                    <table cellPadding={0} cellSpacing={0}>
                        <thead>
                            <tr>
                                <th></th>
                                <th>Property Name</th>
                                <th>Amount</th>
                                <th>User Name</th>
                                <th>User email</th>
                                <th>Date</th>
                                <th>Payment Request</th>
                            </tr>
                        </thead>
                        <tbody>
                            {tourBookings.map((request, index) => (
                                <tr key={request._id}>
                                    <td>{pagination.offset + index + 1}</td>
                                    <td>{request.tourId?.name || "N/A"}</td>
                                    <td>{request.categoryCurrency} {request.subCategory.price}</td>
                                    <td>{request.userId?.firstName}</td>
                                    <td>{request.userId?.email}</td>
                                    <td>{request.date?.split("T")[0]} </td>
                                    {user.userType === USER_TYPE.VENDOR ? (
                                        <td>
                                            <Switch
                                                onColor="#e89532"
                                                onChange={() =>
                                                    switchHandler(request._id, !request.hasDonePayment)
                                                }
                                                checked={request.hasDonePayment}
                                                disabled={request.hasDonePayment}
                                            />
                                        </td>
                                    ) : null}
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </div>
            <Pagination
                count={tourBookingCount}
                setPagination={setPagination}
            />
        </div>
    );
};

const mapDispatchToProps = (dispatch) => ({
    getTourBookings: (query, loader) => dispatch(VendorBookingRequestAction.bookingRequests(query, loader)),
    acceptPayment: (payload, loader, params) => dispatch(VendorBookingRequestAction.acceptBooking(payload, loader, params))
});

export default connect(null, mapDispatchToProps)(ToursBookingRequest);
