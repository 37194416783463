import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { connect, useSelector } from "react-redux";
import Swal from "sweetalert2";
import { debounce } from "lodash";
import Slider from "react-slick";

import {
  APP_ROUTES,
  MESSAGES,
  SORTING_FIELDS,
  USER_TYPE,
} from "../../../constants";
import { CommonAction } from "../../../store/common/thunk";
import { AdminCarAction } from "../../../store/admin/thunk";
import { VendorCarAction } from "../../../store/vendor/thunk";
import { CATEGORY_TYPE } from "../../../constants";
import {
  FilterIcon,
  SearchIcon,
  LocationWhiteIcon,
  DeleteIcon,
  ViewIcon,
  CarEngineIcon,
  CarSpeedIcon,
  CarTransmissionIcon,
} from "../../../assets/images";
import { Button, Pagination } from "../../../components";

const Cars = ({
  loader,
  getCarsList,
  approveCar,
  removeCar,
  categoriesList,
}) => {
  const navigate = useNavigate();

  const { carsList, carsCount, user, categories, loadingCarList } = useSelector(
    (state) => state.common
  );
  console.log(carsList, "carsListcarsListcarsList");

  const [textSearch, setTextSearch] = useState("");
  const [pagination, setPagination] = useState({ offset: 0, limit: 6 });
  const [category, setCategory] = useState("");
  const [approval, setApproval] = useState("");
  const [sorting, setSorting] = useState({ type: "", order: "" });

  const getCars = async (
    offset = pagination.offset,
    search = textSearch,
    categoryId = category,
    approvalId = approval
  ) => {
    let query = {
      limit: pagination.limit,
      offset,
    };

    if (sorting.type.length) {
      query.sort = sorting.type;
      query.sortType = sorting.order;
    }

    if (search && search.length) query.searchString = search;
    if (categoryId && categoryId.length) query.categoryId = categoryId;
    if (approvalId && approvalId.length) query.isApproved = approvalId == 1;

    getCarsList(query, loader);
  };

  const switchHandler = async (car_id, isApproved) => {
    const query = { car_id, isApproved };

    approveCar(query, loader, pagination);
  };

  const deleteCar = async (carId) => {
    Swal.fire({
      title: MESSAGES.REMOVE_REQUEST,
      text: MESSAGES.CAR.REMOVE_REQUEST,
      confirmButtonText: "Yes",
      showCancelButton: true,
    }).then(async (result) => {
      if (result.isConfirmed) {
        const query = { car_id: carId };

        removeCar(query, loader, pagination);
      }
    });
  };

  const handleCategoryChange = (e) => {
    setCategory(e.target.value.length ? e.target.value : null);
    setPagination({ offset: 0, limit: pagination.limit });
  };

  const handleApprovalChange = (e) => {
    setApproval(e.target.value.length ? e.target.value : null);
    setPagination({ offset: 0, limit: pagination.limit });
  };

  const clearResult = () => {
    setCategory("");
    setApproval("");
    setTextSearch("");

    getCars(0, null, null, "");
    setSorting({ type: "", order: "" });
  };

  const debounceCarsList = debounce(() => getCars(0), 500);

  const handleSortingChange = (e) => {
    const value = e.target.value.split("_");

    if (value.length > 1) {
      setSorting({ type: value[0], order: parseInt(value[1]) === 0 ? -1 : 1 });
    } else setSorting({ type: "", value: "" });
  };

  var settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
  };

  useEffect(() => {
    getCars();
  }, [pagination, sorting]);

  useEffect(() => {
    const query = { type: CATEGORY_TYPE.CAR };

    categoriesList(query, loader);
  }, []);

  return (
    <div className="vendor-listing">
      <div className="d-flex justify-content-between mb-3 align-items-center">
        <div className="d-flex justify-content-between align-items-center">
          <ul className="paginaion">
            <li>
              <Link to="/">Dashboard</Link>
            </li>
            <li className="active">
              <Link to="/cars">Cars</Link>
            </li>
          </ul>
        </div>
      </div>
      <div className="filter-main">
        <div className="filter-left">
          <select
            name="categories"
            onChange={handleApprovalChange}
            className="mx-1"
            value={approval}
          >
            <option value="">Filter by Approval</option>
            <option key="1" value="1">
              Approved
            </option>
            <option key="0" value="0">
              Pending
            </option>
          </select>
          <select
            name="categories"
            onChange={handleCategoryChange}
            className="mx-1"
            value={category}
          >
            <option value="">Filter by Category</option>
            {categories.map((category) => (
              <option key={category._id} value={category._id}>
                {category.name}
              </option>
            ))}
          </select>
          <div className="form-group">
            <span className="filer-iic">
              <img src={FilterIcon} alt="Filter" />
            </span>
            <select
              name="sorting"
              className="me-1"
              onChange={handleSortingChange}
              value={`${sorting.type}_${sorting.order}`}
            >
              <option value="">Sort</option>
              <option value={SORTING_FIELDS.NAME_ASC}>Name (ASC)</option>
              <option value={SORTING_FIELDS.NAME_DEC}>Name (DEC)</option>
              <option value={SORTING_FIELDS.CATEGORY_NAME_ASC}>
                Category (ASC)
              </option>
              <option value={SORTING_FIELDS.CATEGORY_NAME_DEC}>
                Category (DEC)
              </option>
              <option value={SORTING_FIELDS.LOCATION_ASC}>
                Location (ASC)
              </option>
              <option value={SORTING_FIELDS.LOCATION_DEC}>
                Location (DEC)
              </option>
              <option value={SORTING_FIELDS.PRICE_ASC}>Price (ASC)</option>
              <option value={SORTING_FIELDS.PRICE_DEC}>Price (DEC)</option>
            </select>
          </div>
          <div className="form-group">
            <span className="search-c">
              <img src={SearchIcon} alt="search" />
            </span>
            <input
              type="text"
              placeholder="Search by name"
              onChange={(e) => setTextSearch(e.target.value)}
              onKeyDown={(e) => {
                if (e.key == "Enter") debounceCarsList();
              }}
              value={textSearch}
            />
            {textSearch.length || category || approval ? (
              <button className="search-button" onClick={clearResult}>
                <i className="fa fa-eraser" />
              </button>
            ) : null}
          </div>
        </div>
        {user.userType === USER_TYPE.VENDOR ? (
          <Button
            type="button"
            handleClick={() => navigate(APP_ROUTES.ADD_CAR)}
            title="Add"
          />
        ) : null}
      </div>

      <div className="new-tab-design">
        <div className="row">
          {loadingCarList ? (
            <div className="d-flex justify-content-center align-items-center flex-column loading-text">
              <h5>{MESSAGES.LOADING}</h5>
            </div>
          ) : null}
          {!loadingCarList && carsList.length == 0 ? (
            <p className="text-center">No Car Found</p>
          ) : null}
          {!loadingCarList &&
            carsList.map((car) => (
              <div className="col-md-4" key={car._id}>
                <div className="inner-tab-deisign">
                  <div className="top-card-had">
                    <div className="left-slider">
                      <Slider {...settings}>
                        {car.images.map((image, key) => (
                          <div key={key}>
                            <img src={image} alt="car" />
                          </div>
                        ))}
                      </Slider>
                      <div className="locaton-card">
                        <p>
                          <img src={LocationWhiteIcon} alt="location" />
                          {car.location}
                        </p>
                      </div>
                      <div className="overlay">
                        <div className="action-btn">
                          {user.userType === USER_TYPE.VENDOR ? (
                            <button
                              className="delet-btn"
                              onClick={() => deleteCar(car._id)}
                            >
                              <img src={DeleteIcon} alt="deleteCar" />
                            </button>
                          ) : null}
                          <Link to={`/car/${car._id}`}>
                            <button className="view-btn">
                              {" "}
                              <img src={ViewIcon} alt="view-car" />
                            </button>
                          </Link>
                        </div>
                      </div>
                    </div>
                    <div className="loc-left">
                      <h3>{car.name}</h3>
                      {user.userType === USER_TYPE.ADMIN && (
                        <label className="switch">
                          <input
                            type="checkbox"
                            onChange={() =>
                              switchHandler(car._id, !car.isApproved)
                            }
                            checked={car.isApproved}
                          />
                          <span className="slider round"></span>
                        </label>
                      )}
                    </div>
                  </div>

                  <div className="bed-rooms">
                    <ul>
                      <li>
                        <img src={CarEngineIcon} alt="engine" />
                        {car?.features?.engine} hp
                      </li>
                      <li>
                        <img src={CarSpeedIcon} alt="speed" />
                        {car?.features?.mileage}mpg
                      </li>
                      <li>
                        <img src={CarTransmissionIcon} alt="transmission" />
                        {car?.features?.transmission}
                      </li>
                    </ul>
                  </div>
                  <div className="refrela">
                    <div className="row">
                      <div className="col-md-6">
                        <div className="rf-left">
                          <h6>Reference No:</h6>
                          <span>{car.referenceNumber}</span>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="rf-left">
                          <h6>Category:</h6>
                          <span>{car?.categoryData?.name}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ))}
        </div>
        <Pagination
          count={carsCount}
          setPagination={setPagination}
          pageIndex={pagination.offset}
        />
      </div>

      <Pagination
        count={carsCount}
        setPagination={setPagination}
        pageSize={pagination.limit}
      />
    </div>
  );
};

const mapDispatchToProps = (dispatch) => ({
  getCarsList: (query, loader) =>
    dispatch(CommonAction.carsList(query, loader)),
  approveCar: (params, loader, pagination) =>
    dispatch(AdminCarAction.approve(params, loader, pagination)),
  removeCar: (query, loader, pagination) =>
    dispatch(VendorCarAction.remove(query, loader, pagination)),
  categoriesList: (query, loader) =>
    dispatch(CommonAction.categoriesList(query, loader)),
});

export default connect(null, mapDispatchToProps)(Cars);
