import * as Yup from "yup";

import { MESSAGES } from '../constants';

const CategorySchema = Yup.object().shape({
	name: Yup.string().required(MESSAGES.VALIDATION_ERRORS.REQUIRED),
	type: Yup.string().required(MESSAGES.VALIDATION_ERRORS.REQUIRED),
	vat: Yup.string().required(MESSAGES.VALIDATION_ERRORS.REQUIRED),
});

export default CategorySchema;