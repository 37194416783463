import React from "react";
import { useNavigate } from "react-router-dom";
import { Formik, Form, Field } from "formik";

import Axios from "../Universal/Axios";
import { FaqSchema } from "../../schemas";
import { APP_ROUTES } from "../../constants";

const AddFaq = ({ loader }) => {
  const navigate = useNavigate();

  const addFaq = async ({ question, answer }) => {
    try {
      const { status } = await Axios.post(
        "/faq",
        { question, answer },
        loader
      );

      if (status) navigate(APP_ROUTES.FAQS);
    } catch (error) { }
  };

  return (
    <div className="edit-users-sc">
      <Formik
        initialValues={{ question: "", answer: "" }}
        validationSchema={FaqSchema}
        onSubmit={(values) => addFaq(values)}
      >
        {({ errors, touched }) => (
          <Form>
            <div className="form-group">
              <label>Question</label>
              <Field name="question" placeholder="Enter question" />
              {errors.question && touched.question ? (
                <div className="error">{errors.question}</div>
              ) : null}
            </div>

            <div className="form-group">
              <label>Answer</label>
              <Field name="answer" placeholder="Enter answer" />
              {errors.answer && touched.answer ? (
                <div className="error">{errors.answer}</div>
              ) : null}
            </div>

            <button type="submit">Add</button>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default AddFaq;
