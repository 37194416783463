import React, { useState } from "react";
import { Field, Formik, Form } from "formik";
import { Link, useNavigate, useParams } from "react-router-dom";
import axios from "axios";

import { PhoneIcon } from "../../../assets/images";
import { Toaster } from "../../../components";
import { ResetPassSchema } from "../../../schemas";
import { APP_ROUTES, TOAST_TYPE } from "../../../constants";

const ResetPass = () => {
  const [pass, setPass] = useState("");
  const { id, type, token } = useParams();
  const navigate = useNavigate();

  const validatePassword = (password) => {
    let error = "";
    const passwordRegex = /(?=.*[0-9])/;

    if (!password) error = "*Required";
    else if (password.length < 8)
      error = "*Password must be 8 characters long.";
    else if (!passwordRegex.test(password))
      error = "*Invalid password. Must contain one number.";

    if (!error) setPass(password);

    return error;
  };

  const validateConfirmPassword = (new_password) => {
    let error = "";

    if (pass && new_password)
      if (pass !== new_password) error = "Password not matched";

    return error;
  };

  const resetPassword = async ({ password }) => {
    try {
      const BASE_URL = "https://socket.rentvip.com/api/v1/",
        data = { id, token, password },
        messages = { linkExpire: "Reset link has been expired." };

      const restPass = await axios.post(
        `${BASE_URL}/${type}/reset/password`,
        data
      );

      if (restPass.data.message.includes("Invalid")) {
        Toaster(TOAST_TYPE.WARN, "Invalid id");
        return;
      }

      if (restPass.data.message === messages.linkExpire) {
        Toaster(TOAST_TYPE.ERROR, messages.linkExpire);
        navigate(APP_ROUTES.FORGET_PASSWORD);
        return;
      }

      if (restPass.data.statusCode === 200) {
        Toaster(TOAST_TYPE.SUCCESS, restPass.data.message);
        navigate("/login");
        return;
      } else {
        Toaster(TOAST_TYPE.WARN, "Something Went Wrong");
      }
    } catch (error) { }
  };

  return (
    <div className="login-page">
      <div className="container">
        <div className="row d-flex align-items-center">
          <div className="col-md-7">
            <div className="login-page-left"></div>
          </div>
          <div className="col-md-5">
            <div className="login-page-right">
              <h5>Reset Password</h5>
              <p>Enter your new password and confirm password</p>

              <Formik
                initialValues={{ password: "", new_password: "" }}
                validationSchema={ResetPassSchema}
                onSubmit={(values) => {
                  resetPassword(values);
                }}
              >
                {({ errors, touched }) => (
                  <Form>
                    <div className="form-group">
                      <span>
                        <img src={PhoneIcon} alt="" />
                      </span>
                      <Field
                        name="password"
                        type="password"
                        placeholder="New Password"
                        validate={validatePassword}
                      ></Field>
                      {errors.password && touched.password ? (
                        <div className="error">{errors.password}</div>
                      ) : null}
                    </div>
                    <div className="form-group">
                      <span>
                        <img src={PhoneIcon} alt="" />
                      </span>
                      <Field
                        name="new_password"
                        type="password"
                        placeholder="Confirm new Password"
                        validate={validateConfirmPassword}
                      ></Field>
                      {errors.new_password && touched.new_password ? (
                        <div className="error">{errors.new_password}</div>
                      ) : null}
                    </div>
                    <div className="form-group">
                      <button type="submit">change</button>
                    </div>
                  </Form>
                )}
              </Formik>

              <div className="copy-right-lo">
                <p>Copyright 2023 by smart. All rights reserved</p>
                <ul>
                  <li>
                    <Link to="/">Privacy Policy</Link>
                  </li>
                  <li>
                    <Link to="/">Terms & Conditions</Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ResetPass;
