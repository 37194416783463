import Axios from "../../../components/Universal/Axios";
import { Toaster } from "../../../components/Toaster";
import ADMIN_ACTIONS from "../actions";
import { TOAST_TYPE } from "../../../constants";

const list = (query, loader) => async (dispatch) => {
    try {
        dispatch({ type: ADMIN_ACTIONS.REQUEST_VENDOR_LIST, status: true });

        const response = await Axios.get("/vendor/list", query, loader);
        dispatch({ type: ADMIN_ACTIONS.VENDOR_LIST_SUCCESS, vendors: response.data.vendors, count: response.data.count });
        dispatch({ type: ADMIN_ACTIONS.REQUEST_VENDOR_LIST, status: false });
    }
    catch (err) { }
};

const specific = (query, loader) => async (dispatch) => {
    try {
        const response = await Axios.get("/vendor", query, loader);
        dispatch({ type: ADMIN_ACTIONS.SPECIFIC_VENDOR_SUCCESS, vendor: response.data });
    }
    catch (err) { }
};

const update = (query, payload, loader) => async (dispatch) => {
    try {
        await Axios.patch("/vendor/admin", payload, loader);

        Toaster(TOAST_TYPE.SUCCESS, `Vendor ${payload.isBlocked ? 'blocked' : 'unblocked'} successfully`);
        dispatch(list(query, loader));
    }
    catch (err) { }
};

export {
    list,
    update,
    specific,
};