export const PROMOTIONS_TYPE_NAMES = {
    1: 'Campaign deals',
    2: 'Target Deals',
    3: 'Portfolio deals'
};

export const PROMOTIONS_TYPE_DESCRIPTIONS = {
    1: 'Get visibility in our emails, push notifications, on affiliate websites and other channels when you offer a discount.',
    2: 'Aim your discount at specific groups and become a favourite property for valuable guest segments.',
    3: 'Improve your occupancy with a range of customisable deal types to suit your needs.'
};

export const PROPERTY_PROMOTIONS_TYPE = {
    CAMPAIGN: 1,
    TARGET: 2,
    PORTFOLIO: 3
}

export const BOOKING_REQUESTS_TYPE = {
    PROPERTY_BOOKING: 'PropertyBooking',
    CAR_BOOKING: 'CarBooking',
    TOUR_BOOKING: 'TourBooking'
};

export const PORTFOLIO_DEALS_TYPE = {
    LAST_MINUTE: 'LAST-MINUTE',
    EARLY_BOOKER: 'EARLY-BOOKER',
    MONTHLY: 'MONTHLY',
    WEEKLY: 'WEEKLY'
};