const MESSAGES = {
    UPLOAD_FILE_SIZE_LIMIT: 'Please upload maximum file size of 5MB.',
    LARGE_FILE_SIZE: 'File too large',
    MINIMUM_UPLOAD_FILE_LIMIT: 'Please select atleast 3 Images',
    MAXIMUM_UPLOAD_FILE_LIMIT: 'You have reached the maximum allowed uploads of 25 Images',
    FILE_UPLOAD_ASPECT_RATIO: 'Please upload image of aspect ratio 4:3',
    VALID_IMAGE_FILE_TYPE: 'Please choose a valid image file (JPEG, PNG, JPG)',
    ENABLE_BROWSER_LOCATION: 'Please enable your location from the browser',
    TOUR_UPDATE_SUCCESS: 'Tour updated successfully',
    TOUR_SUB_CATEGORY_UPDATE_SUCCESS: 'Tour sub category updated successfully',
    TOUR_CREATE_SUCCESS: 'Tour created successfully',
    TOUR_SUB_CATEGORY_CREATE_SUCCESS: 'Tour subcatgory created successfully',
    TOUR_REMOVE_SUCCESS: 'Tour removed successfully',
    ATLEAST_ONE_TOUR_SUB_CATEGORY: 'Tour should have atleast one sub category',
    VOUCHER: {
        DELETE: {
            STARTED: 'You cannot delete this voucher as it is already started',
            ENDED: 'You cannot delete this voucher as it is already ended',
            SUCCESS: 'Voucher removed successfully',
        },
        EDIT: {
            STARTED: 'You cannot edit this voucher as it is already started',
            ENDED: 'You cannot edit this voucher as it is already ended',
            SUCCESS: 'Voucher updated successfully'
        },
        CREATE: {
            SUCCESS: 'Voucher created successfully',
        },
    },
    CAR: {
        CREATE_SUCCESS: 'Car created successfully',
        UPDATE_SUCCESS: 'Car updated successfully',
        REMOVE_SUCCESS: 'Car removed successfully',
        REMOVE_REQUEST: 'You want to remove this car'
    },
    CALENDAR: {
        UPDATE_SUCCESS: 'Calendar updated successfully'
    },
    PROPERTY: {
        REMOVE_SUCCESS: 'Property removed successfully',
        UPDATE_SUCCESS: 'Property updated successfully',
        CREATE_SUCCESS: 'Property created successfully',
        NOT_FOUND: 'No property found',
    },
    TOUR: {
        NOT_FOUND: 'No tour found',
        REQUEST_REMOVE: 'You want to remove this tour'
    },
    AREA: {
        CREATE_SUCCESS: 'Area created successfully',
        UPDATE_SUCCESS: 'Area updated successfully'
    },
    BUILDING: {
        CREATE_SUCCESS: 'Building created successfully',
        UPDATE_SUCCESS: 'Building updated successfully'
    },
    CATEGORY: {
        CREATE_SUCCESS: 'Category created successfully',
        UPDATE_SUCCESS: 'Category updated successfully'
    },
    CITY: {
        CREATE_SUCCESS: 'City created successfully',
        UPDATE_SUCCESS: 'City updated successfully'
    },
    PROMOTION: {
        CREATE_SUCCESS: 'Promotion created successfully',
        UPDATE_SUCCESS: 'Promotion updated successfully',
        REMOVE_SUCCESS: 'Promotion removed successfully'
    },
    SUB_ADMIN: {
        CREATE_SUCCESS: 'Subadmin created successfully'
    },
    PAYOUTS: {
        NO_PAYOUTS_FOUND: "You don't have any invoices ready for now.",
        PAYMENT_ACCEPTED: 'Payment accepted successfully'
    },
    LOADING: 'Loading...',
    REMOVE_REQUEST: 'Are you sure?',
    VALIDATION_ERRORS: {
        REQUIRED: 'Required',
        EMPTY_WHAT_TO_EXPECT: `You can't leave this blank.`,
        NON_ZERO_PRICE: 'Price cannot be zero',
        DISCOUNT_BETWEEN_10_20: 'Please select discount between 10-20',
        BOOKING_END_DATE_RANGE: 'Booking end date cannot be less than start date',
        BOOKING_STAY_START_DATE_RANGE: 'Stay start date must be in booking date range',
        BOOKING_STATY_END_DATE_RANGE: 'Stay end date must be in booking date range',
        NON_ZERO_DISCOUNT: 'Discount cannot be zero',
        REQUIRED_DISCOUNT_TYPE: 'Select discount type',
        NON_ZERO_AMOUNT: 'Amount cannot be zero',
        VOUCHER_END_DATE_RANGE: 'Voucher end date cannot be less than start date',
        REQUIRED_DISCOUNT_CURRENCY: 'Select discount currency',
        MINIMUM_DISCOUNT_CURRENCY: 'Select minimum discount currency',
        DISCOUNT_BETWEEN_1_10: 'Please select discount between 1-10',
        DISCOUNT_BETWEEN_1_20: 'Please select discount between 1-20',
        MINIMUM_BEDROOM: 'Atleast 1 bedroom is required',
        MINIMUM_PERSON_CAPACTIY: 'Capacity must be greater than or equal to 1',
        MINIMUM_AMENITIES_REQUIRED: 'Amenities must have atleast 1 item',
        INVALID_DATE: 'Invalid date',
        NON_ZERO_CALENDAR_NIGHT: 'Night cannot be 0',
        NON_ZERO_PERSON: 'Person cannot be 0'
    },
};

export {
    MESSAGES,
};