import React, { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Field, useFormik, FormikProvider } from "formik";
import { connect, useSelector } from "react-redux";

import { CityAreaSchema } from "../../../../schemas";
import { AdminAreasAction, AdminCitiesAction } from "../../../../store/admin/thunk";

const AddArea = ({ loader, create, update, specific }) => {
    const navigate = useNavigate();
    const params = useParams();

    const { locations: { city, area } } = useSelector((state) => state.admin);

    const navigateToAreas = () => {
        navigate(`/cities/${params.citySlug}/areas`);
    };

    const createArea = async ({ name }) => {
        const payload = { name, cityId: city._id };

        create(payload, loader, navigateToAreas);
    };

    const updateArea = async (values) => {
        const payload = {
            name: values.name,
            areaId: area._id
        };

        update(payload, loader, navigateToAreas);
    };

    const areasFormik = useFormik({
        initialValues: { name: '' },
        onSubmit: (values) => params && params.areaSlug ? updateArea(values) : createArea(values),
        validationSchema: CityAreaSchema
    });

    useEffect(() => {
        if (params.areaSlug && Object.keys(area).length) {
            areasFormik.setFieldValue('name', area.name);
        }
    }, [area]);

    useEffect(() => {
        if (params && params.citySlug) {
            specific({ slug: params.citySlug }, loader, 'CITY');
        }
    }, [params.citySlug]);

    useEffect(() => {
        
        if(params && params.areaSlug) {
            specific({ slug: params.areaSlug }, loader, 'AREA');
        }
    }, [params.areaSlug]);

    return (
        <div className="edit-users-sc bg-white">
            <FormikProvider value={areasFormik}>
                <h4>{params && params.slug && params.areaSlug ? 'Edit Area' : 'Add New Area'}</h4>
                <div className="form-group">
                    <label>Name</label>
                    <Field name="name" placeholder="Enter name" />
                    {areasFormik.errors.name && areasFormik.touched.name ? (
                        <div className="error">{areasFormik.errors.name}</div>
                    ) : null}
                </div>

                <button type="button" onClick={areasFormik.submitForm}>{params && params.areaSlug ? 'Update' : 'Add'}</button>
            </FormikProvider>
        </div>
    );
};

const mapDispatchToProps = (dispatch) => ({
    update: (payload, loader, navigate) => dispatch(AdminAreasAction.update(payload, loader, navigate)),
    create: (payload, loader, navigate) => dispatch(AdminAreasAction.create(payload, loader, navigate)),
    specific: (payload, loader, type) => dispatch(AdminCitiesAction.specific(payload, loader, type))
});

export default connect(null, mapDispatchToProps)(AddArea);
