import React, { useEffect, useState } from "react";
import { Link, useLocation, useParams } from "react-router-dom";
import Switch from "react-switch";
import Swal from "sweetalert2";
import { connect, useSelector } from "react-redux";

import Pagination from "../../../components/Universal/Pagination";
import Axios from "../../../components/Universal/Axios";
import { AdminBuildingAction } from "../../../store/admin/thunk";
import { SORTING_FIELDS } from "../../../constants";
import { SortIcon } from "../../../assets/images";

const Buildings = ({ loader, list, update }) => {
	const location = useLocation();
	const params = useParams();

	const { locations: { buildings, totalBuildings } } = useSelector((state) => state.admin);

	const [pagination, setPagination] = useState({ offset: 0, limit: 10 });
	const [sorting, setSorting] = useState({ type: null, order: null });

	const getBuilding = async () => {
		const payload = {
			slug: params.areaSlug,
			...pagination,
			// sort: sorting.type,
			// sortType: sorting.order,
		};

		list(payload, loader);
	};

	const handleSorting = (field) => {
		if (sorting.type === field) {
			setSorting({ ...sorting, order: -1 * sorting.order });
		}
		else {
			setSorting({ type: field, order: 1 });
		};
	};

	const switchHandler = async (buildingId, status) => {
		Swal.fire({
			title: 'Are you sure?',
			text: `You want to ${status.toLowerCase()} this building`,
			confirmButtonText: 'Yes',
			showCancelButton: true,
		}).then(async (result) => {
			if (result.isConfirmed) {
				let payload = { buildingId, status };

				const listParams = {
					...pagination,
					slug: params.areaSlug,
				};

				update(payload, loader, {}, listParams);

				await Axios.patch("/location/building", { buildingId, status });
			}
		});
	};

	useEffect(() => {
		getBuilding();
	}, [pagination, sorting]);

	return (
		<div className="vendor-listing">
			<div className="d-flex justify-content-between align-items-center">

			</div>
			<div className="d-flex justify-content-between align-items-center">
				<ul className="paginaion">
					<li><Link to="/">Dashboard</Link></li>
					<li><Link to="/cities">Cities</Link></li>
					<li><Link to={`/cities/${params.citySlug}/areas`}>Areas</Link></li>
					<li className="active"><Link to="/users">Buildings</Link></li>
				</ul>
				<Link state={location.state} className="cm-btn" to={`/cities/${params.citySlug}/areas/${params.areaSlug}/add-building`}>
					Add Building
				</Link>
			</div>
			<div className="listing-table">
				<div className="listing-table-scrl">
					<table cellPadding={0} cellSpacing={0}>
						<thead>
							<tr>
								<th></th>
								<th className="cursor-pointer" onClick={() => handleSorting(SORTING_FIELDS.NAME)}>
									<span className="me-1">Name</span>
									{sorting.type !== SORTING_FIELDS.NAME ? <img src={SortIcon} width={15} height={15} /> : null}
									{sorting.type === SORTING_FIELDS.NAME ? sorting.order === 1 ? <i className="fa fa-angle-up" /> : <i className="fa fa-angle-down" /> : null}
								</th>
								<th>Status</th>
								<th>Actions</th>
							</tr>
						</thead>
						<tbody>
							{buildings.length === 0 ? <tr><td colSpan={4} className="text-center py-3">No Building found</td></tr> : null}
							{buildings.map((building, index) => (
								<tr key={building._id}>
									<td>{++index}</td>
									<td>{building.name}</td>
									<td>
										<Switch
											onColor="#e89532"
											onChange={() =>
												switchHandler(
													building._id,
													building.status === "ACTIVE" ? "INACTIVE" : "ACTIVE"
												)
											}
											checked={building.status === "ACTIVE" ? 1 : 0}
										/>
									</td>
									<td>
										<Link to={`/cities/${params.city}/areas/${params.areaSlug}/buildings/${building.slug}`}>
											<i className="fas fa-edit" />
										</Link>
									</td>
								</tr>
							))}
						</tbody>
					</table>
				</div>

				<Pagination count={totalBuildings + 1} setPagination={setPagination} />
			</div>
		</div>
	);
};

const mapDispatchToProps = (dispatch) => ({
	list: (payload, loader) => dispatch(AdminBuildingAction.list(payload, loader)),
	update: (payload, loader, navigate, params) => dispatch(AdminBuildingAction.update(payload, loader, navigate, params)),
});

export default connect(null, mapDispatchToProps)(Buildings);
