import React from "react";
import { Link, useLocation } from "react-router-dom";

const Faq = () => {
  const {
    state: { faq },
  } = useLocation();

  return (
    <div className="edit-users-sc">
      <div className="form-group">Question : {faq.question}</div>
      <div className="form-group">Answer : {faq.answer}</div>

      <Link to="/faqs">
        <button>Done</button>
      </Link>
    </div>
  );
};

export default Faq;
