import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Formik, Form, Field } from "formik";
import Axios from "../Universal/Axios";
import { CityAreaSchema } from "../../schemas";
import { APP_ROUTES } from "../../constants";

const AddSubCategory = ({ loader }) => {
  const {
    state: { category },
  } = useLocation();

  const navigate = useNavigate();

  const AddSubCategory = async ({ name, vat }, category_id = category._id) => {
    try {
      const { status } = await Axios.post(
        "/category/subcategory",
        { category_id, name, vat },
        loader
      );

      if (status) navigate(APP_ROUTES.SUB_CATEGORY_LIST, { state: { category } });
    } catch (error) { }
  };

  return (
    <div className="edit-users-sc">
      <Formik
        initialValues={{ name: "", vat:"" }}
        validationSchema={CityAreaSchema}
        onSubmit={(values) => AddSubCategory(values)}
      >
        {({ errors, touched }) => (
          <Form>
            <div className="form-group">
              <label>Enter name</label>
              <Field name="name" placeholder="Enter name" />
              {errors.name && touched.name ? (
                <div className="error">{errors.name}</div>
              ) : null}
            </div>
            <div className="form-group">
              <label>Vendor Commission</label>
              <Field  type="number" min="1%" max="100%" name="vat" placeholder="Vendor Commission" />
              {errors.vat && touched.vat ? (
                <div className="error">{errors.vat}</div>
              ) : null}
            </div>
            <button type="submit">Add</button>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default AddSubCategory;
