import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Field, useFormik, FormikProvider } from "formik";
import { connect, useSelector } from "react-redux";

import { FILE_UPLOAD_TYPE, IMAGE_FILE_TYPES, MESSAGES } from "../../../../constants";
import { CitySchema } from "../../../../schemas";
import { AdminCitiesAction } from "../../../../store/admin/thunk";
import { uploadImage } from "../../../../utils/imageHelper";

const AddCity = ({ loader, update, create, specific }) => {
    const navigate = useNavigate();
    const params = useParams();

    const { locations: { city } } = useSelector((state) => state.admin);

    const [selectedImage, setSelectedImage] = useState('');

    const addCity = async ({ name }) => {

        if (selectedImage.length === 0) {
            citiesFormik.setFieldError('images', 'Required');
            return;
        }
        const image = await uploadFile();

        const payload = { name, image: image };

        create(payload, loader, navigate);
    };

    const uploadFile = async () => {
        const formData = new FormData();
        formData.append("file", selectedImage);
        formData.append("type", FILE_UPLOAD_TYPE.CITY);

        const imageResponse = await uploadImage(formData);;

        return imageResponse.data.fileUrl;
    };

    const updateCity = async (values, formikProps) => {

        if (!selectedImage || selectedImage.length === 0) {
            formikProps.setFieldError('images', 'Required');
            return;
        }

        let image = selectedImage;

        if (typeof image !== 'string') {
            image = await uploadFile();
        }

        const payload = {
            name: values.name,
            image: image,
            city_id: city._id
        };

        update(payload, loader, navigate);
    };

    const handleImageChange = (event) => {
        const { files } = event.target;

        const invalidFileTypes = Object.values(files).map(item => item.type).filter(item => !IMAGE_FILE_TYPES.includes(item));

        if (files && files.length) {
            if (invalidFileTypes.length) {
                citiesFormik.setFieldError('images', MESSAGES.VALID_IMAGE_FILE_TYPE);
            }
            else {
                const selectedFiles = Array.from(files);

                setSelectedImage(selectedFiles[0]);
            }
        }
    };

    const citiesFormik = useFormik({
        initialValues: { name: '', images: '' },
        onSubmit: (values, formikProps) => params && params.citySlug ? updateCity(values, formikProps) : addCity(values, formikProps),
        validationSchema: CitySchema
    });

    useEffect(() => {
        if (params.citySlug) {
            specific({ slug: params.citySlug }, loader, 'CITY');
        }
    }, []);

    useEffect(() => {
        if (Object.keys(city).length && params.citySlug) {
            citiesFormik.setFieldValue('name', city.name);
            setSelectedImage(city.image);
        }
    }, [city])

    return (
        <div className="edit-users-sc bg-white">
            <FormikProvider value={citiesFormik}>
                <h4>{params && params.slug ? 'Edit City' : 'Add New City'}</h4>
                <div className="form-group add-image">
                    <label>Image</label>
                    <Field
                        name="images"
                        placeholder=""
                        type="file"
                        accept="image/*"
                        onChange={(event) => handleImageChange(event)}
                        className="show-for-sr"
                    />
                    <div className="upl-tet">Upload Image</div>
                    {citiesFormik.errors.images ? (
                        <div className="error mt-3">{citiesFormik.errors.images}</div>
                    ) : null}
                    {selectedImage && <div className="main-img-priew" >
                        <img
                            name="hero"
                            src={typeof selectedImage === "string" ? selectedImage : URL.createObjectURL(selectedImage)}
                            alt="Thumb"
                        />
                        <p onClick={() => setSelectedImage(null)}>X</p>
                    </div>}
                </div>


                <div className="form-group">
                    <label>Name</label>
                    <Field name="name" placeholder="Enter name" />
                    {citiesFormik.errors.name && citiesFormik.touched.name ? (
                        <div className="error">{citiesFormik.errors.name}</div>
                    ) : null}
                </div>

                <button type="button" onClick={citiesFormik.submitForm}>{params && params.citySlug ? 'Update' : 'Add'}</button>
            </FormikProvider>
        </div>
    );
};

const mapDispatchToProps = (dispatch) => ({
    update: (payload, loader, navigate) => dispatch(AdminCitiesAction.update(payload, loader, navigate)),
    create: (payload, loader, navigate) => dispatch(AdminCitiesAction.create(payload, loader, navigate)),
    specific: (payload, loader, type) => dispatch(AdminCitiesAction.specific(payload, loader, type))
});

export default connect(null, mapDispatchToProps)(AddCity);
