import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Switch from "react-switch";
import { connect, useDispatch, useSelector } from "react-redux";
import Swal from "sweetalert2";

import Pagination from "../../../components/Universal/Pagination";
import { AdminSubAdminAction } from "../../../store/admin/thunk";
import ADMIN_ACTIONS from "../../../store/admin/actions";
import { APP_ROUTES } from "../../../constants";

const SubAdmins = ({ loader, list, update }) => {
    const dispatch = useDispatch();

    const { subAdmin: { subAdminList, count, loading } } = useSelector((state) => state.admin);

    const [pagination, setPagination] = useState({ offset: 0, limit: 10 });

    const switchHandler = async (subAdmin_id, isBlocked) => {
        Swal.fire({
            icon: 'question',
            title: 'Are you sure?',
            text: `You want to ${isBlocked ? 'block' : 'unblock'} this sub admin`,
            confirmButtonText: 'Yes',
            showCancelButton: true,
        }).then(async (result) => {
            if (result.isConfirmed) {
                update({ subAdmin_id, isBlocked }, loader);
            }
        });
    };

    useEffect(() => {
        const query = pagination;

        list(query, loader);
        dispatch({ type: ADMIN_ACTIONS.CREATE_SUB_ADMIN_SUCCESS, status: false });
    }, [pagination.offset]);

    return (
        <div className="vendor-listing">
            <div className="d-flex justify-content-between align-items-center">
                <ul className="paginaion">
                    <li><Link to="/">Dashboard</Link></li>
                    <li className="active"><Link to={APP_ROUTES.SUB_ADMIN_LIST}>Sub Admins</Link></li>
                </ul>
                <h3>

                    {/* Sub Admins */}
                    <Link className="cm-btn" to={APP_ROUTES.ADD_SUB_ADMIN}>
                        Add Sub Admin
                    </Link>
                </h3>
            </div>

            <div className="listing-table">
                <div className="listing-table-scrl">
                    <table cellPadding={0} cellSpacing={0}>
                        <thead>
                            <tr>
                                <th></th>
                                <th>First Name</th>
                                <th>Last Name</th>
                                <th>Email</th>
                                <th>Blocked</th>
                                <th>Actions</th>
                            </tr>
                        </thead>
                        <tbody>
                            {loading ? <tr className="noresult"><td colSpan={6}>Loading</td></tr> : null}
                            {!loading && subAdminList.map((val, index) => (
                                <tr key={val._id}>
                                    <td>{++index}</td>
                                    <td>{val.firstName}</td>
                                    <td>{val.lastName}</td>
                                    <td>{val.email}</td>
                                    <td>
                                        <Switch
                                            onColor="#e89532"
                                            onChange={() => switchHandler(val._id, !val.isBlocked)}
                                            checked={val.isBlocked}
                                        />
                                    </td>
                                    <td>
                                        <Link state={{ val }} to="/sub-admin">
                                        <i className="fas fa-eye"></i>
                                        </Link>
                                    </td>
                                </tr>
                            ))}
                            {!loading && subAdminList.length == 0 ? <tr className="noresult"><td colSpan={6}>No Sub admin found</td></tr> : null}
                        </tbody>
                    </table>
                </div>

                <Pagination count={count} setPagination={setPagination} />
            </div>
        </div>
    );
};

const mapDispatchToProps = (dispatch) => ({
    list: (loader) => dispatch(AdminSubAdminAction.list(loader)),
    update: (payload, loader) => dispatch(AdminSubAdminAction.update(payload, loader)),
});

export default connect(null, mapDispatchToProps)(SubAdmins);
