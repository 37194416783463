import * as Yup from "yup";

import { MESSAGES } from "../constants";

const PropertySchema = Yup.object().shape({
	name: Yup.string().required(MESSAGES.VALIDATION_ERRORS.REQUIRED),
	unitNumber: Yup.string().required(MESSAGES.VALIDATION_ERRORS.REQUIRED),
	city_id: Yup.string().required(MESSAGES.VALIDATION_ERRORS.REQUIRED),
	area: Yup.string().required(MESSAGES.VALIDATION_ERRORS.REQUIRED),
	building: Yup.string().required(MESSAGES.VALIDATION_ERRORS.REQUIRED),
	category_id: Yup.string().required(MESSAGES.VALIDATION_ERRORS.REQUIRED),
	subCategory_ids: Yup.array().required(MESSAGES.VALIDATION_ERRORS.REQUIRED).min(1, MESSAGES.VALIDATION_ERRORS.REQUIRED),

	images: Yup.array().optional(),
	price: Yup.number().required(MESSAGES.VALIDATION_ERRORS.REQUIRED),
	description: Yup.string().required(MESSAGES.VALIDATION_ERRORS.REQUIRED),
	personCapacity: Yup
		.number()
		.required(MESSAGES.VALIDATION_ERRORS.REQUIRED)
		.min(1, MESSAGES.VALIDATION_ERRORS.MINIMUM_PERSON_CAPACTIY)
		.test('capacity-limit', function (value) {
			const capacityLimit = this.parent.beds.length * 3 + 1;

			if (this.parent.personCapacity > capacityLimit) {
				return this.createError({ message: `Capacity should not exceed ${capacityLimit}`, path: 'personCapacity' });
			}
			return { path: 'personCapacity' };
		}),
	totalBeds: Yup
		.number(MESSAGES.VALIDATION_ERRORS.REQUIRED)
		.required()
		.min(1, MESSAGES.VALIDATION_ERRORS.MINIMUM_BEDROOM),
	totalBathrooms: Yup
		.number(MESSAGES.VALIDATION_ERRORS.REQUIRED)
		.required(MESSAGES.VALIDATION_ERRORS.REQUIRED),
	beds: Yup
		.array()
		.of(
			Yup.object().shape({
				bedRoomName: Yup.string(),
				items: Yup.array().of(
					Yup.object().shape({
						bedType: Yup.string().required(MESSAGES.VALIDATION_ERRORS.REQUIRED),
						totalBeds: Yup.number().required(MESSAGES.VALIDATION_ERRORS.REQUIRED).min(1, MESSAGES.VALIDATION_ERRORS.MINIMUM_BEDROOM),
					})
				)
			})
		)
		.required(),
	bathrooms: Yup
		.array()
		.of(
			Yup.object().shape({
				private: Yup
					.mixed()
					.test('private-bathroom', function (value) {
						if (!value) return this.createError({ message: MESSAGES.VALIDATION_ERRORS.REQUIRED, path: this.path });
						return { path: this.path };
					}),
				ensuite: Yup
					.mixed()
					.test('ensuite-bathroom', function (value) {
						if (this.parent.private === 'true') {
							if (!value) return this.createError({ message: MESSAGES.VALIDATION_ERRORS.REQUIRED, path: this.path });
						}
						return { path: this.path };
					}),
				bedroom: Yup.string().test('attached-bathroom', function (value) {
					if (this.parent.ensuite === 'true') {
						if (!value) return this.createError({ message: MESSAGES.VALIDATION_ERRORS.REQUIRED, path: this.path });
					}
					return { path: this.path };
				}),
				insideBathroom: Yup.mixed().test('inside-bathroom', function (value) {
					if (this.parent.ensuite === 'false') {
						if (!value) return this.createError({ message: MESSAGES.VALIDATION_ERRORS.REQUIRED, path: this.path });
					}
					return { path: this.path };
				}),
			})
		),
	areaInSqft: Yup.number().required(MESSAGES.VALIDATION_ERRORS.REQUIRED).min(1, 'Area must be greater than or equal to 1'),
	amenities: Yup.array().required(MESSAGES.VALIDATION_ERRORS.REQUIRED).min(1, MESSAGES.VALIDATION_ERRORS.MINIMUM_AMENITIES_REQUIRED),
});

export default PropertySchema;