import Axios from "../../../components/Universal/Axios";
import { Toaster } from "../../../components/Toaster";
import { APP_ROUTES, TOAST_TYPE } from "../../../constants";
import COMMON_ACTIONS from "../../common/actions";
import { CommonAction } from "../../common/thunk";
import VENDOR_ACTIONS from "../actions";
import { MESSAGES } from "../../../constants";

const remove = (params, loader, pagination) => async (dispatch) => {
    try {
        const response = await Axios.delete("tour", { params }, loader);
        if (response.status) {
            Toaster(TOAST_TYPE.SUCCESS, MESSAGES.TOUR_REMOVE_SUCCESS);

            dispatch(CommonAction.toursList(pagination, loader));
        }
        else {
            Toaster(TOAST_TYPE.ERROR, response.error);
        }
    }
    catch (err) { }
};

const removeTourSubCategory = (params, loader, slug) => async (dispatch) => {
    try {
        const response = await Axios.delete("tour/subCategory", { params }, loader);
        if (response.status) {
            Toaster(TOAST_TYPE.SUCCESS, MESSAGES.TOUR_REMOVE_SUCCESS);

            console.log(params);

            dispatch(specific({ id: params.tourId }, loader));
        }
        else {
            Toaster(TOAST_TYPE.ERROR, response.error);
        }
    }
    catch (err) { }
};

const specific = (query, loader) => async (dispatch) => {
    try {
        dispatch({ type: VENDOR_ACTIONS.REQUEST_SPECIFIC_TOUR, status: true });
        
        const response = await Axios.get(`tour/details/vendor`, { slug: query.id }, loader);
        dispatch({ type: VENDOR_ACTIONS.SET_TOUR_DETAILS, tour: response.data });

        dispatch({ type: COMMON_ACTIONS.SET_IMAGES, key: 'selectedImage', value: response.data.images });
        dispatch({ type: COMMON_ACTIONS.SET_IMAGES, key: 'images', value: response.data.images });
        dispatch({ type: VENDOR_ACTIONS.REQUEST_SPECIFIC_TOUR, status: false });
    }
    catch (err) {
        dispatch({ type: VENDOR_ACTIONS.REQUEST_SPECIFIC_TOUR, status: false });
    }
};

const update = (payload, loader, slug) => async (dispatch) => {
    try {
        const response = await Axios.put("tour", payload, loader);

        if (response.status) {
            Toaster(TOAST_TYPE.SUCCESS, MESSAGES.TOUR_UPDATE_SUCCESS);

            dispatch(specific({ id: payload.tourId }, loader));

            dispatch({ type: COMMON_ACTIONS.SET_IMAGES, key: 'selectedImage', value: [] });
            dispatch({ type: COMMON_ACTIONS.SET_IMAGES, key: 'images', value: [] });
        }
    }
    catch (err) { }
};

const updateTourSubCategory = (payload, loader) => async (dispatch) => {
    try {
        const response = await Axios.put("tour/subCategory", payload, loader);

        console.log('>>>payload', payload);

        if (response.status) {
            Toaster(TOAST_TYPE.SUCCESS, MESSAGES.TOUR_SUB_CATEGORY_UPDATE_SUCCESS);

            dispatch({ type: COMMON_ACTIONS.SET_IMAGES, key: 'selectedImage', value: [] });
            dispatch({ type: COMMON_ACTIONS.SET_IMAGES, key: 'images', value: [] });
            dispatch(specific({ id: payload.tourId }, loader));
        }
    }
    catch (err) { }
};

const create = (payload, loader, navigate) => async (dispatch) => {
    try {
        const response = await Axios.post("tour", payload, loader);

        if (response.status) {
            Toaster(TOAST_TYPE.SUCCESS, MESSAGES.TOUR_CREATE_SUCCESS);

            navigate(APP_ROUTES.TOURS_LIST);
            dispatch({ type: COMMON_ACTIONS.SET_IMAGES, key: 'selectedImage', value: [] });
            dispatch({ type: COMMON_ACTIONS.SET_IMAGES, key: 'images', value: [] });
        }
    }
    catch (err) { }
};

const createTourSubCategory = (payload, loader, slug) => async (dispatch) => {
    try {
        const response = await Axios.post("tour/subCategory", payload, loader);

        if (response.status) {
            Toaster(TOAST_TYPE.SUCCESS, MESSAGES.TOUR_SUB_CATEGORY_CREATE_SUCCESS);

            dispatch(specific({ id: payload.tourId }, loader));

            dispatch({ type: COMMON_ACTIONS.SET_IMAGES, key: 'selectedImage', value: [] });
            dispatch({ type: COMMON_ACTIONS.SET_IMAGES, key: 'images', value: [] });
        }
    }
    catch (err) { }
};

const pricingList = (params, loader) => async (dispatch) => {
    try {
        dispatch({ type: VENDOR_ACTIONS.SET_TOUR_PRICING_LOADER, status: true });
        const { data: { data } } = await Axios.get("/tour/calendarData", params, loader);

        for (const [index, item] of Object.entries(data)) {
            for (const mt of item.monthlyData) {
                for (const innerMt of mt.data) {
                    if (parseInt(index) === 0) innerMt.isCalendarDateSelected = false;
                    innerMt.isPriceSelected = false;
                    innerMt.isMaximumPersonSelected = false;
                    innerMt.isPickupDropSelected = false;
                }
            }
        }

        dispatch({ type: VENDOR_ACTIONS.SET_TOUR_PRICE, pricing: data });
    }
    catch (err) { }
};

const updateTourCalendar = (params, loader, query) => async (dispatch) => {
    try {
        await Axios.put("/tour/calendar", params, loader);
        Toaster(TOAST_TYPE.SUCCESS, MESSAGES.CALENDAR.UPDATE_SUCCESS);

        dispatch(pricingList(query, loader));
    }
    catch (err) { }
};

export {
    remove,
    specific,
    update,
    create,
    pricingList,
    updateTourSubCategory,
    createTourSubCategory,
    removeTourSubCategory,
    updateTourCalendar,
};