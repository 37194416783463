import { connect, useSelector } from 'react-redux';
import { useEffect, Fragment } from 'react';
import moment from 'moment';
import { useNavigate, useParams } from 'react-router-dom';

import { AdminPromotionAction } from '../../../../../store/admin/thunk';
import styles from './style.module.css';
import { Button } from '../../../../../components';
import { PROMOTIONS_TYPE_DESCRIPTIONS, PROMOTIONS_TYPE_NAMES, PROPERTY_PROMOTIONS_TYPE, PORTFOLIO_DEALS_TYPE } from '../../../../../constants/promotions';

const SelectPromotion = (props) => {
    const params = useParams();
    const navigate = useNavigate();

    const { promotions: { promotions } } = useSelector((state) => state.admin);

    useEffect(() => {
        props.listPromotions(props.loader);
    }, []);

    const getPromotionDescription = (type) => {
        switch (type) {
            case PORTFOLIO_DEALS_TYPE.LAST_MINUTE:
                return 'Recommended 1% to 10% discount'
            case 1:
                return 'Recommended 1% to 20% discount'
            case PORTFOLIO_DEALS_TYPE.MONTHLY:
                return 'Available 10% discount'
            case PORTFOLIO_DEALS_TYPE.WEEKLY:
                return 'Available 5% discount'
        }
    };

    return (
        <div>
            <p className={styles.heading}>Choose new promotion</p>
            {promotions.map(item => (
                <Fragment key={item._id}>
                    <p className={styles.promotionName}>{PROMOTIONS_TYPE_NAMES[item._id]}</p>
                    <p className={styles.promotionDescription}>{PROMOTIONS_TYPE_DESCRIPTIONS[item._id]}</p>
                    {item.data.map(promotion => (
                        <div key={promotion._id} className={styles.promotionCard}>
                            <div>
                                <p className={`mb-0 ${styles.mainTabHead}`}>{promotion.promotionName}</p>
                                <p className='mb-0'>{getPromotionDescription(promotion.dealName || promotion.type)}</p>
                            </div>
                            {item._id === PROPERTY_PROMOTIONS_TYPE.CAMPAIGN ?
                                <div className='button-prom-des'>
                                    <p className={`mb-0 ${styles.mainTabHead}`}>Bookable Period</p>
                                    {moment(promotion.bookableStartDate).format('DD MMM YYYY')} - {moment(promotion.bookableEndDate).format('DD MMM YYYY')}
                                    <p className={`mb-0 mt-2 ${styles.mainTabHead}`}>Stay Period</p>
                                    {moment(promotion.stayStartDate).format('DD MMM YYYY')} - {moment(promotion.stayEndDate).format('DD MMM YYYY')}
                                </div>
                                :
                                item._id === PROPERTY_PROMOTIONS_TYPE.PORTFOLIO ?
                                    <div>  <p className='mb-0'>Any dates</p></div>
                                    :
                                    null
                            }
                            <div>
                                <p className='mb-0'>{promotion.description}</p>
                            </div>
                            <div className='button-prom'>
                                <Button type="button" handleClick={() => navigate(`/properties/${params.id}/promotions/${promotion._id}/new`)} title="Add Promotion" />
                            </div>
                        </div>
                    ))}
                </Fragment>
            ))}
        </div>
    );
};

const mapDispatchToProps = (dispatch) => ({
    listPromotions: (loader) => dispatch(AdminPromotionAction.list(loader)),
});

export default connect(null, mapDispatchToProps)(SelectPromotion);