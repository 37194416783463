import Axios from "../../../components/Universal/Axios";
import { Toaster } from "../../../components/Toaster";
import ADMIN_ACTIONS from "../actions";
import { MESSAGES } from "../../../constants";
import { TOAST_TYPE } from "../../../constants";

const list = (query, loader) => async (dispatch) => {
    try {
        dispatch({ type: ADMIN_ACTIONS.REQUEST_SUB_ADMIN_LIST, status: true });
        const response = await Axios.get("/admin/get/subadmins", query, loader);

        dispatch({ type: ADMIN_ACTIONS.SUB_ADMIN_LIST_SUCCESS, list: response.data.data, count: response.data.count });
        dispatch({ type: ADMIN_ACTIONS.REQUEST_SUB_ADMIN_LIST, status: false });
    }
    catch (err) {
        dispatch({ type: ADMIN_ACTIONS.REQUEST_SUB_ADMIN_LIST, status: false });
    }
};

const update = (payload, loader) => async (dispatch) => {
    try {
        const response = await Axios.patch("admin/update/subadmin", payload, loader);

        if (response.status) {
            Toaster(TOAST_TYPE.SUCCESS, `Subadmin ${payload.isBlocked ? 'blocked' : 'unblocked'} successfully`);
            dispatch(list(loader));
        }
        else {
            Toaster(TOAST_TYPE.ERROR, response.error);
        }
    }
    catch (err) { }
};

const create = (payload, loader) => async (dispatch) => {
    try {
        const response = await Axios.post("admin/create/subadmin", payload, loader);
        if (response.status) {
            Toaster(TOAST_TYPE.SUCCESS, MESSAGES.SUB_ADMIN.CREATE_SUCCESS);
            dispatch({ type: ADMIN_ACTIONS.CREATE_SUB_ADMIN_SUCCESS, status: true });
        }
        else {
            Toaster(TOAST_TYPE.ERROR, response.error);
        }
    }
    catch (err) {}
};

export {
    list,
    update,
    create
};