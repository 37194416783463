import Axios from "../../../components/Universal/Axios";
import { Toaster } from "../../../components/Toaster";
import { CommonAction } from "../../common/thunk";
import { TOAST_TYPE } from "../../../constants";

const approve = (payload, loader, pagination) => async (dispatch) => {
    try {
        const response = await Axios.patch("tour/approve", payload, loader);

        if (response.status) {
            Toaster(TOAST_TYPE.SUCCESS, `Tour ${payload.isApproved ? 'approved' : 'unapproved'} successfully`);

            dispatch(CommonAction.toursList(pagination, loader));
        }
        else {
            Toaster(TOAST_TYPE.ERROR, response.error);
        }
    }
    catch (err) { }
};

export {
    approve,
}; 