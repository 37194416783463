import "./App.css";
import "./assets/css/custom.css";
import "./assets/fonts/Stylesheet.css";
import "./assets/css/responsive.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min";
import "react-datepicker/dist/react-datepicker.css";
import 'react-calendar/dist/Calendar.css';

import React, { useEffect, useState } from "react";
import { Route, Routes, useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import { useDispatch, useSelector, connect } from "react-redux";
import io from 'socket.io-client';

import { routes } from "./routes";
import { Sidebar, Topbar, Loader } from "./components";
import Login from "./pages/Auth/Login";
import PrivateRoute from "./components/Auth/PrivateRoute";
import AdminDashboard from "./pages/Admin/Dashboard";
import VendorDashboard from "./pages/Vendor/Dashboard";
import { APP_ROUTES, TIMEZONE_URL, USER_TYPE, API_URL, ACCESS_TOKEN } from "./constants";
import { CommonAction } from "./store/common/thunk";
import COMMON_ACTIONS from "./store/common/actions";
import { asyncLocalStorage } from "./components/Auth/LocalStorage";

const App = ({ loader, userDetails }) => {
    const { pathname } = useLocation();
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const currencyData = useSelector((state => state.currencyType));
    const { user, socket } = useSelector((state) => state.common);

    const [collapse, setCollapse] = useState(true);
    const [showLoader, setLoader] = useState(0);
    const [appRoutes, setAppRoutes] = useState([]);

    const fetchData = () => {
        axios.get(`${API_URL.CURRENCY_URL}`).then((response) => {
            if (response.status === 200) {
                dispatch({ type: COMMON_ACTIONS.SET_CURRENCY, currency: response.data });
            }
        });
    };

    const excludeSidebarTopbar = pathname === APP_ROUTES.LOGIN || pathname === APP_ROUTES.FORGET_PASSWORD || pathname.includes("reset-password");

    const style = excludeSidebarTopbar ? { width: "100%", transition: "inherit", padding: "0" } : { transition: "0.5s" };

    const getUserLocation = () => {
        const location = window.navigator && window.navigator.geolocation;

        if (location) {
            location.getCurrentPosition(async (position) => {
                const response = await axios.get(`${TIMEZONE_URL}/${position.coords.latitude},${position.coords.longitude}`);

                let payload = {
                    type: COMMON_ACTIONS.SET_USER_LOCATION,
                    latitude: position.coords.latitude,
                    longitude: position.coords.longitude,
                };

                if (response.data && response.data.timezone_id) {
                    payload.timezone = response.data.timezone_id;
                }

                dispatch(payload);
            }, (error) => {
                dispatch({ type: COMMON_ACTIONS.SET_USER_LOCATION, latitude: null, longitude: null, timezone: null });
            })
        }
    }

    useEffect(() => {
        currencyData.payload || fetchData();

        const getToken = async () => {
            const token = await asyncLocalStorage.getItem("accessToken");

            if (socket && !socket.connected) {
                const socket = io(API_URL.SOCKET_URL, { auth: { token } });

                dispatch({ type: COMMON_ACTIONS.SET_USER_SOCKET, socket });
            }
        };

        if (localStorage.getItem(ACCESS_TOKEN)) {
            userDetails(navigate, loader);
        }
        else {
            navigate(APP_ROUTES.LOGIN);
        }

        getToken();

        getUserLocation();
    }, []);

    useEffect(() => {
        if (user && user.userType) {
            const tempRoutes = routes(setLoader).map((route, index) =>
                <Route
                    exact
                    path={route.path}
                    key={index}
                    element={user && route.privateComponent ?
                        <PrivateRoute
                            role={route.role ?? []}
                            activeRole={user.userType}
                            user={user}
                            route={route}
                        >
                            {route.element}
                        </PrivateRoute>
                        :
                        route.element
                    }
                />
            );

            setAppRoutes(tempRoutes);
        }
    }, [user]);

    const handleSidebarChange = () => {
        setCollapse(!collapse);
    };

    return (
        <div className={collapse ? "app active" : "app"}>
            <Loader progress={showLoader} />
            <div className="main-dashboard">
                {excludeSidebarTopbar ? null : (
                    <div className="left-sidebar">
                        <Sidebar pathname={pathname} open={handleSidebarChange} />
                    </div>
                )}

                <div className="right-sidebar" style={style}>
                    {excludeSidebarTopbar ? null : (
                        <Topbar open={handleSidebarChange} pathname={pathname} />
                    )}
                    <Routes>
                        <Route path={APP_ROUTES.LOGIN} element={<Login />} />
                        {user ? <Route path={APP_ROUTES.DASHBOARD} element={user.userType === USER_TYPE.ADMIN ?
                            <PrivateRoute role={[USER_TYPE.ADMIN]} activeRole={user.userType}>
                                <AdminDashboard loader={setLoader} />
                            </PrivateRoute> :

                            <PrivateRoute role={[USER_TYPE.VENDOR]} activeRole={user.userType}>
                                <VendorDashboard loader={setLoader} />
                            </PrivateRoute>} /> : null}

                        {appRoutes}
                    </Routes>
                </div>
            </div>
        </div>
    );
}

const mapDispatchToProps = (dispatch) => ({
    userDetails: (navigate, loader) => dispatch(CommonAction.getUserDetails(navigate, loader)),
});

export default connect(null, mapDispatchToProps)(App);
