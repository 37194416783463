import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import Switch from "react-switch";
import { asyncLocalStorage } from "../Auth/LocalStorage";
import Pagination from "../Universal/Pagination";
import Axios from "../Universal/Axios";
import { Toaster } from "../Toaster";
import { APP_ROUTES } from "../../constants";

const Faqs = ({ loader }) => {
  const [faqs, setfaqs] = useState({});
  const [isVendor, setIsVendor] = useState(false);
  const [pagination, setPagination] = useState({
    offset: 0,
    limit: 10,
  });
  const navigate = useNavigate();

  const getFaqs = async () => {
    try {
      const { userType } = await asyncLocalStorage.getParsedItem(
        "loginDetails"
      );


      if (userType === "VENDOR") {
        setIsVendor(true);
      }

      const { status, data } = await Axios.get(
        `/faq`,
        {
          limit: pagination.limit,
          offset: pagination.offset,
        },
        loader
      );

      if (status) setfaqs({ count: data.count, faqs: data.faqs });
    } catch (error) {
      console.error("ERROR-->", error);
    }
  };

  const switchHandler = async (faq_id, status) => {
    setfaqs((prev) => {
      return {
        count: prev.count,
        faqs: prev.faqs.map((ele) => {
          if (ele._id === faq_id) ele.status = status;
          return ele;
        }),
      };
    });

    await Axios.patch("/faq", { faq_id, status }, loader);
  };

  const deleteFaq = async (faq_id) => {
    try {
      if (!window.confirm("ARE YOU SURE")) return null;

      const { accessToken } = await asyncLocalStorage.getParsedItem(
        "loginDetails"
      );

      const response = await axios.delete(
        `https://socket.rentvip.com/api/v1/faq?faq_id=${faq_id}`,
        { headers: { authorization: accessToken } }
      );

      if (response.data.statusCode === 200)
        setfaqs((prev => prev.faqs.filter((ele) => ele._id !== faq_id)));
      else if (response.data.message === "Unauthorized") {
        Toaster("warn", `${response.data.message} - Login again`);
        navigate("/login");
      } else if (response.data.statusCode !== 200)
        Toaster("error", response.data.message);
    } catch (error) {
      console.error("ERROR-->", error);
    }
  };

  useEffect(() => {
    getFaqs();
  }, [pagination]);

  return (
    <div className="vendor-listing">
      <h3>
        FAQs
        {isVendor ? null : (
          <Link className="cm-btn" to={APP_ROUTES.ADD_FAQ}>
            Add FAQ
          </Link>
        )}
      </h3>
      <div className="listing-table">
        <div className="listing-table-scrl">
          <table cellPadding={0} cellSpacing={0}>
            <thead>
              <tr>
                <th></th>
                <th>Question</th>
                <th className="answr">Answer</th>
                {isVendor ? null : <th>Status</th>}
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {faqs.faqs &&
                faqs.faqs.map((faq, index) => (
                  <tr key={faq._id}>
                    <td>{++index}</td>
                    <td>{faq.question}</td>
                    <td>{faq.answer}</td>
                    {isVendor ? null : (
                      <td>
                        <Switch
                          onColor="#e89532"
                          onChange={() =>
                            switchHandler(
                              faq._id,
                              faq.status === "ACTIVE" ? "INACTIVE" : "ACTIVE"
                            )
                          }
                          checked={faq.status === "ACTIVE" ? 1 : 0}
                        />
                      </td>
                    )}
                    <td>
                      <Link state={{ faq }} to="/faq">
                      <i className="fas fa-eye"></i>
                      </Link>
                      {isVendor ? null : (
                        <Link state={{ faq }} to={APP_ROUTES.EDIT_FAQ}>
                          <i className="fas fa-edit"></i>
                        </Link>
                      )}
                      {isVendor ? null : (
                        <button onClick={() => deleteFaq(faq._id)}><i className="fas fa-trash"></i></button>
                      )}
                    </td>
                  </tr>
                ))}
            </tbody>

          </table>
        </div>
      </div>
      <Pagination count={faqs.count + 1} setPagination={setPagination} />
    </div>
  );
};

export default Faqs;
