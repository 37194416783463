exports.asyncLocalStorage = {
  setItem: (key, value) =>
    Promise.resolve().then(() => localStorage.setItem(key, value)),

  getItem: (key) => Promise.resolve().then(() => localStorage.getItem(key)),

  getParsedItem: (key) =>
    Promise.resolve().then(() => {
      const data = localStorage.getItem(key);
      return data ? JSON.parse(data) : null;
    }),

  removeItem: (key) =>
    Promise.resolve().then(() => localStorage.removeItem(key)),
};

exports.clearLocalStorage = (items) => {
  if (items && items.length > 0)
    items.map((val) => localStorage.removeItem(val));
};
