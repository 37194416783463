import React, { useState } from "react";
import { Field, Form, Formik } from "formik";
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";


import { EmailIcon, LockIcon } from "../../../assets/images";
import { userType } from "../../../store/slices/authSlice";
import { ACCESS_TOKEN, TOAST_TYPE, APP_ROUTES, USER_TYPE, API_URL } from "../../../constants";
import { SignupSchema } from "../../../schemas";
import { asyncLocalStorage } from '../../../components/Auth/LocalStorage';
import { Toaster } from '../../../components';
import COMMON_ACTIONS from "../../../store/common/actions";

const Login = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [values, setValues] = useState(false);
  const [login, setLogin] = useState([]);

  const showText = () => {
    setValues(!values);
  };

  const loginFunction = async ({ email, password }) => {
    try {
      const BASE_URL = API_URL.BASE_URL,
        auth = { email, password },
        messages = {
          invalidCred: "Invalid email or password.",
          wrongPass: "Wrong password",
          blockMsg: "Vendor is blocked.",
        };

      const [admin, vendor] = await Promise.all([
        axios.post(`${BASE_URL}admin/login`, auth),
        axios.post(`${BASE_URL}vendor/login`, auth),
      ]);

      if (
        admin.data.message === messages.invalidCred &&
        vendor.data.message === messages.invalidCred
      ) {
        Toaster(TOAST_TYPE.WARN, messages.invalidCred);
        return;
      }

      if (
        admin.data.message === messages.wrongPass ||
        vendor.data.message === messages.wrongPass
      ) {
        Toaster(TOAST_TYPE.ERROR, messages.wrongPass);
        return;
      }

      if (
        admin.data.message === messages.blockMsg ||
        vendor.data.message === messages.blockMsg
      ) {
        Toaster(TOAST_TYPE.WARN, messages.blockMsg);
        return;
      }

      if (admin.status === 200) {
        const types = { admin: true, vendor: false };

        const adminDetails = admin.data.data;
        await asyncLocalStorage.removeItem("loginDetails");
        await asyncLocalStorage.removeItem(ACCESS_TOKEN);
        await asyncLocalStorage.setItem(
          "loginDetails",
          JSON.stringify(admin.data.data)
        );
        await asyncLocalStorage.setItem(ACCESS_TOKEN, admin.data.data.accessToken);
        dispatch({
          type: COMMON_ACTIONS.USER_DETAILS_SUCCESS,
          user: {
            firstName: adminDetails.firstName,
            lastName: adminDetails.lastName,
            email: adminDetails.email,
            image: adminDetails.image,
            userType: USER_TYPE.ADMIN
          }
        });
        dispatch(userType(types));
        navigate(APP_ROUTES.DASHBOARD);
        return;
      }

      if (vendor.status === 200) {
        const types = { vendor: true, admin: false };
        const vendorDetails = vendor.data.data;

        await asyncLocalStorage.removeItem("loginDetails");
        await asyncLocalStorage.removeItem(ACCESS_TOKEN);
        await asyncLocalStorage.setItem(
          "loginDetails",
          JSON.stringify(vendorDetails)
        );
        await asyncLocalStorage.setItem(
          "accessToken",
          vendorDetails.accessToken
        );
        dispatch({
          type: COMMON_ACTIONS.USER_DETAILS_SUCCESS,
          user: {
            firstName: vendorDetails.firstName,
            lastName: vendorDetails.lastName,
            email: vendorDetails.email,
            image: vendorDetails.image,
            userType: USER_TYPE.VENDOR
          }
        });
        dispatch(userType(types));
        navigate(APP_ROUTES.DASHBOARD);
        return;
      }
    } catch (error) {
      Toaster(TOAST_TYPE.ERROR, error.response.data.message);
    }
  };

  return (
    <div className="login-page">
      <div className="container">
        <div className="row d-flex align-items-center">
          <div className="col-md-7">
            <div className="login-page-left"></div>
          </div>
          <div className="col-md-5">
            <div className="login-page-right">
              <h5>Welcome back</h5>
              <p>It is a long established fact that a reader.</p>
              <Formik
                initialValues={{
                  email: "",
                  password: "",
                }}
                validationSchema={SignupSchema}
                onSubmit={(values) => {
                  setLogin([...login, values]);
                  loginFunction(values);
                }}
              >
                {({ errors, touched }) => (
                  <Form>
                    <div className="form-group">
                      <span>
                        <img src={EmailIcon} alt="" />
                      </span>
                      <Field
                        name="email"
                        type="email"
                        placeholder="Email"
                      ></Field>
                      {errors.email && touched.email ? (
                        <div className="error">{errors.email}</div>
                      ) : null}
                    </div>
                    <div className="form-group">
                      <span>
                        <img src={LockIcon} alt="" />
                      </span>
                      <Field
                        name="password"
                        type={values ? "text" : "password"}
                        placeholder="Enter Password"
                      ></Field>
                      {errors.password && touched.password ? (
                        <div className="error">{errors.password}</div>
                      ) : null}
                      <label onClick={showText}>
                        {!values ? (
                          <i className="far fa-eye"></i>
                        ) : (
                          <i className="far fa-eye-slash"></i>
                        )}
                      </label>
                    </div>
                    <div className="form-group">
                      <Link to={APP_ROUTES.FORGET_PASSWORD}>Forgot password?</Link>
                    </div>
                    <div className="form-group">
                      <button type="submit">Sign in</button>
                    </div>
                  </Form>
                )}
              </Formik>

              <div className="copy-right-lo">
                <p>Copyright 2023 by smart. All rights reserved</p>
                <ul>
                  <li>
                    <Link to="/">Privacy Policy</Link>
                  </li>
                  <li>
                    <Link to="/">Terms & Conditions</Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Login;
